import React from "react"
import ButtonCustomization from "./ButtonCustomization"
import CancelButtonCustomization from "./CancelButtonCustomization"
import CheckboxCustomization from "./CheckboxCustomization"
import CouponCodesCustomization from "./CouponCodesCustomization"
import EmojiCustomization from "./EmojiCustomization"
import HeadingCustomization from "./HeadingCustomization"
import IconCustomization from "./IconCustomization"
import InputCustomization from "./InputCustomization"
import InputsCustomization from "./InputsCustomization"
import ImageCustomization from "./ImageCustomization"
import ListCustomization from "./ListCustomization"
import MainTextCustomization from "./MainTextCustomization"
import PriceCustomization from "./PriceCustomization"
import TimerCustomization from "./TimerCustomization"
import TextareaCustomization from "./TextareaCustomization"
import VideoCustomization from "./VideoCustomization"
import WidgetDetailsCustomization from "./WidgetDetailsCustomization"

export interface CustomizationComponentProps {
  parameterTypes: WidgetParameterTypes;
}

interface StylePopupContentProps extends CustomizationComponentProps {
  activeStyleTab: WidgetParameterCategory;
}

const StylePopupContent: React.FC<StylePopupContentProps> = (props: StylePopupContentProps) => {
  const { activeStyleTab, parameterTypes } = props

  switch(activeStyleTab) {
    case "emoji": return <EmojiCustomization parameterTypes={parameterTypes} />
    case "heading": return <HeadingCustomization parameterTypes={parameterTypes} />
    case "main_text": return <MainTextCustomization parameterTypes={parameterTypes} />
    case "textarea": return <TextareaCustomization parameterTypes={parameterTypes} />
    case "input": return <InputCustomization parameterTypes={parameterTypes} />
    case "inputs": return <InputsCustomization parameterTypes={parameterTypes} />
    case "button": return <ButtonCustomization parameterTypes={parameterTypes} />
    case "cancel_button": return <CancelButtonCustomization parameterTypes={parameterTypes} />
    case "image": return <ImageCustomization parameterTypes={parameterTypes} />
    case "coupon_codes": return <CouponCodesCustomization parameterTypes={parameterTypes} />
    case "icon": return <IconCustomization parameterTypes={parameterTypes} />
    case "checkbox": return <CheckboxCustomization parameterTypes={parameterTypes} />
    case "list_with_icon": return <ListCustomization parameterTypes={parameterTypes} />
    case "price": return <PriceCustomization parameterTypes={parameterTypes} />
    case "timer": return <TimerCustomization parameterTypes={parameterTypes} />
    case "video": return <VideoCustomization parameterTypes={parameterTypes} />
    case "widget_details": return <WidgetDetailsCustomization parameterTypes={parameterTypes} />
    default: return null
  }
}

export default StylePopupContent
