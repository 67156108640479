import React from "react"

const HeavyPlusIcon: React.FC<{}> = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="6.04544" x2="11.5" y2="6.04544" stroke="#5869FA" strokeLinecap="round"/>
      <line x1="5.95459" y1="0.5" x2="5.95459" y2="11.5" stroke="#5869FA" strokeLinecap="round"/>
    </svg>
  )
}

export default HeavyPlusIcon
