import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/DomainsLayout"
import SiteForm from "../components/sites/SiteForm"
import sitesApi from "../api/sites"

const handleSubmit = async (attrs: CustomerSite): Promise<any> => {
  return sitesApi.save(attrs)
    .then(() => navigate(`/sites`))
    .catch(errors => errors)
}

const SiteNew: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const [site, setSite] = useState<CustomerSite | null>(null)
  const [sites, setSites] = useState<CustomerSite[]>([])

  useEffect(() => {
    sitesApi.getNew().then(setSite)
    sitesApi.getAll().then(setSites)
  }, [])

  return (
    <Layout
      activeSubSection="new"
      headerText={t("newDomain")}
      sites={sites}
    >
      <SiteForm onSubmit={handleSubmit} site={site} />
    </Layout>
  )
}

export default SiteNew
