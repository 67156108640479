import React from "react"
import cn from "classnames"
import { Field, FieldRenderProps } from "react-final-form"
import { StatefulIconProps } from "../../utils/icons"

interface RadioFieldRenderProps extends FieldRenderProps<string> {
  icon?: React.FC<StatefulIconProps>;
  className?: string;
  onClick?: () => void;
}

const renderField = (props: RadioFieldRenderProps): React.ReactNode => {
  const { children, className, icon, input, label, onClick, title } = props
  const inputName = [input.name, input.value].join('-')
  const Icon: any = icon

  return (
    <div>
      <input
        checked={input.checked}
        className="form-checkbox__input"
        id={inputName}
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        type="radio"
      />
      <label
        className={cn(`radio radio--${input.checked ? "active" : "inactive"}`, className)}
        htmlFor={inputName}
        onClick={onClick ? onClick : undefined}
        title={title}
      >
        {icon && <Icon active={input.checked} />}
        {label || children}
      </label>
    </div>
  )
}

export interface RadioFieldProps {
  children?: React.ReactNode;
  className?: string;
  icon?: React.FC<StatefulIconProps>;
  label?: string;
  onChange?: any;
  onClick?: () => void;
  name: string;
  value: any;
}

const RadioField: React.FC<RadioFieldProps> = (props: RadioFieldProps) => {
  return (
    <Field
      {...props}
      render={renderField}
      type="radio"
    />
  )
}

export default RadioField
