import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const TimerIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { fillOpacity, primaryColor, secondaryColor } = useStyles(props)

  return (
    <svg width="31" height="38" viewBox="0 0 31 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.1417 16.875C17.6419 17.2053 17.1044 17.9242 17 18.735C16 26.5 16.25 30.4337 15.5 30.3712C14.75 30.3087 15 26.5 14 18.735C13.8956 17.9242 13.3578 17.2054 12.8583 16.8752C8.84062 14.2217 10 15.5 6.62843 11.6103C6.62843 11.6103 5.33934 9.19255 5.62843 7.69004C14 4.99995 19.4855 16 25.3715 7.69016C25.6605 9.19258 24.3715 11.6101 24.3715 11.6101C21.5 15 22.1588 14.2216 18.1417 16.875Z" fill={secondaryColor} fillOpacity={fillOpacity}/>
      <path d="M1 1.5H30M1 36.5H30" stroke={primaryColor} strokeWidth="2" strokeLinecap="round"/>
      <path d="M25.9432 36.2554V32.0398C25.9432 27.7748 23.6026 23.8544 19.3525 21.0005C18.824 20.6456 18.4956 19.8794 18.4956 19.0002C18.4956 18.121 18.824 17.3551 19.3528 16.9999C23.6026 14.1463 25.9432 10.2259 25.9435 5.96088V1.74525M5.05676 1.74512V5.96101C5.05676 10.2261 7.3971 14.1465 11.6475 17.0001C12.176 17.3552 12.5044 18.1212 12.5044 19.0003C12.5044 19.8797 12.176 20.6457 11.6472 21.0009C7.3971 23.8545 5.05676 27.7749 5.05676 32.0399V36.2556" stroke={primaryColor}/>
      <path d="M25.4969 34.1087C25.4969 34.2277 25.5039 35.3871 25.4969 35.5C21.4975 35.5 7.47172 35.5 5.51117 35.5C5.5 35.3277 5.5 34.2262 5.5 34.1087C5.5 32 13.5 28.5 15.5 28.5C17.5 28.5 25.4969 32.5 25.4969 34.1087Z" fill={secondaryColor} fillOpacity={fillOpacity}/>
    </svg>
  )
}

export default TimerIcon
