import React, { useState } from "react"
import DefaultDomainIcon from "../menu/DomainsIcon"

interface DomainIconProps {
  src: string | null | undefined;
}

const DomainIcon: React.FC<DomainIconProps> = (props: DomainIconProps) => {
  const { src } = props
  const [isDefaultIconUsed, setIsDefaultIconUsed] = useState(!src)

  if (isDefaultIconUsed) return <DefaultDomainIcon />

  return (
    <img
      onError={(): void => setIsDefaultIconUsed(true)}
      src={src || undefined}
    />
  )
}

export default DomainIcon
