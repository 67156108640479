import React from "react"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import { Field, FieldRenderProps } from "react-final-form"
import { useTranslation } from "react-i18next"

interface RenderProps extends FieldRenderProps<number> {
  options: string[];
}

const renderField = (props: RenderProps): React.ReactNode => {
  const { className, input, label, meta, options } = props
  const { t } = useTranslation()
  const error = meta.submitError
  return (
    <FormControl className="select-control" fullWidth variant="outlined">
      <InputLabel>{label}</InputLabel>
      <Select
        {...input}
        className={className}
        error={!!error}
        fullWidth
        label={label}
        native
        variant="outlined"
      >
        {options.map(option => <option key={option} value={option}>{t(`Option__${option}`)}</option>)}
      </Select>
    </FormControl>
  )
}

interface SelectFieldProps {
  label: string;
  name: string;
  options: string[];
}

const SelectField: React.FC<SelectFieldProps> = (props: SelectFieldProps) => {
  return (
    <Field {...props} render={renderField}/>
  )
}

export default SelectField
