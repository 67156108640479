import React, { useEffect, useState } from "react"
import { ParsedQuery, parse, stringify } from "query-string"
import { navigate, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../components/buttons/Button"
import DomainIcon from "../images/icons/other/DomainIcon"
import EmptyCollection from "../components/layout/EmptyCollection"
import ExportIcon from "../images/icons/other/ExportIcon"
import Layout from "../components/layout/DomainsLayout"
import SiteMessageCard from "../components/site-messages/SiteMessageCard"
import sitesApi from "../api/sites"

import DateRangePicker from "../components/filters/DateRangePicker"
import Pagination from "../components/filters/Pagination"
import Select from "../components/filters/Select"
import OrderPanel, { OrderButton } from "../components/filters/OrderPanel"
import SearchField from "../components/filters/SearchField"

interface SiteMessagesPageProps extends RouteComponentProps {
  siteId?: string;
}

interface PeriodChangeProps {
  from?: string | null;
  to?: string | null;
}

const HeaderContent: React.FC<SiteMessagesPageProps> = (props: SiteMessagesPageProps) => {
  const { location, siteId } = props
  const { t } = useTranslation()

  return(
    <Button
      className="button--no-text-transform"
      download
      secondary
      icon={ExportIcon}
      href={`/sites/${siteId}/leads.csv${location?.search}`}
    >
      {t("exportToCSV")}
    </Button>
  )
}

const SiteMessagesPage: React.FC<SiteMessagesPageProps> = (props: SiteMessagesPageProps) => {
  const { location, siteId } = props
  const [nullableQuery, setQuery] = useState<ParsedQuery<string> | null>(null)
  const { t } = useTranslation()
  const [isMessagesFetched, setIsMessagesFetched] = useState(false)
  const [site, setSite] = useState<CustomerSite | null>(null)

  useEffect(() => {
    if (nullableQuery == null) return;

    sitesApi.getOneWithMessages(siteId, nullableQuery || {}).then((site: CustomerSite | null) => {
      setSite(site)
      setIsMessagesFetched(true)
    })
  }, [nullableQuery])

  useEffect(() => {
    setQuery(parse(location?.search || "") || {})
  }, [location?.search])

  if (nullableQuery == null) return null;

  const query: any = nullableQuery || {}
  const page = parseInt(query.page?.toString() || "") || 1
  const { campaignId, orderBy = "-date", perPage, search, since, until } = query

  const applyFilters = (newQuery: any): void => {
    const stringifiedQuery = stringify(newQuery, { skipNull: true })
    navigate(`/sites/${siteId}/leads${stringifiedQuery && ['?', stringifiedQuery].join('')}`)
  }

  const handlePageChange = (page: number): void => {
    applyFilters({ ...query, page })
  }

  const handlePeriodChange = (props: PeriodChangeProps): void => {
    const { from, to } = props
    applyFilters({ ...query, page: null, since: from, until: to })
  }

  const handleSearch = (value?: string | null): void => {
    applyFilters({ ...query, page: null, search: value || null })
  }

  const handleCampaignSelect = (id?: string | number | null): void => {
    const campaignId = parseInt(id?.toString() || "") === -1 ? null : id
    applyFilters({ ...query, page: null, campaignId })
  }

  const handlePerPageSelect = (perPage: number): void => {
    applyFilters({ ...query, page: null, perPage })
  }

  const handleReorder = (orderBy?: string | null): void => {
    applyFilters({ ...query, page: null, orderBy })
  }

  const isHeaderContentShown = (
    site && ((site.messagesTotalPages || 1) > 1 || (site.messages?.length || 0) > 0)
  )

  return (
    <Layout
      activeSiteId={site && (site.hostname || site.id) || undefined}
      activeSubSection="leads"
      headerContent={isHeaderContentShown && <HeaderContent {...props} />}
      headerIcon={site && <DomainIcon src={site.iconUrl} />}
      headerText={site?.name || ""}
      headerSubText={t("navigationLeads")}
    >
      {site && <div className="filters">
        <SearchField
          onChange={handleSearch}
          initialValue={search?.toString() || undefined}
        />
        <div className="filters-group">
          <DateRangePicker
            endDate={until?.toString() || undefined}
            onChange={handlePeriodChange}
            startDate={since?.toString() || undefined}
          />
          <Select
            onChange={handleCampaignSelect}
            options={[
              { id: -1, name: t("allCampaigns") }, ...(site.widgets || [])
            ].map(w => ({ name: w.name, value: w.id }))}
            selectedValue={parseInt((campaignId || "").toString()) || null}
          />
        </div>
      </div>}
      {site && (site.messagesTotalPages || 1) > 1 && <Pagination
        page={page}
        perPage={perPage}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageSelect}
        totalPages={site.messagesTotalPages || 1}
      />}
      {(site?.messages?.length || 0) > 0 && <OrderPanel
        activeOrder={orderBy?.toString()}
        className="card horizontal-card site-message-card site-messages-order"
        onChange={handleReorder}
      >
        <OrderButton by="campaign" className="site-message-card__campaign">
          {t("campaign")}
        </OrderButton>
        <OrderButton by="emoji" className="site-message-card__emoji">
          {t("emoji")}
        </OrderButton>
        <OrderButton by="name" className="site-message-card__name">
          {t("name")}
        </OrderButton>
        <OrderButton by="email" className="site-message-card__contacts">
          {t("emailAndPhone")}
        </OrderButton>
        <OrderButton by="date" className="site-message-card__date">
          {t("date")}
        </OrderButton>
      </OrderPanel>}
      {site && site.messages && site.messages.length !== 0 && site.messages.map(siteMessage => (
        <SiteMessageCard siteMessage={siteMessage} key={siteMessage.id} />
      ))}
      {site && (site.messagesTotalPages || 1) > 1 && <Pagination
        page={page}
        perPage={perPage}
        onPageChange={handlePageChange}
        onPerPageChange={handlePerPageSelect}
        totalPages={site.messagesTotalPages || 1}
      />}
      {isMessagesFetched && site && site.messages && site.messages.length === 0 && (
        <EmptyCollection
          title={t("emptyLeadsTitle")}
          description={t("emptyLeadsDescription")}
        />
      )}
    </Layout>
  )
}

export default SiteMessagesPage
