import React from "react"
import { FormSpy } from "react-final-form"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"
import IconSelect from "../shared/IconSelect"
import IconTypeSelect from "../shared/IconTypeSelect"
import AvatarSelect from "../shared/AvatarSelect"

const IconCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup visible={!!parameterTypes.iconDisabled && parameterTypes.iconDisabled !== "hidden"}>
        <Field
          className="form-checkbox--reversed"
          name="iconDisabled"
          prefix="parameters"
          type={parameterTypes.iconDisabled}
        />
      </FormGroup>
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const { values: { parameters: { iconDisabled, iconType } } } = props

          if (iconDisabled) return null

          const Picker = iconType === "avatar" ? AvatarSelect : IconSelect
          return (
            <>
              <FormGroup visible={!!parameterTypes.iconType}>
                <IconTypeSelect name="parameters.iconType" type={parameterTypes.iconType} />
              </FormGroup>
              <FormGroup visible={!!parameterTypes.iconValue}>
                <Picker name="parameters.iconValue" type={parameterTypes.iconValue} />
              </FormGroup>
              {iconType === "avatar" && <>
                <FormGroup title={t("color")} visible={!!parameterTypes.avatarBackgroundColor}>
                  <Field
                    initialValue="#5869fa"
                    name="avatarBackgroundColor"
                    prefix="theme"
                    type={parameterTypes.avatarBackgroundColor}
                  />
                </FormGroup>
              </>}
              {iconType === "icon" && <>
                <FormGroup title={t("icon")} visible={!!parameterTypes.iconColor}>
                  <Field
                    initialValue="#ffffff"
                    name="iconColor"
                    prefix="theme"
                    type={parameterTypes.iconColor}
                  />
                </FormGroup>
                <FormGroup title={t("background")} visible={!!parameterTypes.iconBackgroundColor}>
                  <Field
                    initialValue="#5869fa"
                    name="iconBackgroundColor"
                    prefix="theme"
                    type={parameterTypes.iconBackgroundColor}
                  />
                </FormGroup>
              </>}
            </>
          )
        }}
      </FormSpy>
    </>
  )
}

export default IconCustomization
