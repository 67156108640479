import React, { ReactNode, useState } from "react"
import { Form, FormRenderProps } from "react-final-form"
import { useTranslation } from "react-i18next"

import Button from "../buttons/Button"
import ControlGroup from "../boxes/ControlGroup"
import FormGroup from "../form-fields/FormGroup"
import LinkButton from "../buttons/LinkButton"
import Paper from "../boxes/Paper"
import StaticField from "../form-fields/StaticField"
import TextField from "../form-fields/TextField"

const renderForm = (renderProps: FormRenderProps<Account>): ReactNode => {
  const { t } = useTranslation()
  const [isPasswordEditActive, setIsPasswordEditActive] = useState(false)
  const { form, handleSubmit } = renderProps

  const activatePasswordEdit = (): void => {
    setIsPasswordEditActive(true)
    form.mutators.initializePasswordFields()
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper>
        <ControlGroup maxWidth="480px">
          <FormGroup>
            <TextField type="text" name="firstName" label={t("firstName")} />
            <TextField type="text" name="lastName" label={t("lastName")} />
            <TextField type="email" name="email" label={t("email")} />
            {!isPasswordEditActive && <StaticField label={t("password")}>
              <LinkButton
                onClick={activatePasswordEdit}
                type="button"
              >
                {t("change")}
              </LinkButton>
            </StaticField>}
            {isPasswordEditActive && <>
              <TextField type="password" name="password" label={t("newPassword")} />
              <TextField type="password" name="passwordConfirmation" label={t("confirmation")} />
            </>}
          </FormGroup>
          <FormGroup>
            <TextField readOnly type="text" name="zapierToken" label={t("zapierToken")} />
          </FormGroup>
        </ControlGroup>
      </Paper>
      <ControlGroup gap="30px">
        <Button type="submit">
          {t("saveChanges")}
        </Button>
      </ControlGroup>
    </form>
  )
}

const initializePasswordFields = (args: any, state: object, utils: any): void => {
  utils.changeValue(state, 'password', () => "")
  utils.changeValue(state, 'passwordConfirmation', () => "")
}

interface AccountFormProps {
  onSubmit: (attrs: Account) => Promise<void>;
  account: Account | null;
}

const AccountForm: React.FC<AccountFormProps> = (props: AccountFormProps) => {
  const { onSubmit, account } = props

  if (!account) return null

  return (
    <Form
      initialValues={account}
      mutators={{ initializePasswordFields }}
      onSubmit={onSubmit}
      render={renderForm}
    />
  )
}

export default AccountForm
