import React from "react"
import { FormSpy } from "react-final-form"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import { getPredefinedIcon } from "../../../utils/predefinedIcons"
import Field from "../../../utils/widget-builder/Field"
import FontCustomization from "../shared/FontCustomization"
import FormGroup from "../../form-fields/FormGroup"
import IconSelect from "../shared/IconSelect"
import IconTabSelect from "../shared/IconTabSelect"

const ListCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup visible={!!parameterTypes.listIconsDisabled}>
        <Field
          className="form-checkbox--reversed"
          name="listIconsDisabled"
          prefix="parameters"
          type={parameterTypes.listIconsDisabled}
        />
      </FormGroup>
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const {
            activeIconTab,
            parameters: { listIconsDisabled, listIcon1, listIcon2, listIcon3, listIcon4 }
          } = props.values

          if (listIconsDisabled) return null

          const icons = {
            listIcon1: getPredefinedIcon(listIcon1),
            listIcon2: getPredefinedIcon(listIcon2),
            listIcon3: getPredefinedIcon(listIcon3),
          } as any

          if (parameterTypes.listIcon4) {
            icons.listIcon4 = getPredefinedIcon(listIcon4)
          }

          const iconSelectName = activeIconTab || "listIcon1"
          return (
            <>
              <FormGroup visible={!!parameterTypes[iconSelectName]}>
                <IconTabSelect
                  columns={Object.keys(icons).length}
                  initialValue="listIcon1"
                  icons={icons}
                  name="activeIconTab"
                  type="text"
                />
              </FormGroup>
              <FormGroup visible={!!parameterTypes[iconSelectName]}>
                <IconSelect
                  name={`parameters.${iconSelectName}`}
                  type={parameterTypes[iconSelectName]}
                />
              </FormGroup>
              <FormGroup title={t("icon")} visible={!!parameterTypes[`${iconSelectName}Color`]}>
                <Field
                  initialValue="#83cf48"
                  name={`${iconSelectName}Color`}
                  prefix="theme"
                  type={parameterTypes[`${iconSelectName}Color`]}
                />
              </FormGroup>
              <FormGroup
                title={t("background")}
                visible={!!parameterTypes[`${iconSelectName}BackgroundColor`]}
              >
                <Field
                  initialValue="#e4f7d6"
                  name={`${iconSelectName}BackgroundColor`}
                  prefix="theme"
                  type={parameterTypes[`${iconSelectName}BackgroundColor`]}
                />
              </FormGroup>
            </>
          )
        }}
      </FormSpy>
      <FormGroup visible={!!parameterTypes.listText1}>
        <Field name="listText1" prefix="parameters" type={parameterTypes.listText1} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.listText2}>
        <Field name="listText2" prefix="parameters" type={parameterTypes.listText2} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.listText3}>
        <Field name="listText3" prefix="parameters" type={parameterTypes.listText3} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.listText4}>
        <Field name="listText4" prefix="parameters" type={parameterTypes.listText4} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.listWithIconFontSize}>
        <FontCustomization namePrefix="listWithIcon" parameterTypes={parameterTypes} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.listWithIconTextColor}>
        <Field
          initialValue="#000000b3"
          name="listWithIconTextColor"
          prefix="theme"
          type={parameterTypes.listWithIconTextColor}
        />
      </FormGroup>
    </>
  )
}

export default ListCustomization
