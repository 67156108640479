import React, { useState } from "react"
import { FormSpy } from "react-final-form"
import useInterval from "@use-it/interval"
import { useDebounce } from "../../utils/debounce"
import widgetsApi from "../../api/widgets"

const getScale = (): number => {
  const container = document.querySelector(".widget-builder__preview")
  const containerHeight = container?.clientHeight
  const containerWidth = container?.clientWidth
  const scaleBox = document.querySelector(".pliek-autoscale-wrapper")
  const boxHeight = scaleBox?.clientHeight
  const boxWidth = scaleBox?.clientWidth
  if (!containerHeight || !containerWidth || !boxWidth || !boxHeight) return 1
  const scaleX = containerWidth / boxWidth
  const scaleY = containerHeight / boxHeight
  return Math.min(scaleX, scaleY, 1)
}

const WidgetPreview: React.FC<{}> = () => {
  return (
    <FormSpy subscription={{ values: true }}>
      {(props: any): any => {
        const [widget, setWidget] = useState({} as any)
        const { values } = props
        const [scale, setScale] = useState(getScale())

        useDebounce((): void => {
          widgetsApi.getPreview(values).then(setWidget)
        }, 500, [values])

        useInterval(() => {
          setScale(getScale())
        }, 100)

        if (!widget) return null

        let style = widget.style

        // TODO: consider moving this logic to template styles
        if (widget.backgroundImageUrl && widget.html) {
          const backgroundImageStyle = `background-image: url(${widget.backgroundImageUrl});`
          const className = widget.html.includes("pliek-image") ? "pliek-image" : "pliek-box"
          style += `.${className} { ${backgroundImageStyle} }`
        }

        return (
          <div id={widget.htmlId}>
            <style>{style}</style>
            <div className="pliek-autoscale-wrapper">
              <div
                className={"pliek-box pliek-box--loaded" + (widget.layout ? " pliek-" + widget.layout : "")}
                style={{ transform: `scale(${scale})` }}
                dangerouslySetInnerHTML={{ __html: widget.html }}
              />
            </div>
          </div>
        )
      }}
    </FormSpy>
  )
}

export default WidgetPreview
