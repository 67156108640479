import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const TextareaIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="52" height="36" viewBox="0 0 52 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 33V3C1 1.89543 1.89543 1 3 1H49C50.1046 1 51 1.89543 51 3V33C51 34.1046 50.1046 35 49 35H3C1.89543 35 1 34.1046 1 33Z" stroke={primaryColor} strokeWidth="2" strokeLinecap="round"/>
      <path d="M49 31L47 33" stroke={primaryColor} strokeLinecap="round"/>
      <path d="M49 27L43 33" stroke={primaryColor} strokeLinecap="round"/>
      <path d="M6.64991 15.0001C6.58591 15.0001 6.52991 14.9761 6.48191 14.9281C6.43391 14.8801 6.40991 14.8241 6.40991 14.7601C6.40991 14.6961 6.41391 14.6521 6.42191 14.6281L9.39791 6.8641C9.46191 6.6881 9.58991 6.6001 9.78191 6.6001H10.5979C10.7899 6.6001 10.9179 6.6881 10.9819 6.8641L13.9459 14.6281L13.9699 14.7601C13.9699 14.8241 13.9459 14.8801 13.8979 14.9281C13.8499 14.9761 13.7939 15.0001 13.7299 15.0001H13.1179C13.0299 15.0001 12.9619 14.9801 12.9139 14.9401C12.8659 14.8921 12.8339 14.8441 12.8179 14.7961L12.1579 13.0921H8.22191L7.56191 14.7961C7.54591 14.8441 7.50991 14.8921 7.45391 14.9401C7.40591 14.9801 7.34191 15.0001 7.26191 15.0001H6.64991ZM11.8099 12.0601L10.1899 7.8001L8.56991 12.0601H11.8099Z" fill={primaryColor}/>
      <path d="M16.837 15.1201C16.445 15.1201 16.081 15.0401 15.745 14.8801C15.409 14.7201 15.141 14.5041 14.941 14.2321C14.749 13.9521 14.653 13.6441 14.653 13.3081C14.653 12.7801 14.869 12.3481 15.301 12.0121C15.741 11.6681 16.333 11.4441 17.077 11.3401L18.865 11.0881V10.7401C18.865 9.9241 18.397 9.5161 17.461 9.5161C17.109 9.5161 16.821 9.5921 16.597 9.7441C16.373 9.8881 16.205 10.0601 16.093 10.2601C16.069 10.3321 16.037 10.3841 15.997 10.4161C15.965 10.4481 15.917 10.4641 15.853 10.4641H15.337C15.265 10.4641 15.201 10.4401 15.145 10.3921C15.097 10.3361 15.073 10.2721 15.073 10.2001C15.081 10.0081 15.169 9.7921 15.337 9.5521C15.513 9.3041 15.781 9.0921 16.141 8.9161C16.501 8.7321 16.945 8.6401 17.473 8.6401C18.369 8.6401 19.013 8.8521 19.405 9.2761C19.797 9.6921 19.993 10.2121 19.993 10.8361V14.7241C19.993 14.8041 19.965 14.8721 19.909 14.9281C19.861 14.9761 19.797 15.0001 19.717 15.0001H19.165C19.085 15.0001 19.017 14.9761 18.961 14.9281C18.913 14.8721 18.889 14.8041 18.889 14.7241V14.2081C18.713 14.4641 18.457 14.6801 18.121 14.8561C17.785 15.0321 17.357 15.1201 16.837 15.1201ZM17.089 14.2201C17.609 14.2201 18.033 14.0521 18.361 13.7161C18.697 13.3721 18.865 12.8801 18.865 12.2401V11.9041L17.473 12.1081C16.905 12.1881 16.477 12.3241 16.189 12.5161C15.901 12.7001 15.757 12.9361 15.757 13.2241C15.757 13.5441 15.889 13.7921 16.153 13.9681C16.417 14.1361 16.729 14.2201 17.089 14.2201Z" fill={primaryColor}/>
    </svg>
  )
}

export default TextareaIcon
