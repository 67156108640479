import React from "react"
import cn from 'classnames'
import { Link as RouterLink } from "@reach/router"
import RegularButton from "../buttons/RegularButton"
import RegularLink from "../buttons/RegularLink"
import { StatefulIconProps } from "../../utils/icons"

interface LinkProps {
  activeSection?: string | null;
  className?: string;
  external?: boolean;
  icon?: React.FC<StatefulIconProps>;
  onClick?: any;
  section?: string;
  target?: string;
  to?: string;
}

const NavLink: React.FC<LinkProps> = ({
  activeSection, children, className, external, icon, onClick, section, target, to
}) => {
  const isActive = !!section && section === activeSection
  const linkClassName = cn(`app-sidebar__link`, className, {
    "app-sidebar__link--active": isActive
  })

  const Component: React.FC<any> = !to ? RegularButton : (external ? RegularLink : RouterLink)
  const Icon: any = icon

  return (
    <Component className={linkClassName} href={to} onClick={onClick} target={target} to={to}>
      <div className="app-sidebar__icon">
        {icon && <Icon active={isActive} />}
      </div>
      <div className="app-sidebar__link-text">
        {children}
      </div>
    </Component>
  )
}

export default NavLink
