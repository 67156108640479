import "react-phone-number-input/style.css"
import React from "react"
import cn from "classnames"
import { Field, FieldRenderProps } from "react-final-form"
import PhoneInput from "react-phone-number-input"

const renderField = (props: FieldRenderProps<any>): React.ReactNode => {
  const { className, input, label, meta, placeholder } = props
  const error = meta.error || meta.submitError && meta.submitError[0]
  const classNames = cn(`form-text-input form-text-input-text form-text-input-phone`, className, {
    "form-text-input--error": !!error
  })
  return (
    <label className={classNames}>
      {label && <div className="form-text-input__label"> {label} </div>}
      <div className="form-text-input__input">
        <div className="phone-input-wrapper">
          <PhoneInput
            onChange={(value: string): void => input.onChange(value)}
            placeholder={placeholder}
            value={input.value}
          />
        </div>
        {error && <div className="form-text-input__error-text">{error}</div>}
      </div>
    </label>
  )
}

interface PhoneFieldProps {
  label?: string;
  name: string;
}

const parsePhoneToTextNumber = (value: any): string => {
  const number = parseInt((value || "").replace(/\D/g, ""))
  return isNaN(number) ? "" : number.toString()
}

const PhoneField: React.FC<PhoneFieldProps> = (props: PhoneFieldProps) => {
  return (
    <Field
      {...props}
      format={(value: any): string => `+${value}`}
      render={renderField}
      parse={parsePhoneToTextNumber}
      type="text"
    />
  )
}

export default PhoneField
