import React from "react"
import { useAlert } from "react-alert"
import { Form, FormRenderProps } from "react-final-form"
import { Link as RouterLink, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../components/buttons/Button"
import ControlGroup from "../components/boxes/ControlGroup"
import Header from "../components/boxes/Header"
import Paper from "../components/boxes/Paper"
import TextField from "../components/form-fields/TextField"
import illustration from "../images/sign-up.svg"
import usersApi from "../api/users"

const initialValues: UserForgotPasswordAttrs = {
  email: "",
}

const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const alert = useAlert()

  const handleFormSubmit = async (attrs: UserForgotPasswordAttrs): Promise<any> => {
    return usersApi.requestNewPassword(attrs)
      .then((message: any) => alert.show(message))
      .catch((errors: any) => errors)
  }

  return (
    <div className="sign-up-container">
      <img className="sign-up-container__illustration" src={illustration}/>
      <Form
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }: FormRenderProps<UserForgotPasswordAttrs>): React.ReactNode => {
          return (
            <div className="sign-up-form">
              <form onSubmit={handleSubmit}>
                <div className="sign-up-user-fields">
                  <Header variant="main" text={t("forgotPassword")}/>
                  <Paper gap="30px">
                    <ControlGroup maxWidth="480px">
                      <TextField type="email" name="email" label="Email" />
                      <RouterLink className="link" to="/login">
                        {t("SignIn")}
                      </RouterLink>
                    </ControlGroup>
                  </Paper>
                  <ControlGroup gap="30px">
                    <Button type="submit">
                      {t("sendMeResetPasswordInstructions")}
                    </Button>
                  </ControlGroup>
                </div>
              </form>
            </div>
          )
        }}
      />
    </div>
  )
}

export default ForgotPassword
