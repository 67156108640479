import React from "react"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FontCustomization from "../shared/FontCustomization"
import FormGroup from "../../form-fields/FormGroup"

const MainTextCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <>
      <FormGroup visible={!!parameterTypes.mainText}>
        <Field name="mainText" prefix="parameters" type={parameterTypes.mainText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.mainTextHint}>
        <Field name="mainTextHint" prefix="parameters" type={parameterTypes.mainTextHint} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.liveVisitorsText}>
        <Field name="liveVisitorsText" prefix="parameters" type={parameterTypes.liveVisitorsText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.liveVisitorsPeriodMinutes}>
        <Field name="liveVisitorsPeriodMinutes" prefix="parameters" type={parameterTypes.liveVisitorsPeriodMinutes} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.showVisitorsToday}>
        <Field name="showVisitorsToday" prefix="parameters" type={parameterTypes.showVisitorsToday} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.visitorsTodayText}>
        <Field name="visitorsTodayText" prefix="parameters" type={parameterTypes.visitorsTodayText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.fontSize}>
        <FontCustomization namePrefix="" parameterTypes={parameterTypes} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.textColor}>
        <Field initialValue="#000000" name="textColor" prefix="theme" type={parameterTypes.textColor} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.altTextColor}>
        <Field initialValue="#ffffff" name="altTextColor" prefix="theme" type={parameterTypes.altTextColor} />
      </FormGroup>
    </>
  )
}

export default MainTextCustomization
