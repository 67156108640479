import React from "react"
import cn from "classnames"
import { Link as RouterLink } from "@reach/router"
import DownloadLink from "./DownloadLink"
import RegularButton from "./RegularButton"
import RegularLink from "./RegularLink"
import { StatefulIconProps } from "../../utils/icons"

interface ButtonProps {
  children?: React.ReactNode;
  className?: string;
  download?: boolean;
  engaging?: boolean;
  external?: boolean;
  href?: string | null;
  icon?: React.FC<StatefulIconProps>;
  noBackground?: boolean;
  onClick?: any;
  secondary?: boolean;
  tertiary?: boolean;
  rounded?: boolean;
  type?: string;
}

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const {
    children, className, download, engaging, external, href, icon, noBackground,
    onClick, secondary, tertiary, rounded, type
  } = props

  const component = (): React.FC<any> => {
    if (!href) return RegularButton
    if (download) return DownloadLink
    if (external) return RegularLink
    return RouterLink
  }

  const Component: React.FC<any> = component()
  const Icon: any = icon

  const buttonClassName = cn("button", className, {
    "button--engaging": engaging,
    "button--no-background": noBackground,
    "button--rounded": rounded,
    "button--secondary": secondary,
    "button--tertiary": tertiary,
    "button--without-text": !children,
  })

  const componentOptions = {} as any
  if (external) {
    componentOptions.href = href
    componentOptions.target = "_blank"
  }

  return (
    <Component {...componentOptions} className={buttonClassName} onClick={onClick} to={href} type={type}>
      {icon && <div className="button__icon"><Icon /></div>}
      {children && <div className="button__text">{children}</div>}
    </Component>
  )
}

export default Button
