import React from "react"

interface OptionProps {
  name: string;
  value: any;
}

interface SearchFieldProps {
  onChange: (value: any) => void;
  options: OptionProps[];
  selectedValue: any;
}

const SearchField: React.FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const { onChange, options, selectedValue } = props

  return (
    <select
      className="filter filter-select"
      defaultValue={selectedValue}
      onChange={(e: any): void => onChange(e.target.value)}
    >
      {options.map((optionProps) => (
        <option key={optionProps.value} value={optionProps.value}>
          {optionProps.name}
        </option>
      ))}
    </select>
  )
}

export default SearchField
