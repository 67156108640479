import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import ChangePlan from "../components/change-plan"
import couponApi from "../api/coupons"
import plansApi from "../api/billingPlans"
import templatesApi from "../api/templates"
import illustration from "../images/sign-up.svg"

const ChangePlanPage: React.FC<RouteComponentProps> = () => {
  const [coupon, setCoupon] = useState<CouponCode | null>(null)
  const [plans, setPlans] = useState<BillingPlan[]>([])
  const [templates, setTemplates] = useState<WidgetTemplate[]>([])
  const [widgetTemplateIds, setWidgetTemplateIds] = useState<number[]>([])

  useEffect(() => {
    couponApi.getCouponCode(null).then(setCoupon)
    plansApi.getAll().then(setPlans)
    templatesApi.getAll().then(setTemplates)
    templatesApi.getUsage().then((data: WidgetTemplatesUsage) => {
      const { usedTemplateIds } = data
      setWidgetTemplateIds(usedTemplateIds)
    })
  }, [])

  return (
    <div className="sign-up-container">
      <img className="sign-up-container__illustration" src={illustration}/>
      <ChangePlan
        existingCoupon={coupon}
        onSubmit={plansApi.switchPlan}
        plans={plans}
        templates={templates}
        widgetTemplateIds={widgetTemplateIds}
      />
    </div>
  )
}

export default ChangePlanPage
