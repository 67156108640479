import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../components/buttons/Button"
import DomainIcon from "../images/icons/other/DomainIcon"
import Layout from "../components/layout/DomainsLayout"
import SiteForm from "../components/sites/SiteForm"
import sitesApi from "../api/sites"

const handleSubmit = async (attrs: CustomerSite): Promise<any> => {
  return sitesApi.save(attrs)
    .then(() => navigate(`/sites`))
    .catch(errors => errors)
}

interface SiteEditPageProps extends RouteComponentProps {
  siteId?: string;
}

const HeaderContent: any = (props: any) => {
  const { t } = useTranslation()
  const { siteId } = props
  const [isDeletionInitiated, setIsDeletionInitiated] = useState(false)

  const handleDeleteSite = async (): Promise<any> => {
    return sitesApi.destroy(siteId)
      .then((): Promise<void> => navigate(`/sites`))
      .catch((errors: any) => errors)
  }

  if (!isDeletionInitiated) return (
    <Button tertiary onClick={(): void => setIsDeletionInitiated(true)}>
      {t("deleteDomain")}
    </Button>
  )

  if (isDeletionInitiated) return (
    <>
      <Button tertiary onClick={(): void => { siteId && handleDeleteSite() }}>
        {t("confirm")}
      </Button>
      <Button tertiary onClick={(): void => setIsDeletionInitiated(false)}>
        {t("cancel")}
      </Button>
    </>
  )
}

const SiteEdit: React.FC<SiteEditPageProps> = (props: SiteEditPageProps) => {
  const { siteId } = props
  const { t } = useTranslation()
  const [site, setSite] = useState<CustomerSite | null>(null)

  useEffect(() => {
    sitesApi.getOneWithWidgets(siteId).then(setSite)
  }, [])

  return (
    <Layout
      activeSiteId={site && (site.hostname || site.id) || undefined}
      activeSubSection="manage"
      headerContent={site && <HeaderContent siteId={siteId} />}
      headerIcon={site && <DomainIcon src={site.iconUrl} />}
      headerText={site?.name || ""}
      headerSubText={t("navigationManageDomain")}
    >
      <SiteForm onSubmit={handleSubmit} site={site} />
    </Layout>
  )
}

export default SiteEdit
