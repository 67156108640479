import React from "react"
import { useTranslation } from "react-i18next"
import RadioGroup from "../../form-fields/RadioGroup"

const types: string[] = ["avatar", "icon"]

interface IconTypeSelectProps {
  name: string;
  type: string;
}

const IconTypeSelect: React.FC<IconTypeSelectProps> = (props: IconTypeSelectProps) => {
  const { t } = useTranslation()

  const renderOptionProps = (tabName: string): object => ({
    label: t(`IconTypes_${tabName}`),
  })

  return (
    <RadioGroup
      {...props}
      className="widget-form__icon-types"
      options={types}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default IconTypeSelect
