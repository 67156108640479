import React from "react"

const ManageIcon: React.FC<{}> = () => {
  return (
    <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="2.5" y1="0.5" x2="2.5" y2="15.5" stroke="#BAD0E0" strokeLinecap="round"/>
      <line x1="9.5" y1="0.5" x2="9.5" y2="15.5" stroke="#BAD0E0" strokeLinecap="round"/>
      <circle cx="2.5" cy="9.5" r="2" fill="white" stroke="#BAD0E0"/>
      <circle cx="9.5" cy="4.5" r="2" fill="white" stroke="#BAD0E0"/>
    </svg>
  )
}

export default ManageIcon
