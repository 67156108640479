import React from "react"

const BluePlusIcon: React.FC<{}> = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.950195" y1="5.5" x2="11.1152" y2="5.5" stroke="#5869fa" strokeLinecap="round"/>
      <line x1="6.02539" y1="0.5" x2="6.02539" y2="10.5" stroke="#5869fa" strokeLinecap="round"/>
    </svg>
  )
}

export default BluePlusIcon
