import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/SettingsLayout"
import AccountForm from "../components/settings/AccountForm"
import accountApi from "../api/account"

const handleSubmit = async (attrs: Account): Promise<any> => {
  return accountApi.save(attrs)
    .then(() => window.location.reload())
    .catch(errors => errors)
}

const AccountEdit: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const [account, setAccount] = useState<Account | null>(null)

  useEffect(() => {
    accountApi.get().then(setAccount)
  }, [])

  return (
    <Layout
      activeSubSection="account"
      headerText={t("navigationAccount")}
    >
      <AccountForm onSubmit={handleSubmit} account={account} />
    </Layout>
  )
}

export default AccountEdit
