import React from "react"
import cn from "classnames"
import RadioGroup from "../../form-fields/RadioGroup"

interface IconTabSelectProps {
  className?: string;
  columns?: number;
  icons: { [key: string]: React.ReactNode };
  initialValue?: any;
  name: string;
  type: string;
}

const IconTabSelect: React.FC<IconTabSelectProps> = (props: IconTabSelectProps) => {
  const { className, icons, ...restProps } = props

  const renderOptionProps = (tabName: string): object => ({
    icon: icons[tabName],
  })

  return (
    <RadioGroup
      {...restProps}
      className={cn("widget-form__icons widget-form__icon-tabs", className)}
      options={Object.keys(icons)}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default IconTabSelect
