import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { navigate, RouteComponentProps } from "@reach/router"
import Button from "../components/buttons/Button"
import Layout from "../components/layout/DomainsLayout"
import TemplatePicker from "../components/templates/TemplatePicker"
import WidgetBuilder from "../components/widgets/WidgetBuilder"
import widgetsApi from "../api/widgets"

const handleSubmit = async (attrs: CustomerWidget): Promise<any> => {
  return widgetsApi.save(attrs)
    .then(() => navigate(`/sites/${attrs.siteHostName || attrs.siteId}/widgets`))
    .catch(errors => errors)
}

interface SiteWidgetsNewProps extends RouteComponentProps {
  siteId?: string;
}

const HeaderContent: React.FC<SiteWidgetsNewProps> = (props: SiteWidgetsNewProps) => {
  const { t } = useTranslation()
  const { siteId } = props

  return(
    <Button secondary href={siteId && `/sites/${siteId}/widgets`}>
      {t("backToWidgets")}
    </Button>
  )
}

const SiteWidgetsNew: React.FC<SiteWidgetsNewProps> = (props: SiteWidgetsNewProps) => {
  const { siteId } = props
  const { t } = useTranslation()
  const [template, setTemplate] = useState<WidgetTemplate | null>(null)
  const [templates, setTemplates] = useState<WidgetTemplate[]>([])
  const [widget, setWidget] = useState<CustomerWidget | null>(null)

  useEffect(() => {
    widgetsApi.getNew(siteId).then(setWidget)
    widgetsApi.getTemplates().then(setTemplates)
  }, [])

  const handleTemplateSelect = (template: WidgetTemplate): void => {
    setWidget({ ...(widget as CustomerWidget), templateId: template.id })
    setTemplate(template)
  }

  return (
    <Layout
      activeSiteId={siteId}
      activeSubSection="campaigns"
      headerContent={template ? undefined : <HeaderContent siteId={siteId} />}
      headerSubText={template ? undefined : t("selectTemplate")}
      headerText={template ? undefined : t("newCampaign")}
    >
      {!template && templates && (
        <TemplatePicker onSelect={handleTemplateSelect} templates={templates}/>
      )}
      {template && widget && (
        <WidgetBuilder onSubmit={handleSubmit} templates={templates} widget={widget}/>
      )}
    </Layout>
  )
}

export default SiteWidgetsNew
