import React from "react"
import { useTranslation } from "react-i18next"
import Card from "./StatisticsCard"
import TopPages from "./StatisticsTopPages"
import Visitors from "./StatisticsVisitors"

import { ReactComponent as ConversionRateIcon } from "../../../assets/images/icons/statistics/conversion-rate.svg"
import { ReactComponent as EngagementIcon } from "../../../assets/images/icons/statistics/engagement.svg"
import { ReactComponent as ImpressionsIcon } from "../../../assets/images/icons/statistics/impressions.svg"
import { ReactComponent as LeadsIcon } from "../../../assets/images/icons/statistics/leads.svg"
import { ReactComponent as VisitorsIcon } from "../../../assets/images/icons/statistics/visitors.svg"

const Statistics: React.FC<CustomerSiteStatistics> = (props: CustomerSiteStatistics) => {
  const { conversionRate, engagements, impressions, leads, topPages, visitors, visitorsByDates } = props
  const { t } = useTranslation()

  return (
    <div className="statistics">
      <Card icon={ImpressionsIcon} title={t("impressions")} value={impressions} />
      <Card icon={VisitorsIcon} title={t("visitors")} value={visitors} />
      <Card icon={EngagementIcon} title={t("engagement")} value={engagements} />
      <Card icon={LeadsIcon} title={t("leads")} value={leads} />
      <Card
        icon={ConversionRateIcon}
        title={t("conversionRate")}
        value={`${Math.round(conversionRate * 100)}%`}
      />
      <Visitors visitorsByDates={visitorsByDates} />
      <TopPages topPages={topPages} />
    </div>
  )
}

export default Statistics
