import React from "react"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const CouponCodesCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <Field name="codes" prefix="parameters" type={parameterTypes.codes} />
      <FormGroup title={t("color")} visible={!!parameterTypes.couponTextColor}>
        <Field initialValue="#42A5F5" name="couponTextColor" prefix="theme" type={parameterTypes.couponTextColor} />
      </FormGroup>
      <FormGroup title={t("background")} visible={!!parameterTypes.couponBackgroundColor}>
        <Field initialValue="#ffffff" name="couponBackgroundColor" prefix="theme" type={parameterTypes.couponBackgroundColor} />
      </FormGroup>
    </>
  )
}

export default CouponCodesCustomization
