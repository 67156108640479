import "react-datetime/css/react-datetime.css"
import React, { useEffect, useRef } from "react"
import moment, { Moment } from "moment"
import Datetime from "react-datetime"
import { Field, FieldRenderProps } from "react-final-form"

const renderField = (props: FieldRenderProps<string>): React.ReactNode => {
  const { input, label } = props
  const wrapperRef = useRef<HTMLLabelElement | null>(null)

  const onChange = (value: string | Moment | null): void => {
    input.onChange(value)
  }

  const handleFieldClean = (e: KeyboardEvent): void => {
    if (e.key !== "Delete" && e.key !== "Backspace") return

    onChange(null)
  }

  useEffect(() => {
    if (!wrapperRef.current) return

    wrapperRef.current.addEventListener('keydown', handleFieldClean)

    return (): void => {
      if (!wrapperRef.current) return

      wrapperRef.current.removeEventListener('keydown', handleFieldClean)
    }
  })

  return (
    <label ref={wrapperRef} className="form-text-input form-text-input-text datetime-picker__wrapper">
      {label && <div className="form-text-input__label">{label}</div>}
      <Datetime
        className="form-text-input__input datetime-picker"
        inputProps={{ className: "text-input datetime-picker__input", placeholder: label, readOnly: true }}
        onChange={onChange}
        value={input.value}
      />
    </label>
  )
}

export interface DateTimeFieldProps {
  initialValue?: string;
  name: string;
}

const DateTimeField: React.FC<DateTimeFieldProps> = (props: DateTimeFieldProps) => {
  return (
    <Field
      {...props}
      render={renderField}
      type="text"
      format={(value: any): any => value ? moment(value) : value}
      parse={(value: any): any => value && value.toISOString ? value.toISOString() : value}
    />
  )
}

export default DateTimeField
