import axios from "axios"
import { navigate } from "@reach/router"
import { getCsrfToken } from "../utils"

const jsonRequest = axios.create({
  baseURL: "/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": getCsrfToken(),
  },
})

jsonRequest.interceptors.response.use(response => {
  const accountInfoJSON = response.headers["pliek-account-info"]
  if (accountInfoJSON) {
    window.localStorage.setItem("accountInfo", accountInfoJSON)
  }
  return Promise.resolve(response)
}, error => {
  if (error.response.status === 402 /* :payment_required */) {
    navigate("/settings/billing")
  }
  return Promise.reject(error)
})

export default jsonRequest
