import React from "react"

const CodeIcon: React.FC<{}> = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 7L6.29289 10.2929C6.68342 10.6834 7.31658 10.6834 7.70711 10.2929L11 7" stroke="#A9C0D1" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.94952 0.999927L6.94974 10.0002" stroke="#A9C0D1" strokeLinecap="round"/>
      <path d="M1.00038 12.5002L12.5001 12.4995" stroke="#A9C0D1" strokeLinecap="round"/>
    </svg>
  )
}

export default CodeIcon
