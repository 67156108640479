import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../components/buttons/Button"
import EmptyCollection from "../components/layout/EmptyCollection"
import Layout from "../components/layout/DomainsLayout"
import PlusIcon from "../images/icons/other/PlusIcon"
import SiteCard from "../components/sites/SiteCard"
import sitesApi from "../api/sites"

type SiteIndexProps = RouteComponentProps

const HeaderContent: React.FC<{}> = () => {
  const { t } = useTranslation()

  return(
    <Button icon={PlusIcon} href="/sites/new">
      {t("newDomain")}
    </Button>
  )
}

const SiteIndex: React.FC<SiteIndexProps> = () => {
  const { t } = useTranslation()
  const [isSitesFetched, setIsSitesFetched] = useState(false)
  const [sites, setSites] = useState<CustomerSite[]>([])

  const refreshSites = (): void => {
    sitesApi.getAll().then((data: CustomerSite[]) => {
      setSites(data)
      setIsSitesFetched(true)
    })
  }

  useEffect(() => {
    refreshSites()
  }, [])

  return (
    <Layout
      activeSubSection="list"
      headerText={t("AllDomains")}
      headerContent={isSitesFetched && sites.length !== 0 && <HeaderContent />}
      sites={sites}
    >
      {sites.map(s => (
        <SiteCard onUpdate={refreshSites} site={s} key={JSON.stringify(s)} />
      ))}
      {isSitesFetched && sites.length === 0 && <EmptyCollection
        title={t("emptyDomainsTitle")}
        description={t("emptyDomainsDescription")}
        actionText={t("addDomain")}
        onAction={(): Promise<void> => navigate("/sites/new")}
      />}
    </Layout>
  )
}

export default SiteIndex
