import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { navigate } from "@reach/router"
import arrayMutators from "final-form-arrays"
import { Form, FormRenderProps } from "react-final-form"
import Plans from "./Plans"
import Widgets from "./Widgets"

interface ChangePlanProps {
  existingCoupon?: CouponCode | null;
  plans: BillingPlan[];
  templates: WidgetTemplate[];
  widgetTemplateIds: number[];
  onSubmit(attrs: SwitchBillingPlanAttrs): Promise<any>;
}

const initialValues: SwitchBillingPlanAttrs = {
  billingPlanId: null,
  widgetTemplateIds: [],
}

const SignUp: React.FC<ChangePlanProps> = (props) => {
  const { existingCoupon, plans, templates, onSubmit, widgetTemplateIds } = props
  const { t } = useTranslation()

  const [values, setValues] = useState<SwitchBillingPlanAttrs>(initialValues)
  const [isWidgetsShown, setIsWidgetsShown] = useState(false)

  useEffect((): void => {
    setValues({ ...values, widgetTemplateIds })
  }, [widgetTemplateIds])

  const handleFormSubmit = async (attrs: SwitchBillingPlanAttrs): Promise<any> => {
  return onSubmit(attrs)
    .then((redirectUrl) => {
      window.location.replace(redirectUrl ? redirectUrl : "/settings/billing")
    })
    .catch((errors: any) => errors)
  }

  const handleClickPlan = (id: number): void => {
    const plan = plans.find(p => p.id === id)
    if (plan?.perWidget) {
      setValues({ ...values, billingPlanId: id })
      setIsWidgetsShown(true)
    } else {
      handleFormSubmit({ ...values, billingPlanId: id })
    }
  }

  const backToPlans = (): void => {
    setValues({ ...values, billingPlanId: null })
    setIsWidgetsShown(false)
  }

  return (
    <Form
      initialValues={values}
      mutators={{...arrayMutators}}
      onSubmit={handleFormSubmit}
      render={(renderProps: FormRenderProps<SwitchBillingPlanAttrs>): React.ReactNode => {
        const { handleSubmit } = renderProps
        return (
          <div className="sign-up-form">
            <form onSubmit={handleSubmit}>
              {!isWidgetsShown && (
                <>
                  <Plans existingCoupon={existingCoupon} onClick={handleClickPlan} plans={plans} onBack={(): Promise<void> => navigate("/settings/billing")}/>
                </>
              )}
              {isWidgetsShown && (
                <Widgets
                  confirmationText={t("confirm")}
                  templates={templates}
                  onBack={backToPlans}
                />
              )}
            </form>
          </div>
        )
      }}
    />
  )
}

export default SignUp
