import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const CheckboxIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="47" height="22" viewBox="0 0 47 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="20" height="20" rx="4" fill="none" stroke={primaryColor} strokeWidth="2"/>
      <path d="M8 11.5L10.5 14.5L15 8" stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M27 1.5C27 1.22386 27.2239 1 27.5 1H46.5C46.7761 1 47 1.22386 47 1.5C47 1.77614 46.7761 2 46.5 2H27.5C27.2239 2 27 1.77614 27 1.5Z" fill={primaryColor}/>
      <path d="M27 10.5C27 10.2239 27.2239 10 27.5 10H46.5C46.7761 10 47 10.2239 47 10.5C47 10.7761 46.7761 11 46.5 11H27.5C27.2239 11 27 10.7761 27 10.5Z" fill={primaryColor}/>
      <path d="M27 19.5C27 19.2239 27.2239 19 27.5 19H40.5C40.7761 19 41 19.2239 41 19.5C41 19.7761 40.7761 20 40.5 20H27.5C27.2239 20 27 19.7761 27 19.5Z" fill={primaryColor}/>
    </svg>
  )
}

export default CheckboxIcon
