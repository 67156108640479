import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const ListIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="12" r="11" stroke={primaryColor} strokeWidth="2"/>
      <line x1="0.5" y1="30.5" x2="39.5" y2="30.5" stroke={primaryColor} strokeLinecap="round"/>
      <line x1="5.5" y1="37.5" x2="34.5" y2="37.5" stroke={primaryColor} strokeLinecap="round"/>
      <path d="M15.7998 11.9998L18.7998 15.5998L24.1998 7.7998" stroke={primaryColor} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ListIcon
