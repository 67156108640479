import React from "react"
import cn from "classnames"
import { Field, FieldRenderProps } from "react-final-form"
import CheckIcon from "../../images/Check"
import { ReactComponent as LockIcon } from "../../../assets/images/icons/other/lock.svg"

interface CheckboxFieldRenderProps extends FieldRenderProps<boolean> {
  kind: "checkbox" | "switch" | "roundbox";
  locked?: boolean;
  onChange?: any;
}

const renderField = (props: CheckboxFieldRenderProps): React.ReactNode => {
  const { className, children, kind = "checkbox", input, label, locked, meta, onChange } = props
  const error = meta.submitError && meta.submitError[0]
  const handleStateChange = (data: object): void => {
    if (locked) return;

    input.onChange(data)
    onChange && onChange(data)
  }

  return (
    <label className={cn("form-checkbox", className)}>
      <input
        checked={input.checked}
        className="form-checkbox__input"
        name={input.name}
        onChange={handleStateChange}
        type="checkbox"
        readOnly={!!locked}
      />
      <span className={`form-checkbox__box form-checkbox__${kind}`}>
        <CheckIcon/>
      </span>
      {!label && children}
      {label && <div className="form-control__label">
        {label}
        {locked && <LockIcon/>}
      </div>}
      {error && <div className="form-control__error-text">{error}</div>}
    </label>
  )
}

export interface CheckboxFieldProps {
  children?: React.ReactNode;
  kind?: "checkbox" | "switch" | "roundbox";
  label?: string;
  onChange?: any;
  name: string;
  type?: "boolean";
}

const CheckboxField: React.FC<CheckboxFieldProps> = (props: CheckboxFieldProps) => {
  return (
    <Field
      {...props}
      render={renderField}
      type="checkbox"
    />
  )
}

export default CheckboxField
