import React from "react"

const ViewIcon: React.FC<{}> = () => {
  return (
    <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1H1" stroke="#BAD0E0" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13 5H1" stroke="#BAD0E0" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.9998 9H5.7998" stroke="#BAD0E0" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ViewIcon
