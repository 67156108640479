import React from "react"
import { useTranslation } from "react-i18next"
import AppStorage from "../../utils/storage"
import Button from "../buttons/Button"
import CustomerLayout from "./CustomerLayout"
import NavLink from "./NavLink"

import AccountIcon from "../../images/icons/menu/AccountIcon"
import BillingIcon from "../../images/icons/menu/BillingIcon"

interface LayoutProps {
  activeSubSection: string;
  headerContent?: React.ReactNode;
  headerText?: string;
  headerSubText?: string;
}

const HeaderContentLimitsExpired: any = () => {
  const { t } = useTranslation()
  return (
    <Button secondary className="button--no-text-transform" href="/settings/billing">
      {t("impressionsLimitExceededShort")}
    </Button>
  )
}

const HeaderContentExpired: any = () => {
  const { t } = useTranslation()
  return (
    <Button secondary className="button--no-text-transform" href="/settings/billing">
      {t("yourAccountHasExpired")}
    </Button>
  )
}

const HeaderContentCanceled: any = () => {
  const { t } = useTranslation()
  return (
    <Button secondary className="button--no-text-transform" href="/settings/billing">
      {t("yourSubscriptionIsCancelled")}
    </Button>
  )
}

const SettingsLayout: React.FC<LayoutProps> = ({
  activeSubSection, children, ...restProps
}) => {
  const SidebarContent: React.FC<any> = () => {
    const { t } = useTranslation()

    return (
      <>
        <div className="app-sidebar__secondary-title">
          <div className="app-sidebar__secondary-title-text">
            {t("navigationSettings")}
          </div>
        </div>
        <div className="app-sidebar__links">
          <NavLink
            activeSection={activeSubSection}
            icon={AccountIcon}
            section="account"
            to="/settings/account"
          >
            {t("navigationAccount")}
          </NavLink>
          <NavLink
            activeSection={activeSubSection}
            icon={BillingIcon}
            section="billing"
            to="/settings/billing"
          >
            {t("navigationBilling")}
          </NavLink>
        </div>
      </>
    )
  }

  const accountInfo = AppStorage.get("accountInfo", {}) as AccountInfo
  const isLimitsExceeded = accountInfo.impressionsLimitExceeded
  const isAccountExpired = accountInfo.expired
  const isAccountCanceled = accountInfo.canceled
  const headerContent = (
    isLimitsExceeded && <HeaderContentLimitsExpired /> ||
    isAccountExpired && <HeaderContentExpired /> ||
    isAccountCanceled && <HeaderContentCanceled />
  )
  return (
    <CustomerLayout
      {...restProps}
      activeSection="settings"
      headerContent={headerContent}
      sidebarContent={<SidebarContent />}
    >
      {children}
    </CustomerLayout>
  )
}

export default SettingsLayout
