import React from "react"
import { useTranslation } from "react-i18next"
import ColorField from "../../components/form-fields/ColorField"
import DateTimeField from "../../components/form-fields/DateTimeField"
import HintField from "../../components/form-fields/HintField"
import NumberField from "../../components/form-fields/NumberField"
import SelectField from "../../components/form-fields/SelectField"
import SwitchboxField from "../../components/form-fields/SwitchboxField"
import TextField from "../../components/form-fields/TextField"
import PhoneField from "../../components/form-fields/PhoneField"
import TextFieldArray from "../../components/form-fields/TextFieldArray"
import RadioToggle from "../../components/form-fields/RadioToggle"

const getFieldComponent = (fieldType: string): React.FC<any> => {
  switch (fieldType) {
    case "boolean":
      return SwitchboxField
    case "color":
      return ColorField
    case "datetime":
      return DateTimeField
    case "hint":
      return HintField
    case "text[]":
      return TextFieldArray
    case "radio":
      return RadioToggle
    case "number":
      return NumberField
    case "phone":
      return PhoneField
    case "select":
      return SelectField
    default:
      return TextField
  }
}

const Field: React.FC<any> = (props: any) => {
  const { t } = useTranslation()
  const { name, prefix = "", type, ...restProps } = props
  if (!type) return null
  const Component = getFieldComponent(type)
  if (Component === TextField) restProps.placeholder = t(name)
  return (
    <Component name={[prefix, name].join(".")} label={t(name)} {...restProps} />
  )
}

export default Field
