import React from "react"
import cn from "classnames"
import Box, { BoxProps } from "./Box"

interface MiniHeaderProps extends BoxProps {
  text?: string;
}

const MiniHeader: React.FC<MiniHeaderProps> = (props: MiniHeaderProps) => {
  return (
    <Box {...props} className={cn("mini-header", props.className)}>
      {props.text}
    </Box>
  )
}

export default MiniHeader
