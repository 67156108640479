import React from "react"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FontCustomization from "../shared/FontCustomization"
import FormGroup from "../../form-fields/FormGroup"

const HeadingCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <>
      <FormGroup visible={!!parameterTypes.headerText}>
        <Field name="headerText" prefix="parameters" type={parameterTypes.headerText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.headerTextHint}>
        <Field name="headerTextHint" prefix="parameters" type={parameterTypes.headerTextHint} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.headerFontSize}>
        <FontCustomization namePrefix="header" parameterTypes={parameterTypes} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.headerTextColor}>
        <Field initialValue="#000000" name="headerTextColor" prefix="theme" type={parameterTypes.headerTextColor} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.altHeaderTextColor}>
        <Field initialValue="#ffffff" name="altHeaderTextColor" prefix="theme" type={parameterTypes.altHeaderTextColor} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.overheadText}>
        <Field name="overheadText" prefix="parameters" type={parameterTypes.overheadText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.overheadFontSize}>
        <FontCustomization namePrefix="overhead" parameterTypes={parameterTypes} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.overheadTextColor}>
        <Field initialValue="#5869fa" name="overheadTextColor" prefix="theme" type={parameterTypes.overheadTextColor} />
      </FormGroup>
    </>
  )
}

export default HeadingCustomization
