import { useAlert } from "react-alert"
import { useTranslation } from "react-i18next"

const getPixelCode = (clientKey: string): string =>
`<script>
  var pliekConfig = {
    key: "${clientKey}"
  };
</script>
<script src="${window.location.origin}/client.js"></script>`

const copyPixelCode = (clientKey: string): void => {
  navigator.clipboard.writeText(getPixelCode(clientKey))
}

export const usePixelCodeCopier = (clientKey?: string): () => void => {
  const { t } = useTranslation()
  const alert = useAlert()

  return (): void => {
    if (!clientKey) return;
    copyPixelCode(clientKey)
    alert.show(t("pixelCodeCopied"))
  }
}
