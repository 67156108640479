import React from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"

interface StateLabelProps {
  active: boolean;
}

const StateLabel: React.FC<StateLabelProps> = (props: StateLabelProps) => {
  const { active } = props
  const { t } = useTranslation()

  return (
    <div className={cn("card__state", { "card__state--active": active })}>
      {t(active ? "stateActive" : "stateInactive")}
    </div>
  )
}

export default StateLabel
