import React from "react"
import cn from 'classnames'
import AccountCircle from '@material-ui/icons/AccountCircle'
import { useTranslation } from "react-i18next"
import AppStorage from "../../utils/storage"
import usersApi from "../../api/users"
import NavLink from "./NavLink"

import BellIcon from "../../images/icons/menu/BellIcon"
import DomainsIcon from "../../images/icons/menu/DomainsIcon"
import HelpIcon from "../../images/icons/menu/HelpIcon"
import LogoutIcon from "../../images/icons/menu/LogoutIcon"
import SettingsIcon from "../../images/icons/menu/SettingsIcon"

const MainSidebarLayout: React.FC<any> = ({ activeSection, viewMode }) => {
  const { t } = useTranslation()
  const accountInfo = AppStorage.get("accountInfo", { canceled: false, expired: false, name: "My Profile" })

  const mainSidebarClassName = cn("app-sidebar__main", {
    "app-sidebar__main-compact": ["COMPACT", "HALF"].includes(viewMode)
  })

  const handleLogout = (): void => {
    usersApi.signOut().then((ok: boolean) => {
      if (!ok) return;

      AppStorage.clear()
      window.location.href = "/login"
    })
  }

  return (
    <div className={mainSidebarClassName}>
      <div className="app-sidebar__main-app-name">
        {t("appName")}
      </div>
      <nav className="app-sidebar__links">
        {!accountInfo.expired && !accountInfo.canceled && (
          <NavLink activeSection={activeSection} icon={DomainsIcon} to="/sites" section="domains">
            {t("navigationDomains")}
          </NavLink>
        )}
        <NavLink activeSection={activeSection} icon={SettingsIcon} to="/settings/account" section="settings">
          {t("navigationSettings")}
        </NavLink>
        <NavLink external icon={BellIcon} to="https://pliek.feedy.app/changelog" target="_blank">
          {t("navigationWhatsNew")}
        </NavLink>
        <NavLink external icon={HelpIcon} to="https://pliek.io/documentation" target="_blank">
          {t("navigationHelpCenter")}
        </NavLink>
      </nav>
      <div className="app-sidebar__main-footer">
        <div className="app-sidebar__main-footer-content">
          <div className="app-sidebar__main-profile">
            <div className="app-sidebar__main-profile-picture">
              <AccountCircle />
            </div>
            <div className="app-sidebar__main-profile-name">
              {accountInfo.name}
            </div>
          </div>
          <NavLink icon={LogoutIcon} section="logout" onClick={handleLogout}>
            {t("logout")}
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default MainSidebarLayout
