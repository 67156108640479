import "react-dates/initialize"
import React from "react"
import ReactDOM from "react-dom"
import { Router } from "@reach/router"
import { positions, transitions, Provider as AlertProvider } from "react-alert"
import AlertTemplate from "../components/alerts/AlertTemplate"
import AccountEdit from "../pages/AccountEdit"
import Billing from "../pages/Billing"
import ChangePlan from "../pages/ChangePlan"
import ForgotPassword from "../pages/ForgotPassword"
import NewPassword from "../pages/NewPassword"
import SiteWidgetsNew from "../pages/SiteWidgetsNew"
import SignIn from "../pages/SignIn"
import SignUp from "../pages/SignUp"
import SiteIndex  from "../pages/SiteIndex"
import SiteEdit  from "../pages/SiteEdit"
import SiteNew  from "../pages/SiteNew"
import SiteMessages  from "../pages/SiteMessages"
import SiteStatistics  from "../pages/SiteStatistics"
import SiteWidgets  from "../pages/SiteWidgets"
import WidgetEdit  from "../pages/WidgetEdit"
import "../i18n"

const App: React.FC<{}> = () => {
  return (
    <Router>
      <AccountEdit path="/settings/account"/>
      <Billing path="/settings/billing"/>
      <ChangePlan path="/settings/plan"/>
      <ForgotPassword path="/forgot-password"/>
      <NewPassword path="/reset-password"/>
      <SignIn path="/login"/>
      <SignUp path="/register"/>
      <SignUp path="/account"/>
      <SignUp path="/ready"/>
      <SiteMessages path="/sites/:siteId/leads"/>
      <SiteStatistics path="/sites/:siteId/statistics"/>
      <SiteWidgetsNew path="/sites/:siteId/widgets/new"/>
      <SiteWidgets path="/sites/:siteId/widgets"/>
      <SiteEdit path="/sites/:siteId/edit"/>
      <SiteNew path="/sites/new"/>
      <SiteIndex path="/sites"/>
      <WidgetEdit path="/widgets/:widgetId/edit"/>
    </Router>
  )
}

const Root: React.FC<{}> = () => {
  const alertOptions = {
    position: positions.TOP_CENTER,
    timeout: 3000,
    transition: transitions.FADE
  }

  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <App/>
    </AlertProvider>
  )
}

document.addEventListener("DOMContentLoaded", () => {
  const root: HTMLElement | null = document.getElementById("react-root")
  if (!root) return
  ReactDOM.render(<Root/>, root)
})
