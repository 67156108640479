import { getI18n } from "react-i18next"
import { AlertManager } from "react-alert"
import AppStorage from "./storage"

export function processExceededLimits(alert: AlertManager): void {
  const info = AppStorage.get("accountInfo", {}) as AccountInfo
  const { canceled, expired, signedInAt } = info

  if (canceled || expired || !signedInAt) return;

  if (info.impressionsLimitExceeded) {
    const notifiedAt = localStorage["impressionsLimitExceededNotifiedAt"]
    if (notifiedAt && notifiedAt >= signedInAt) return;
    alert.show(getI18n().t("impressionsLimitExceeded"))
    localStorage["impressionsLimitExceededNotifiedAt"] = signedInAt
  } else if (info.impressionsThresholdLimitExceeded) {
    const notifiedAt = localStorage["impressionsThresholdLimitExceededNotifiedAt"]
    if (notifiedAt && notifiedAt >= signedInAt) return;
    alert.show(getI18n().t("impressions75LimitReached"))
    localStorage["impressionsThresholdLimitExceededNotifiedAt"] = signedInAt
  }
}
