import React from "react"
import { statefulIcon } from "../../../utils/icons"

const DomainsIcon: React.FC<{}> = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="13" cy="13" r="12" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M13 23.4352C8.60228 21.6551 6.50002 16.3076 6.50002 12.3075C6.50002 9.82629 7.30892 6.82657 8.97284 4.48143" stroke="#CDDFEC" strokeLinecap="round"/>
      <path d="M13 2.56475C17.3977 4.34491 19.5 9.69243 19.5 13.6925C19.5 16.1737 18.6911 19.1734 17.0272 21.5186" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="12.978" y1="2.54395" x2="12.978" y2="23.457" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="24.478" y1="13.5" x2="1.52151" y2="13.5" stroke="#CDDFEC"/>
    </svg>
  )
}

const DomainsActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#CDDFEC"/>
      <path d="M12 22.4348C7.60228 20.6546 5.50002 15.3071 5.50002 11.3071C5.50002 8.82583 6.30892 5.82611 7.97284 3.48098" stroke="white" strokeLinecap="round"/>
      <path d="M12 1.56522C16.3977 3.34539 18.5 8.69291 18.5 12.6929C18.5 15.1742 17.6911 18.1739 16.0272 20.519" stroke="white" strokeLinecap="round"/>
      <line x1="11.9783" y1="1.54349" x2="11.9783" y2="22.4565" stroke="white" strokeLinecap="round"/>
      <line x1="23.4783" y1="12.5" x2="0.521749" y2="12.5" stroke="white"/>
    </svg>
  )
}

export default statefulIcon(DomainsIcon, DomainsActiveIcon)
