import req from "./jsonRequest"

interface WidgetTemplateIndexResponse {
  templates: WidgetTemplate[];
}

interface WidgetTemplatesUsageResponse {
  usage: WidgetTemplatesUsage;
}

async function getAll(): Promise<WidgetTemplate[]> {
  return req.get<WidgetTemplateIndexResponse>("/widget_templates.json")
    .then(response => response.data.templates)
    .catch(() => [])
}

async function getUsage(): Promise<WidgetTemplatesUsage> {
  return req.get<WidgetTemplatesUsageResponse>("/templates_usage.json")
    .then(response => response.data.usage)
    .catch(() => ({ usedTemplateIds: [] }))
}

export default {
  getAll,
  getUsage,
}
