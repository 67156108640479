import React from "react"
import Paper from "../boxes/Paper"
import { toHumanDate } from "../../utils/date"
// import CheckboxField from "../form-fields/CheckboxField" // TODO

interface SiteMessageCardProps {
  siteMessage: CustomerSiteMessage;
}

const SiteMessageCard: React.FC<SiteMessageCardProps> = (props: SiteMessageCardProps) => {
  const { siteMessage } = props
  const { createdAt, email, emojiSlug, name, phoneNumber, text, widgetName } = siteMessage

  return (
    <Paper className="card horizontal-card site-message-card">
      {/*TODO: <div className="site-message-card__check">
        <CheckboxField />
      </div>*/}
      <div className="site-message-card__campaign">
        <div className="site-message-card__widget-name">{widgetName}</div>
        <div className="site-message-card__text">{text}</div>
      </div>
      <div className="site-message-card__emoji">
        {emojiSlug && <div className={`predefined-emoji predefined-emoji--${emojiSlug}`} />}
      </div>
      <div className="site-message-card__name">{name}</div>
      <div className="site-message-card__contacts">
        <div className="site-message-card__email">
          <a href={`mailto:${email}`}>{email}</a>
        </div>
        <div className="site-message-card__phone">{phoneNumber}</div>
      </div>
      <div className="site-message-card__date">{toHumanDate(createdAt)}</div>
    </Paper>
  )
}

export default SiteMessageCard
