import React from "react"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "../style-tabs"
import Field from "../../../utils/widget-builder/Field"

interface FontCustomizationProps extends CustomizationComponentProps {
  namePrefix: string;
}

const FontCustomization: React.FC<FontCustomizationProps> = (props: FontCustomizationProps) => {
  const { t } = useTranslation()
  const { namePrefix, parameterTypes } = props

  return (
    <div className="font-controls">
      <Field
        icon={/*TODO*/(): React.ReactElement => <span>B</span>}
        name={namePrefix ? `${namePrefix}FontWeight` : "fontWeight"}
        prefix="theme"
        toggledValue="bold"
        type={parameterTypes[namePrefix ? `${namePrefix}FontWeight` : "fontWeight"]}
        untoggledValue="normal"
      />
      <Field
        icon={/*TODO*/(): React.ReactElement => <i>I</i>}
        name={namePrefix ? `${namePrefix}FontStyle` : "fontStyle"}
        prefix="theme"
        toggledValue="italic"
        type={parameterTypes[namePrefix ? `${namePrefix}FontStyle` : "fontStyle"]}
        untoggledValue="normal"
      />
      <Field
        icon={/*TODO*/(): React.ReactElement => <u>U</u>}
        name={namePrefix ? `${namePrefix}TextDecoration` : "textDecoration"}
        prefix="theme"
        toggledValue="underline"
        type={parameterTypes[namePrefix ? `${namePrefix}TextDecoration` : "textDecoration"]}
        untoggledValue="none"
      />
      <Field
        label={t("size")}
        unit="px"
        name={namePrefix ? `${namePrefix}FontSize` : "fontSize"}
        prefix="theme"
        type={parameterTypes[namePrefix ? `${namePrefix}FontSize` : "fontSize"]}
      />
    </div>
  )
}

export default FontCustomization
