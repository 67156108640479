import React from "react"
import cn from "classnames"
import { Field, FieldRenderProps } from "react-final-form"
import RadioField from "./RadioField"

interface RadioGroupProps {
  className?: string;
  columns?: number;
  initialValue?: any;
  name: string;
  options: string[];
  renderOptionProps: (option: any, input?: any) => object;
}

const RadioGroup: React.FC<RadioGroupProps> = (props: RadioGroupProps) => {
  const { className, columns, options, renderOptionProps, ...otherProps } = props

  const renderField = (props: FieldRenderProps<string>): React.ReactNode => {
    const { input } = props
    const style = columns ? { gridTemplateColumns: `repeat(${columns}, 1fr)` } : {}

    return (
      <div
        className={cn("radio-group", className)}
        style={style}
      >
        {options.map(option => (
          <RadioField
            {...renderOptionProps(option, input)}
            key={option}
            name={input.name}
            value={option}
          />
        ))}
      </div>
    )
  }

  return (
    <Field {...otherProps} render={renderField} type="text"/>
  )
}
export default RadioGroup
