import React, { ReactNode, useEffect, useState } from "react"
import { Field, Form, FormRenderProps } from "react-final-form"
import { useTranslation } from "react-i18next"
import { toHumanDate } from "../../utils/date"
import siteApi from "../../api/sites"

import Button from "../buttons/Button"
import ControlGroup from "../boxes/ControlGroup"
import LinkButton from "../buttons/LinkButton"
import Paper from "../boxes/Paper"
import StaticField from "../form-fields/StaticField"
import FormGroup from "../form-fields/FormGroup"
import StateLabel from "../labels/StateLabel"
import TextField from "../form-fields/TextField"

interface SiteFormProps {
  onSubmit: (attrs: CustomerSite) => Promise<void>;
  site: CustomerSite | null;
}

const SiteForm: React.FC<SiteFormProps> = (props: SiteFormProps) => {
  const { onSubmit, site } = props
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(site?.state === "active")

  useEffect((): void => {
    setIsActive(site?.state === "active")
  }, [site])

  if (!site) return null

  const toggleState = (): void => {
    const updatedSite = { ...site }
    updatedSite.state = isActive ? "disabled" : "active"
    setIsActive(!isActive)
    siteApi.save(updatedSite).then((site: CustomerSite) => {
      setIsActive(site.state === "active")
    })
  }

  const { createdAt, widgets } = site
  return (
    <Form
      initialValues={site}
      onSubmit={onSubmit}
      render={(renderProps: FormRenderProps<CustomerSite>): ReactNode => {
        const { handleSubmit } = renderProps
        return (
          <form onSubmit={handleSubmit}>
            <Field name="base">
              {(props: any): any => {
                const { meta } = props
                const error = meta.submitError && meta.submitError[0]
                if (!error && site.isAllowedByBillingPlan) return null
                return (
                  <div className="sign-up-form__base-error">{error || t("sitesLimitExceededError")}</div>
                )
              }}
            </Field>
            <Paper>
              <ControlGroup maxWidth="480px">
                <FormGroup>
                  <TextField type="text" name="hostname" label={t("domain")} />
                  <TextField type="text" name="name" label={t("name")} />
                </FormGroup>
                {site.id && <>
                  <FormGroup>
                    <StaticField label={t("status")}>
                      <div style={{ flexGrow: 1 }}><StateLabel active={isActive} /></div>
                      <LinkButton onClick={toggleState} type="button">
                        {t(isActive ? "deactivate" : "activate")}
                      </LinkButton>
                    </StaticField>
                  </FormGroup>
                  {/* TODO: <FormGroup>
                    <StaticField label={t("pixelCode")}>
                      TODO
                      </StaticField>
                  </FormGroup>*/}
                  <FormGroup>
                    <StaticField label={t("campaigns")}>
                      {widgets?.length || 0}
                    </StaticField>
                  </FormGroup>
                  <FormGroup>
                    <StaticField label={t("created")}>
                      {toHumanDate(createdAt)}
                    </StaticField>
                  </FormGroup>
                </>}
              </ControlGroup>
            </Paper>
            <ControlGroup gap="30px">
              <Button type="submit">
                {t(site.id ? "saveChanges" : "save")}
              </Button>
            </ControlGroup>
          </form>
        )
      }}
    />
  )
}

export default SiteForm
