import React from "react"

const Check: React.FC<{}> = () => {
  return (
    <svg viewBox="0 0 9 8">
      <path d="M1 4.5L3.5 7.5L8 1" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default Check
