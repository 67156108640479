import React from "react"
import cn from "classnames"
import { Link as RouterLink } from "@reach/router"
import RegularButton from "./RegularButton"

interface LinkButtonProps {
  children?: React.ReactNode;
  className?: string;
  href?: string | null;
  onClick?: any;
  type?: string;
}

const LinkButton: React.FC<LinkButtonProps> = (props: LinkButtonProps) => {
  const { children, className, href, onClick, type } = props

  const Component: React.FC<any> = !href ? RegularButton : RouterLink

  const buttonClassName = cn("button-link", className)
  return (
    <Component className={buttonClassName} onClick={onClick} to={href} type={type}>
      {children && <div className="button__text">{children}</div>}
    </Component>
  )
}

export default LinkButton
