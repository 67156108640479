import React from "react"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FontCustomization from "../shared/FontCustomization"
import FormGroup from "../../form-fields/FormGroup"

const ButtonCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup visible={!!parameterTypes.buttonText}>
        <Field name="buttonText" prefix="parameters" type={parameterTypes.buttonText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.linkText}>
        <Field name="linkText" prefix="parameters" type={parameterTypes.linkText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.linkUrl}>
        <Field name="linkUrl" prefix="parameters" type={parameterTypes.linkUrl} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.openLinkInNewTab}>
        <Field name="openLinkInNewTab" prefix="parameters" type={parameterTypes.openLinkInNewTab} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.buttonFontSize}>
        <FontCustomization namePrefix="button" parameterTypes={parameterTypes} />
      </FormGroup>
      <FormGroup title={t("text")} visible={!!parameterTypes.buttonTextColor}>
        <Field initialValue="#ffffff" name="buttonTextColor" prefix="theme" type={parameterTypes.buttonTextColor} />
      </FormGroup>
      <FormGroup title={t("button")} visible={!!parameterTypes.buttonBackgroundColor}>
        <Field initialValue="#5869fa" name="buttonBackgroundColor" prefix="theme" type={parameterTypes.buttonBackgroundColor} />
      </FormGroup>
    </>
  )
}

export default ButtonCustomization
