import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useAlert } from "react-alert"
import moment from "moment"
import Box from "../boxes/Box"
import Button from "../buttons/Button"
import MiniHeader from "../boxes/MiniHeader"
import Paper from "../boxes/Paper"
import LinkButton from "../buttons/LinkButton"

interface BillingProps {
  onCreatePayment(): void;
  onCancelSubscription(): Promise<BillingInfo>;
  undoSubscriptionCancel(): Promise<BillingInfo>;
  info: BillingInfo;
}

const Billing: React.FC<BillingProps> = (props: BillingProps) => {
  const {
    info: {
      changePlanOncePaid, invoices, nextBillingState, nextPlanName, payable,
      paymentDetails, periodEndsAt, planName, state, planCanBeProlonged, isLifetime,
    },
    onCancelSubscription, onCreatePayment, undoSubscriptionCancel,
  } = props
  const { t } = useTranslation()
  const [isCancelSubscriptionInitiated, setIsCancelSubscriptionInitiated] = useState(false)
  const alert = useAlert()

  const cancelSubscription = (): void => {
    onCancelSubscription().then((billing: BillingInfo) => {
      const { nextBillingState, state } = billing
      setIsCancelSubscriptionInitiated(false)
      if (nextBillingState === "canceled") {
        alert.show(t("subscriptionWillBeCanceled"))
      } else if (state === "canceled") {
        alert.show(t("subscriptionCanceled"))
      }
    })
  }

  return (
    <Paper>
      {payable &&
        <>
          <MiniHeader text={t("BillingMethod")}/>
          <Box className="billing-box">
            {paymentDetails &&
              <div>
                <div className="billing-box__larger">**** **** **** **** {paymentDetails.cardNumber}</div>
                <div>{paymentDetails.cardHolder}</div>
              </div>
            }
            <LinkButton onClick={onCreatePayment}>{t("ChangeCard")}</LinkButton>
          </Box>
        </>
      }
      <Box className="billing-box">
        <div className="billing-box__item">
          <MiniHeader text={t(state === "canceled" ? "YourLastPlan" : "YourPlan")}/>
          <div className="billing-box__larger">{planName}</div>
          {!isLifetime && state !== "canceled" && nextBillingState !== "canceled" && <>
            {isCancelSubscriptionInitiated ? (
              <LinkButton
                className="button-link--danger"
                onClick={cancelSubscription}
              >
                {t("CancelSubscriptionConfirmation")}
              </LinkButton>
            ) : (
              <LinkButton
                className="button-link--danger"
                onClick={(): void => setIsCancelSubscriptionInitiated(true)}
              >
                {t("CancelSubscription")}
              </LinkButton>
            )}
          </>}
          {!isLifetime && nextBillingState === "canceled" && (
            <>
              <MiniHeader text={t("subscriptionWillBeCanceledAt", { date: moment(periodEndsAt).format("ll") })}/>
              <LinkButton onClick={undoSubscriptionCancel}>{t("undo")}</LinkButton>
            </>
          ) || !planCanBeProlonged && (
            <>
              {!isLifetime && <MiniHeader text={t("subscriptionWillBeCanceledAt", { date: moment(periodEndsAt).format("ll") })}/>}
              <MiniHeader text={t("subscriptionWillNotBeProlonged")}/>
            </>
          )}
          {state === "canceled" && (
            <MiniHeader text={t("yourSubscriptionIsCancelled")}/>
          )}
        </div>
        <div className="billing-box__item">
          <MiniHeader text={t(state === "canceled" ? "SignUpStep1" : "ChangePlan")}/>
          <Button engaging href="/settings/plan">{t(state === "canceled" ? "Choose" : "Change")}</Button>
        </div>
      </Box>
      {nextPlanName && <Box className="billing-box">
        <div className="billing-box__item">
          <MiniHeader text={t("NextPlan")}/>
          <div className="billing-box__larger">{nextPlanName}</div>
        </div>
        <div className="billing-box__item">
          <MiniHeader text={t("WillBeApplied")}/>
          <div>{changePlanOncePaid ? t("Now") : moment(periodEndsAt).format("ll")}</div>
        </div>
      </Box>}
      {(payable || invoices.length) && <>
        <MiniHeader text={t("Invoices")}/>
        <div className="billing-invoices">
          {invoices.map((invoice: BillingInvoice): any => (
            <div className="billing-invoice" key={invoice.id}>
              <div className="billing-invoice__period">
                {moment(invoice.createdAt).format('ll')}
              </div>
              <div className="billing-invoice__amount">{invoice.amount}</div>
              <div className="billing-invoice__state">{invoice.state}</div>
            </div>
          ))}
        </div>
      </>}
    </Paper>
  )
}

export default Billing
