import React from "react"

const MoreIcon: React.FC<{}> = () => {
  return (
    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="12.4668" cy="16.9995" rx="1.13333" ry="1.13333" fill="#BAD0E0"/>
      <circle cx="17" cy="16.9995" r="1.13333" fill="#BAD0E0"/>
      <ellipse cx="21.5337" cy="16.9995" rx="1.13333" ry="1.13333" fill="#BAD0E0"/>
    </svg>
  )
}

export default MoreIcon
