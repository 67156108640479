import React from "react"
import Button from "../buttons/Button"
import PlusIcon from "../../images/icons/other/PlusIcon"

interface EmptyCollectionProps {
  actionText?: string;
  description: string;
  onAction?: any;
  title: string;
}

const EmptyCollection: React.FC<EmptyCollectionProps> = ({ actionText, description, onAction, title }) => {
  return (
    <div className="blank-content-fallback">
      <div className="blank-content-fallback__title">{title}</div>
      <div className="blank-content-fallback__description">{description}</div>
      {onAction && <Button icon={PlusIcon} onClick={onAction}>{actionText}</Button>}
    </div>
  )
}

export default EmptyCollection
