import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const EmojiIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke={primaryColor} strokeWidth="2"/>
      <circle cx="15" cy="15" r="1" fill={primaryColor}/>
      <circle cx="25" cy="15" r="1" fill={primaryColor}/>
      <path d="M29.2211 20C27.2584 23.0102 23.8616 25 20 25C16.1384 25 12.7416 23.0102 10.7789 20" stroke={primaryColor} strokeLinecap="round"/>
    </svg>
  )
}

export default EmojiIcon
