import React from "react"
import cn from "classnames"
import RadioGroup from "./RadioGroup"
import { StatefulIconProps } from "../../utils/icons"

interface RadioToggleProps {
  icon?: React.FC<StatefulIconProps>;
  name: string;
  toggledValue: string;
  untoggledValue: string;
}

const RadioToggle: React.FC<RadioToggleProps> = (props: RadioToggleProps) => {
  const { icon, toggledValue, untoggledValue, ...restProps } = props

  const renderOptionProps = (value: string, input: any): object => ({
    className: cn("button button--rounded button--secondary", {
      "button--no-background": input.value != toggledValue,
      "button--without-text": icon,
      "hidden": input.value === value || input.value != toggledValue && value === untoggledValue
    }),
    icon
  })

  return (
    <RadioGroup
      {...restProps}
      className="radio-toggle"
      options={[toggledValue, untoggledValue]}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default RadioToggle
