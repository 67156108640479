import React from "react"
import { statefulIcon } from "../../../utils/icons"

const HelpIcon: React.FC<{}> = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1C6.38293 1 1 6.38293 1 13C1 19.6171 6.38293 25 13 25C19.6171 25 25 19.6171 25 13C25 6.38293 19.6171 1 13 1Z" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M13.9335 17.8C13.9335 18.2418 13.5753 18.6 13.1335 18.6C12.6917 18.6 12.3335 18.2418 12.3335 17.8C12.3335 17.3582 12.6917 17 13.1335 17C13.5753 17 13.9335 17.3582 13.9335 17.8Z" fill="#CDDFEC"/>
      <path d="M13.977 12.3453C13.7262 12.4587 13.5639 12.7071 13.5639 12.9778V13.9857C13.5639 14.3696 13.2472 14.6813 12.8554 14.6813C12.4636 14.6813 12.1469 14.3696 12.1469 13.9857V12.9778C12.1469 12.1659 12.6329 11.4216 13.3833 11.0814C14.1053 10.7552 14.6267 9.88899 14.6267 9.46393C14.6267 8.50541 13.8325 7.72484 12.8554 7.72484C11.8784 7.72484 11.0841 8.50541 11.0841 9.46393C11.0841 9.84797 10.7673 10.1597 10.3755 10.1597C9.98368 10.1597 9.66699 9.84797 9.66699 9.46393C9.66699 7.73809 11.0975 6.3335 12.8554 6.3335C14.6134 6.3335 16.0438 7.73809 16.0438 9.46393C16.0438 10.4039 15.2134 11.7854 13.977 12.3453Z" fill="#CDDFEC"/>
    </svg>
  )
}

const HelpActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 1C6.15865 1 1 6.15865 1 12.5C1 18.8414 6.15865 24 12.5 24C18.8414 24 24 18.8414 24 12.5C24 6.15865 18.8414 1 12.5 1Z" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M13.3947 17.1002C13.3947 17.5236 13.0514 17.8668 12.628 17.8668C12.2046 17.8668 11.8613 17.5236 11.8613 17.1002C11.8613 16.6767 12.2046 16.3335 12.628 16.3335C13.0514 16.3335 13.3947 16.6767 13.3947 17.1002Z" fill="white"/>
      <path d="M13.4361 11.8727C13.1957 11.9813 13.0402 12.2194 13.0402 12.4788V13.4447C13.0402 13.8126 12.7367 14.1113 12.3612 14.1113C11.9858 14.1113 11.6823 13.8126 11.6823 13.4447V12.4788C11.6823 11.7007 12.148 10.9874 12.8671 10.6614C13.559 10.3488 14.0587 9.51868 14.0587 9.11133C14.0587 8.19275 13.2976 7.4447 12.3612 7.4447C11.4249 7.4447 10.6637 8.19275 10.6637 9.11133C10.6637 9.47937 10.3601 9.77808 9.98464 9.77808C9.60916 9.77808 9.30566 9.47937 9.30566 9.11133C9.30566 7.4574 10.6765 6.11133 12.3612 6.11133C14.0459 6.11133 15.4168 7.4574 15.4168 9.11133C15.4168 10.0121 14.621 11.3361 13.4361 11.8727Z" fill="white"/>
    </svg>
  )
}

export default statefulIcon(HelpIcon, HelpActiveIcon)
