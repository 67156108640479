import React from "react"
import cn from "classnames"
import Box, { BoxProps } from "./Box"

const Paper: React.FC<BoxProps> = (props: BoxProps) => {
  return (
    <Box {...props} className={cn("paper", props.className)}/>
  )
}

export default Paper
