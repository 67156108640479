import React from "react"
import { FieldArray } from "react-final-form-arrays"
import Button from "../buttons/Button"
import BluePlusIcon from "../../images/icons/other/BluePlusIcon"
import FormGroup from "./FormGroup"
import RemoveIcon from "../../images/icons/other/RemoveIcon"
import TextField from "./TextField"

interface ArrayFormFieldProps {
  label: string;
  name: string;
  type: "number" | "text" | "email" | "password";
}

const identity = (value: any): any => value

const numberParse = (value: any): number => Number(value)

const ArrayFormField: React.FC<ArrayFormFieldProps> = (props: ArrayFormFieldProps) => {
  return (
    <FieldArray
      name={props.name}
      parse={props.type === "number" ? numberParse : identity}
      type="text"
    >
      {({ fields }): React.ReactNode => (
        <>
          {fields.map((name, index) => (
            <FormGroup key={index}>
              <div className="array-form-field__item">
                <TextField key={name} name={name} placeholder={props.label} type={props.type} />
                <Button
                  rounded
                  secondary
                  icon={index > 0 ? RemoveIcon : BluePlusIcon}
                  onClick={(): void => index > 0 ? fields.remove(index) : fields.push("")}
                  type="button"
                />
              </div>
            </FormGroup>
          ))}
        </>
      )}
    </FieldArray>
  )
}

export default ArrayFormField
