import React from "react"
import TextField from "./TextField"

interface NumberFieldProps {
  label?: string;
  unit?: string;
  name: string;
}

const NumberField: React.FC<NumberFieldProps> = (props: NumberFieldProps) => {
  const { unit, ...restProps } = props

  if (unit) return (
    <TextField
      {...restProps}
      format={(value: any): string => parseFloat(value).toString()}
      parse={(value: any): string => [parseFloat(value || "0"), unit].join('')}
      type="number"
    />
  )

  return (
    <TextField {...restProps} type="number" />
  )
}

export default NumberField
