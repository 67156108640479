import React from "react"
import { navigate } from "@reach/router"
import { Field, Form, FormRenderProps } from "react-final-form"
import { Link as RouterLink, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../components/buttons/Button"
import ControlGroup from "../components/boxes/ControlGroup"
import Header from "../components/boxes/Header"
import Paper from "../components/boxes/Paper"
import TextField from "../components/form-fields/TextField"
import illustration from "../images/sign-up.svg"
import usersApi from "../api/users"

const initialValues = (): UserNewPasswordAttrs => {
  const params = (new URL(document.location.href)).searchParams
  return {
    password: "",
    passwordConfirmation: "",
    resetPasswordToken: params?.get("reset_password_token") || "",
  }
}

const ForgotPassword: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()

  const handleFormSubmit = async (attrs: UserNewPasswordAttrs): Promise<any> => {
    return usersApi.setNewPassword(attrs)
      .then(() => navigate("/sites", { replace: true }))
      .catch((errors: any) => errors)
  }

  return (
    <div className="sign-up-container">
      <img className="sign-up-container__illustration" src={illustration}/>
      <Form
        initialValues={initialValues()}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }: FormRenderProps<UserNewPasswordAttrs>): React.ReactNode => {
          return (
            <div className="sign-up-form">
              <form onSubmit={handleSubmit}>
                <div className="sign-up-user-fields">
                  <Header variant="main" text={t("changeYourPassword")}/>
                  <Field name="resetPasswordToken">
                    {(props: any): any => {
                      const { input, meta } = props
                      const error = meta.submitError && meta.submitError[0]
                      if (!error) return null
                      return (
                        <div className="sign-up-form__base-error">{[t(input.name), error].join(" ")}</div>
                      )
                    }}
                  </Field>
                  <Paper gap="30px">
                    <ControlGroup maxWidth="480px">
                      <TextField type="password" name="password" label={t("newPassword")} />
                      <TextField type="password" name="passwordConfirmation" label={t("newPasswordConfirmation")} />
                      <RouterLink className="link" to="/login">
                        {t("SignIn")}
                      </RouterLink>
                    </ControlGroup>
                  </Paper>
                  <ControlGroup gap="30px">
                    <Button type="submit">
                      {t("changeMyPassword")}
                    </Button>
                  </ControlGroup>
                </div>
              </form>
            </div>
          )
        }}
      />
    </div>
  )
}

export default ForgotPassword
