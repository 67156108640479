/* eslint-disable react/display-name */

import React from "react"

import { ReactComponent as CartIcon } from "../../assets/images/icons/predefined/cart.svg"
import { ReactComponent as ChartsIcon } from "../../assets/images/icons/predefined/charts.svg"
import { ReactComponent as CheckedIcon } from "../../assets/images/icons/predefined/checked.svg"
import { ReactComponent as CouponIcon } from "../../assets/images/icons/predefined/coupon.svg"
import { ReactComponent as HeartIcon } from "../../assets/images/icons/predefined/heart.svg"
import { ReactComponent as LocationIcon } from "../../assets/images/icons/predefined/location.svg"
import { ReactComponent as MailIcon } from "../../assets/images/icons/predefined/mail.svg"
import { ReactComponent as PercentIcon } from "../../assets/images/icons/predefined/percent.svg"
import { ReactComponent as PersonIcon } from "../../assets/images/icons/predefined/person.svg"
import { ReactComponent as PhoneIcon } from "../../assets/images/icons/predefined/phone.svg"
import { ReactComponent as PlaneIcon } from "../../assets/images/icons/predefined/plane.svg"
import { ReactComponent as SearchIcon } from "../../assets/images/icons/predefined/search.svg"

const iconsMapping: any = {
  cart: CartIcon,
  charts: ChartsIcon,
  checked: CheckedIcon,
  coupon: CouponIcon,
  heart: HeartIcon,
  location: LocationIcon,
  mail: MailIcon,
  percent: PercentIcon,
  person: PersonIcon,
  phone: PhoneIcon,
  plane: PlaneIcon,
  search: SearchIcon,
}

export const getPredefinedIcon = (iconName: string): () => React.ReactNode => {
  const Icon = iconsMapping[iconName]

  if (!Icon) return (): React.ReactNode => null

  return (): React.ReactNode => (
    <Icon className="predefined-icon" />
  )
}
