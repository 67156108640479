import React from "react"
import CheckboxField, { CheckboxFieldProps } from "./CheckboxField"

const SwitchboxField: React.FC<CheckboxFieldProps> = (props: CheckboxFieldProps) => {
  return (
    <CheckboxField {...props} kind="switch" />
  )
}

export default SwitchboxField
