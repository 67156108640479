import React from "react"

const CodeIcon: React.FC<{}> = () => {
  return (
    <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 4L1.70711 7.29289C1.31658 7.68342 1.31658 8.31658 1.70711 8.70711L5 12" stroke="#A9C0D1" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 4L17.2929 7.29289C17.6834 7.68342 17.6834 8.31658 17.2929 8.70711L14 12" stroke="#A9C0D1" strokeLinecap="round" strokeLinejoin="round"/>
      <line x1="12.2892" y1="0.645242" x2="6.94932" y2="14.6626" stroke="#A9C0D1" strokeLinecap="round"/>
    </svg>
  )
}

export default CodeIcon
