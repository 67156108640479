import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const MainTextIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="13.244" y="6.6001" width="17" height="1" rx="0.5" fill={primaryColor}/>
      <rect x="13.244" y="12.6001" width="17" height="1" rx="0.5" fill={primaryColor}/>
      <rect x="13.244" y="18.6001" width="17" height="1" rx="0.5" fill={primaryColor}/>
      <path d="M17.244 1.1001C17.244 0.823955 17.4679 0.600098 17.744 0.600098H29.744C30.0202 0.600098 30.244 0.823955 30.244 1.1001C30.244 1.37624 30.0202 1.6001 29.744 1.6001H17.744C17.4679 1.6001 17.244 1.37624 17.244 1.1001Z" fill={primaryColor}/>
      <path d="M6.776 19.6C6.58933 19.6 6.44 19.544 6.328 19.432C6.216 19.32 6.16 19.1707 6.16 18.984V1.68H0.616C0.429333 1.68 0.28 1.624 0.168 1.512C0.056 1.4 0 1.25067 0 1.064V0.644C0 0.457334 0.056 0.308001 0.168 0.196001C0.28 0.0653338 0.429333 0 0.616 0H13.44C13.6453 0 13.804 0.0560004 13.916 0.168001C14.028 0.280001 14.084 0.438667 14.084 0.644V1.064C14.084 1.25067 14.0187 1.4 13.888 1.512C13.776 1.624 13.6267 1.68 13.44 1.68H7.924V18.984C7.924 19.1707 7.85867 19.32 7.728 19.432C7.616 19.544 7.46667 19.6 7.28 19.6H6.776Z" fill={primaryColor}/>
    </svg>
  )
}

export default MainTextIcon
