import React from "react"
import { statefulIcon } from "../../../utils/icons"

const StatisticsIcon: React.FC<{}> = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 1.54248C4.36017 3.02324 1 7.3693 1 12.5002C1 18.8515 6.14873 24.0002 12.5 24.0002C17.6309 24.0002 21.9769 20.64 23.4577 16.0002" stroke="#CDDFEC" strokeWidth="2" strokeLinecap="round"/>
      <mask id="path-2-outside-1" maskUnits="userSpaceOnUse" x="12" y="0.0107422" width="13" height="14" fill="black">
        <rect fill="white" x="12" y="0.0107422" width="13" height="14"/>
        <path d="M24 12.4999H13L13 1.01074C19.1194 1.27251 24 6.31618 24 12.4999Z"/>
      </mask>
      <path d="M24 12.4999V13.4999C24.5523 13.4999 25 13.0522 25 12.4999H24ZM13 12.4999L12 12.4999C12 12.7651 12.1054 13.0195 12.2929 13.207C12.4804 13.3946 12.7348 13.4999 13 13.4999V12.4999ZM13 1.01074L13.0428 0.0116559C12.7704 4.17233e-06 12.5051 0.100068 12.3082 0.288682C12.1114 0.477296 12 0.738108 12 1.01074L13 1.01074ZM24 11.4999H13V13.4999H24V11.4999ZM14 12.4999L14 1.01075L12 1.01074L12 12.4999L14 12.4999ZM12.9573 2.00983C18.5438 2.2488 23 6.85416 23 12.4999H25C25 5.7782 19.695 0.296217 13.0428 0.0116559L12.9573 2.00983Z" fill="#CDDFEC" mask="url(#path-2-outside-1)"/>
    </svg>
  )
}

const StatisticsActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.0385 13.9808C24.0385 20.6188 18.6573 26 12.0192 26C5.38119 26 0 20.6188 0 13.9808C0 7.34273 5.38119 1.96153 12.0192 1.96153C12.0192 4.84615 12.0192 13.9808 12.0192 13.9808C12.0192 13.9808 20.6731 13.9808 24.0385 13.9808Z" fill="#CDDFEC"/>
      <mask id="path-2-outside-1" maskUnits="userSpaceOnUse" x="11.5" y="0" width="15" height="15" fill="black">
        <rect fill="white" x="11.5" width="15" height="15"/>
        <path d="M25 13.5H12.5L12.5 1C19.4538 1.2848 25 6.77221 25 13.5Z"/>
      </mask>
      <path d="M25 13.5H12.5L12.5 1C19.4538 1.2848 25 6.77221 25 13.5Z" fill="#CDDFEC"/>
      <path d="M25 13.5V14.5C25.5523 14.5 26 14.0523 26 13.5H25ZM12.5 13.5L11.5 13.5C11.5 13.7652 11.6054 14.0196 11.7929 14.2071C11.9804 14.3946 12.2348 14.5 12.5 14.5V13.5ZM12.5 1L12.541 0.000837624C12.2689 -0.0103059 12.004 0.0899944 11.8076 0.278569C11.6111 0.467144 11.5 0.727681 11.5 0.999996L12.5 1ZM25 12.5H12.5V14.5H25V12.5ZM13.5 13.5L13.5 1L11.5 0.999996L11.5 13.5L13.5 13.5ZM12.4591 1.99916C18.9182 2.2637 24 7.34894 24 13.5H26C26 6.19547 19.9894 0.305894 12.541 0.000837624L12.4591 1.99916Z" fill="white" mask="url(#path-2-outside-1)"/>
    </svg>
  )
}

export default statefulIcon(StatisticsIcon, StatisticsActiveIcon)
