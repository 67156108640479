import React, { useRef, useState } from "react"
import { AreaChart, Area, CartesianGrid, XAxis, YAxis } from "recharts"
import moment from "moment"
import useInterval from "@use-it/interval"
import { useTranslation } from "react-i18next"

interface StatisticsVisitorsProps {
  visitorsByDates: SiteVisitorsByDate[];
}

const YAxisTick = (props: any): React.ReactNode => {
  const { y, payload: { value } } = props
  return (
    <text x={0} y={y} textAnchor="start">
      {value.toLocaleString("en-US")}
    </text>
  )
}

const withTransfomedDates = (data: SiteVisitorsByDate[]): SiteVisitorsByDate[] => {
  return data.map(item => ({ ...item, date: moment(item.date).format("MMM D")}))
}

const StatisticsVisitors: React.FC<StatisticsVisitorsProps> = (props: StatisticsVisitorsProps) => {
  const { visitorsByDates } = props
  const { t } = useTranslation()
  const visitorsContainer = useRef<HTMLDivElement | null>(null)
  const [visitorsContainerWidth, setVisitorsContainerWidth] = useState(0)

  useInterval(() => {
    const newContainerWidth = visitorsContainer.current?.clientWidth
    if (newContainerWidth && newContainerWidth !== visitorsContainerWidth) {
      setVisitorsContainerWidth(newContainerWidth)
    }
  }, 100)

  return (
    <div className="statistics__visitors" ref={visitorsContainer}>
      <div className="statistics__title">{t("visitors")}</div>
      <div className="statistics-visitors__chart">
        <AreaChart width={visitorsContainerWidth} height={250} data={withTransfomedDates(visitorsByDates)}>
          <defs>
            <linearGradient id="statistics-visitors-color" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="rgb(88, 105, 250)" stopOpacity={0.1}/>
              <stop offset="95%" stopColor="rgb(88, 105, 250)" stopOpacity={0.05}/>
            </linearGradient>
          </defs>
          <XAxis dataKey="date" />
          <YAxis tick={YAxisTick} width={50} />
          <CartesianGrid stroke="#93b1c7" />
          <Area type="monotone" dataKey="visitors" stroke="#5869fa" fillOpacity={1} fill="url(#statistics-visitors-color)" />
        </AreaChart>
      </div>
    </div>
  )
}

export default StatisticsVisitors
