import React from "react"
import { useTranslation } from "react-i18next"

interface StatisticsTopPagesProps {
  topPages: SiteVisitorsByPage[];
}

const StatisticsTopPages: React.FC<StatisticsTopPagesProps> = (props: StatisticsTopPagesProps) => {
  const { topPages } = props
  const { t } = useTranslation()

  return (
    <div className="card paper statistics-card statistics__top-pages">
      <div className="statistics__title">{t("topPages")}</div>
      {topPages.map(page => (
        <div className="statistics-top-page" key={page.path}>
          <div className="statistics-top-page__path">{page.path}</div>
          <div className="statistics-top-page__visitors">{page.visitors.toLocaleString("en-US")}</div>
        </div>
      ))}
    </div>
  )
}

export default StatisticsTopPages
