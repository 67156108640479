import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const VideoIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke={primaryColor} strokeWidth="2"/>
      <path d="M26.4347 20.8779C27.1296 20.4989 27.1296 19.5011 26.4347 19.1221L16.9789 13.9644C16.3125 13.6009 15.5 14.0832 15.5 14.8423L15.5 25.1577C15.5 25.9168 16.3125 26.3991 16.9789 26.0356L26.4347 20.8779Z" stroke={primaryColor}/>
    </svg>
  )
}

export default VideoIcon
