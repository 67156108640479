import React, { ReactNode } from "react"
import { Form, FormRenderProps } from "react-final-form"
import SwitchboxField from "../form-fields/SwitchboxField"

interface StateSwitcherProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

interface CheckboxFieldProps {
  checked: boolean;
}

const WidgetForm: React.FC<StateSwitcherProps> = (props: StateSwitcherProps) => {
  const { value, onChange } = props

  return (
    <Form
      initialValues={{ checked: value }}
      onSubmit={({ checked }): void => onChange(checked)}
      render={(renderProps: FormRenderProps<CheckboxFieldProps>): ReactNode => {
        const { handleSubmit } = renderProps
        return (
          <form>
            <SwitchboxField name="checked" onChange={handleSubmit} />
          </form>
        )
      }}
    />
  )
}

export default WidgetForm
