import React from "react"

export interface StatefulIconProps {
  active?: boolean;
}

interface StatefulIconStyles {
  backgroundColor: string;
  fillOpacity: number;
  primaryColor: string;
  secondaryColor: string;
}

export const useStyles = (props: StatefulIconProps): StatefulIconStyles => {
  const { active } = props

  return {
    backgroundColor: active ? "#5869FA" : "white",
    fillOpacity: active ? 0.2 : 1,
    primaryColor: active ? "white" : "#93B1C7",
    secondaryColor: active ? "white" : "#E3ECF2",
  }
}

interface IconProps extends StatefulIconProps {
  activeIcon?: React.FC<any>;
  defaultIcon: React.FC<any>;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { active, activeIcon, defaultIcon } = props

  const Component: React.FC<any> = active && activeIcon ? activeIcon : defaultIcon

  return <Component />
}

export function statefulIcon(defaultIcon: React.FC<any>, activeIcon: React.FC<any>): React.FC<StatefulIconProps> {
  const Component: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
    const { active } = props
    return (
      <Icon active={active} activeIcon={activeIcon} defaultIcon={defaultIcon} />
    )
  }
  return Component
}
