import React, { useState } from "react"
import { navigate } from "@reach/router"
import arrayMutators from "final-form-arrays"
import { Form, FormRenderProps } from "react-final-form"
import { useTranslation } from "react-i18next"
import UserFields from "./UserFields"
import Plans from "../change-plan/Plans"
import StepBar from "./StepBar"
import Widgets from "../change-plan/Widgets"
import DashboardReady from "./DashboardReady"

const initialValues = (): UserSignUpAttrs => {
  const params = (new URL(document.location.href)).searchParams
  return {
    agreed: false,
    billingPlanId: null,
    couponCode: params?.get("couponCode") || "",
    customerName: params?.get("company") || "",
    email: params?.get("email") || "",
    firstName: params?.get("firstName") || "",
    lastName: params?.get("lastName") || "",
    password: "",
    widgetTemplateIds: [],
  }
}

interface SignUpProps {
  plans: BillingPlan[];
  state: any;
  templates: WidgetTemplate[];
  onSubmit(attrs: UserSignUpAttrs, checkonly: boolean, checkPlanOnly: boolean): Promise<any>;
}

const SignUp: React.FC<SignUpProps> = (props) => {
  const { plans, state, templates, onSubmit } = props
  const { t } = useTranslation()

  const [values, setValues] = useState<UserSignUpAttrs>(state?.values || initialValues())
  const step = state?.step || 1
  const [isWidgetsShown, setIsWidgetsShown] = useState(state?.isWidgetsShown || false)

  const getCurrentState = (): any => ({ values, step, isWidgetsShown })
  const getPathForStep = (step: number): string => {
    switch (step) {
      case 3:
        return "/ready"
      case 2:
        return "/account"
      default:
        return "/register"
    }
  }
  const goToStep = (step: number, data?: any): void => {
    const currentState = getCurrentState()
    const values = data || currentState.values
    navigate(getPathForStep(step), { replace: true, state: { ...currentState, step, values } })
  }

  const handleFormSubmit = async (attrs: UserSignUpAttrs): Promise<any> => {
    if (step === 1) {
      return onSubmit(attrs, true, true)
        .then(() => goToStep(2, attrs))
        .catch((errors: any) => errors)
    }
    if (step === 2) {
      return onSubmit(attrs, true, false)
        .then(() => goToStep(3, attrs))
        .catch((errors: any) => errors)
    }
    return onSubmit(attrs, false, false)
      .then(({ redirectUrl }: { redirectUrl: string }) => {
        try {
          (window as any)?.dataLayer?.push({
            event: "virtualPageView",
            page: { title: "Ready", url: "/ready" }
          })
        } catch (error) {
          console.error(error)
        }
        if (redirectUrl) {
          window.location.href = redirectUrl
        } else {
          navigate("/sites", { replace: true })
        }
      })
      .catch((errors: any) => errors)
  }

  const handleClickPlan = (id: number, couponCode?: string | null): void => {
    const plan = plans.find(p => p.id === id)
    if (plan?.perWidget) {
      setValues({ ...values, billingPlanId: id, couponCode: couponCode || null })
      setIsWidgetsShown(true)
    } else {
      handleFormSubmit({ ...values, billingPlanId: id, couponCode: couponCode || null })
    }
  }

  const backToPlans = (): void => {
    setValues({ ...values, billingPlanId: null })
    setIsWidgetsShown(false)
  }

  return (
    <Form
      initialValues={values}
      mutators={{...arrayMutators}}
      onSubmit={handleFormSubmit}
      render={(renderProps: FormRenderProps<UserSignUpAttrs>): React.ReactNode => {
        const { handleSubmit, values } = renderProps
        return (
          <div className="sign-up-form">
            <StepBar currentStep={step}/>
            <form onSubmit={handleSubmit}>
              {step === 1 && !isWidgetsShown && (
                <Plans canApplyCoupon={true} onClick={handleClickPlan} plans={plans}/>
              )}
              {step === 1 && isWidgetsShown && (
                <Widgets confirmationText={t("Next")} templates={templates} onBack={backToPlans}/>
              )}
              {step === 2 && <UserFields onBack={(): void => goToStep(1)}/>}
              {step === 3 && (
                <DashboardReady name={values.customerName} onBack={(): void => goToStep(2)}/>
              )}
            </form>
          </div>
        )
      }}
    />
  )
}

export default SignUp
