import React from "react"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import RadioGroup from "./RadioGroup"
import { StatefulIconProps } from "../../utils/icons"

interface LayoutSelectProps {
  layouts: WidgetLayout[];
  name: string;
  template?: WidgetTemplate;
}

const LayoutSelect: React.FC<LayoutSelectProps> = (props: LayoutSelectProps) => {
  const { t } = useTranslation()
  const { layouts, template, ...otherProps } = props

  const iconFor = (template: WidgetTemplate | undefined, layout: WidgetLayout): React.FC<StatefulIconProps> => {
    return (props: StatefulIconProps): any => {
      if (!template) return null

      const { active } = props
      const className = cn(`layout__icon layout__icon-${template.slug}-${layout}`, {
        "layout__icon--active": active,
      })

      return <div className={className} />
    }
  }

  const renderOptionProps = (layout: WidgetLayout): object => ({
    icon: iconFor(template, layout),
    label: t(`Layout_${layout}`),
  })

  return (
    <RadioGroup
      {...otherProps}
      className="widget-form__layouts"
      options={layouts}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default LayoutSelect
