import React from "react"
import cn from "classnames"
import Box, { BoxProps } from "./Box"
import ArrowRightIcon from "../../images/icons/other/ArrowRightIcon"

interface HeaderProps extends BoxProps {
  children?: React.ReactNode;
  icon?: React.ReactNode;
  text?: string;
  text2?: string;
  variant: "main";
}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const { children, icon, text, text2 } = props
  return (
    <Box {...props} className={cn("main-header", props.className)}>
      {icon && <div className="main-header__icon-wrapper">{icon}</div>}
      <div className="main-header__title">
        {text || text2}
        {text && text2 && (
          <>
            <span className="main-header__arrow"><ArrowRightIcon /></span>
            <span className="main-header__subheader">{text2}</span>
          </>
        )}
      </div>
      <div className="main-header__content">{children}</div>
    </Box>
  )
}

export default Header
