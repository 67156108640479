import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const ImageIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { fillOpacity, primaryColor, secondaryColor } = useStyles(props)

  return (
    <svg width="34" height="26" viewBox="0 0 34 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="32" height="23.25" rx="2" stroke={primaryColor} strokeWidth="2"/>
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="30" height="22">
        <path d="M2 3C2 2.44772 2.44772 2 3 2H31C31.5523 2 32 2.44772 32 3V22.25C32 22.8023 31.5523 23.25 31 23.25H3C2.44772 23.25 2 22.8023 2 22.25V3Z" fill="#F1F5F8"/>
      </mask>
      <g mask="url(#mask0)">
        <mask id="path-3-outside-1" maskUnits="userSpaceOnUse" x="-1.52124" y="4.62793" width="38" height="21" fill="black">
          <rect fill="white" x="-1.52124" y="4.62793" width="38" height="21"/>
          <path d="M14.4573 19.2996L9.52535 14.3677C9.13482 13.9772 8.50166 13.9772 8.11113 14.3677L-0.52124 23.0001V24.2501H34.5022V12.3751L29.2635 6.50144C28.8545 6.04281 28.1324 6.05819 27.7433 6.53382L17.4194 19.1519C16.6701 20.0677 15.2939 20.1363 14.4573 19.2996Z"/>
        </mask>
        <path d="M14.4573 19.2996L9.52535 14.3677C9.13482 13.9772 8.50166 13.9772 8.11113 14.3677L-0.52124 23.0001V24.2501H34.5022V12.3751L29.2635 6.50144C28.8545 6.04281 28.1324 6.05819 27.7433 6.53382L17.4194 19.1519C16.6701 20.0677 15.2939 20.1363 14.4573 19.2996Z" fill={secondaryColor} fillOpacity={fillOpacity}/>
        <path d="M9.52535 14.3677L10.2325 13.6606L9.52535 14.3677ZM14.4573 19.2996L13.7501 20.0067L14.4573 19.2996ZM17.4194 19.1519L18.1933 19.7851H18.1933L17.4194 19.1519ZM27.7433 6.53382L28.5172 7.16705V7.16705L27.7433 6.53382ZM-0.52124 24.2501H-1.52124C-1.52124 24.8024 -1.07353 25.2501 -0.52124 25.2501L-0.52124 24.2501ZM-0.52124 23.0001L-1.22835 22.293C-1.41588 22.4805 -1.52124 22.7349 -1.52124 23.0001H-0.52124ZM8.11113 14.3677L8.81824 15.0748L8.11113 14.3677ZM29.2635 6.50144L28.5172 7.16705L29.2635 6.50144ZM34.5022 12.3751H35.5022C35.5022 12.1296 35.4119 11.8927 35.2485 11.7095L34.5022 12.3751ZM34.5022 24.2501V25.2501C35.0544 25.2501 35.5022 24.8024 35.5022 24.2501H34.5022ZM8.81824 15.0748L13.7501 20.0067L15.1644 18.5925L10.2325 13.6606L8.81824 15.0748ZM18.1933 19.7851L28.5172 7.16705L26.9693 5.90058L16.6454 18.5186L18.1933 19.7851ZM0.47876 24.2501V23.0001H-1.52124V24.2501H0.47876ZM0.185867 23.7072L8.81824 15.0748L7.40403 13.6606L-1.22835 22.293L0.185867 23.7072ZM28.5172 7.16705L33.7559 13.0407L35.2485 11.7095L30.0098 5.83582L28.5172 7.16705ZM33.5022 12.3751V24.2501H35.5022V12.3751H33.5022ZM34.5022 23.2501H-0.52124V25.2501H34.5022V23.2501ZM28.5172 7.16705L28.5172 7.16705L30.0098 5.83582C29.1917 4.91856 27.7476 4.94932 26.9693 5.90058L28.5172 7.16705ZM13.7501 20.0067C15.0052 21.2617 17.0694 21.1588 18.1933 19.7851L16.6454 18.5186C16.2708 18.9765 15.5827 19.0108 15.1644 18.5925L13.7501 20.0067ZM10.2325 13.6606C9.4514 12.8795 8.18507 12.8796 7.40403 13.6606L8.81824 15.0748L8.81824 15.0748L10.2325 13.6606Z" fill={primaryColor} mask="url(#path-3-outside-1)"/>
        <circle cx="8" cy="8" r="2.5" fill={secondaryColor} fillOpacity={fillOpacity} stroke={primaryColor} strokeLinejoin="round"/>
      </g>
    </svg>
  )
}

export default ImageIcon
