import { decamelizeKeys } from "humps"
import { AxiosResponse } from "axios"
import req from "./jsonRequest"
import { handleFormErrors } from "./utils"

interface AccountResponse {
  account: Account;
}

function accountFromResponse(response: AxiosResponse<AccountResponse>): Account {
  return response.data.account
}

async function get(): Promise<Account | null> {
  return req.get<AccountResponse>("/settings/account.json")
    .then(accountFromResponse)
    .catch(() => null)
}

async function save(account: Account): Promise<Account> {
  const data = { account: decamelizeKeys(account) }
  return req.patch<AccountResponse>("/settings/account.json", data)
    .then(accountFromResponse)
    .catch(handleFormErrors)
}

export default {
  get,
  save,
}
