import req from "./jsonRequest"

async function getCouponCode(code: string | null): Promise<CouponCode | null> {
  return req.get<any>("/coupon_code_info.json", { params: { code } })
    .then(response => response.data.couponCode)
    .catch(() => null)
}

export default {
  getCouponCode,
}
