import React from "react"
import { statefulIcon } from "../../../utils/icons"

const BellIcon: React.FC<{}> = () => {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1378 17.27C20.4887 15.8759 19.543 13.8384 19.543 11.6797V8.63874C19.543 4.80045 16.6917 1.62304 12.9984 1.09077C12.1845 0.973473 12.4927 1.00342 11.8905 1.00342C11.2886 1.00342 11.3412 1.01524 10.8168 1.09078C7.12234 1.62304 4.27228 4.80045 4.27228 8.63874V11.6797C4.27228 13.8384 3.32658 15.8759 1.66748 17.2786C1.24326 17.6419 1 18.1698 1 18.7282C1 19.7809 1.85622 20.6372 2.90876 20.6372H20.9063C21.959 20.6372 22.8153 19.7809 22.8153 18.7282C22.8153 18.1698 22.572 17.6419 22.1378 17.27Z" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M16.4219 21.8273L16.544 21.2275H15.9319H7.91699H7.30488L7.42705 21.8273C7.85312 23.9191 9.70634 25.4998 11.9245 25.4998C14.1427 25.4998 15.996 23.9192 16.4219 21.8273Z" stroke="#CDDFEC"/>
    </svg>
  )
}

const BellActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1378 17.27C20.4887 15.8759 19.543 13.8384 19.543 11.6797V8.63874C19.543 4.80045 16.6917 1.62304 12.9984 1.09077C12.1845 0.973473 12.4927 1.00342 11.8905 1.00342C11.2886 1.00342 11.3412 1.01524 10.8168 1.09078C7.12234 1.62304 4.27228 4.80045 4.27228 8.63874V11.6797C4.27228 13.8384 3.32658 15.8759 1.66748 17.2786C1.24326 17.6419 1 18.1698 1 18.7282C1 19.7809 1.85622 20.6372 2.90876 20.6372H20.9063C21.959 20.6372 22.8153 19.7809 22.8153 18.7282C22.8153 18.1698 22.572 17.6419 22.1378 17.27Z" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M16.4219 21.8275L16.544 21.2277H15.9319H7.91699H7.30488L7.42705 21.8275C7.85312 23.9193 9.70634 25.5 11.9245 25.5C14.1427 25.5 15.996 23.9193 16.4219 21.8275Z" stroke="#CDDFEC"/>
    </svg>
  )
}

export default statefulIcon(BellIcon, BellActiveIcon)
