import React from "react"
import Button from "../buttons/Button"
import { useTranslation } from "react-i18next"
import ArrowLeftIcon from "../../images/icons/other/ArrowLeftIcon"
import ArrowRightIcon from "../../images/icons/other/ArrowRightIcon"
import CheckboxField from "../form-fields/CheckboxField"
import Header from "../boxes/Header"
import Paper from "../boxes/Paper"
import ControlGroup from "../boxes/ControlGroup"
import TextField from "../form-fields/TextField"

interface UserFieldsProps {
  onBack(id: number): void;
}

const UserFields: React.FC<UserFieldsProps> = ({ onBack }: UserFieldsProps) => {
  const { t } = useTranslation()

  return (
    <div className="sign-up-user-fields">
      <Header variant="main" text={t("CreateYourAccount")}/>
      <Paper gap="30px">
        <ControlGroup maxWidth="480px">
          <TextField type="text" name="customerName" label="Company" />
          <TextField type="text" name="firstName" label="First name" />
          <TextField type="text" name="lastName" label="Last name" />
          <TextField type="email" name="email" label="Email" />
          <TextField type="password" name="password" label="Password" />
          <CheckboxField name="agreed">
            {t("IHaveRead")}&nbsp;
            <a
              className="link"
              href="https://pliek.io/terms"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t("agreement")}
            </a>
          </CheckboxField>
        </ControlGroup>
      </Paper>
      <ControlGroup gap="30px">
        <Button secondary icon={ArrowLeftIcon} onClick={onBack} type="button">
          {t("Back")}
        </Button>
        <Button className="button--with-icon-at-right" icon={ArrowRightIcon} type="submit">
          {t("createDashboard")}
        </Button>
      </ControlGroup>
    </div>
  )
}

export default UserFields
