import React from "react"

interface StatisticsCardProps {
  icon: any;
  title: string;
  value: string | number;
}

const StatisticsCard: React.FC<StatisticsCardProps> = (props: StatisticsCardProps) => {
  const { icon, title, value } = props
  const Icon = icon

  return (
    <div className="card paper statistics-card">
      <Icon className="statistics-card__icon" />
      <div className="statistics-card__value">{value}</div>
      <div className="statistics-card__title">{title}</div>
    </div>
  )
}

export default StatisticsCard
