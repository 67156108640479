import React from "react"

const LogoutIcon: React.FC<{}> = () => {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.61807 4.74361C7.82809 3.08945 9.08075 1.77299 10.728 1.51428C12.3494 1.25961 14.3958 1 16 1C17.5451 1 19.5003 1.24083 21.091 1.48611C22.8269 1.75379 24.1088 3.19491 24.348 4.93497C24.6752 7.31603 25 9.21519 25 12.5C25 15.0724 24.6732 18.1349 24.3933 20.2867C24.18 21.9261 22.9335 23.224 21.3004 23.4813C19.6745 23.7373 17.6136 24 16 24C14.3958 24 12.3494 23.7404 10.728 23.4857C9.08075 23.227 7.82809 21.9106 7.61807 20.2564C7.33568 18.0323 7 14.8865 7 12.5C7 10.1135 7.33568 6.96773 7.61807 4.74361Z" stroke="#CDDFEC" strokeWidth="2"/>
      <rect x="6" y="7" width="4" height="10" fill="white"/>
      <path d="M1 11.5C0.723858 11.5 0.5 11.7239 0.5 12C0.5 12.2761 0.723858 12.5 1 12.5V11.5ZM9.35355 12.3536C9.54882 12.1583 9.54882 11.8417 9.35355 11.6464L6.17157 8.46447C5.97631 8.2692 5.65973 8.2692 5.46447 8.46447C5.2692 8.65973 5.2692 8.97631 5.46447 9.17157L8.29289 12L5.46447 14.8284C5.2692 15.0237 5.2692 15.3403 5.46447 15.5355C5.65973 15.7308 5.97631 15.7308 6.17157 15.5355L9.35355 12.3536ZM1 12.5H9V11.5H1V12.5Z" fill="#CDDFEC"/>
    </svg>
  )
}

export default LogoutIcon
