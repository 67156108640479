/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import { usePixelCodeCopier } from "../utils/code"
import Button from "../components/buttons/Button"
import EmptyCollection from "../components/layout/EmptyCollection"
import Layout from "../components/layout/DomainsLayout"
import WidgetList from "../components/widgets/WidgetList"
import sitesApi from "../api/sites"
import widgetsApi from "../api/widgets"

import CodeIcon from "../images/icons/other/CodeIcon"
import DomainIcon from "../images/icons/other/DomainIcon"
import PlusIcon from "../images/icons/other/PlusIcon"

interface SiteWidgetsPageProps extends RouteComponentProps {
  clientKey?: string;
  siteId?: string;
}

const HeaderContent: React.FC<SiteWidgetsPageProps> = (props: SiteWidgetsPageProps) => {
  const { t } = useTranslation()
  const { clientKey, siteId } = props
  const copyPixelCode = usePixelCodeCopier(clientKey)

  return(
    <>
      <Button icon={PlusIcon} href={siteId && `/sites/${siteId}/widgets/new`}>
        {t("newCampaign")}
      </Button>
      <Button secondary className="button--with-check-effect" icon={CodeIcon} onClick={copyPixelCode}>
        {t("pixelCode")}
      </Button>
    </>
  )
}

const SiteWidgetsPage: React.FC<SiteWidgetsPageProps> = (props: SiteWidgetsPageProps) => {
  const { siteId } = props
  const { t } = useTranslation()
  const [isWidgetsFetched, setIsWidgetsFetched] = useState(false)
  const [site, setSite] = useState<CustomerSite | null>(null)

  useEffect(() => {
    sitesApi.getOneWithWidgets(siteId).then((site: CustomerSite | null) => {
      setSite(site)
      setIsWidgetsFetched(true)
    })
  }, [])

  const handleDeleteWidget = (widgetId: number): void => {
    widgetsApi.destroy(widgetId)
      .then(() => {
        const newSite: CustomerSite = { ...site! }
        newSite.widgets = newSite.widgets!.filter(w => w.id !== widgetId)
        setSite(newSite)
      })
  }

  return (
    <Layout
      activeSiteId={site && (site.hostname || site.id) || undefined}
      activeSubSection="campaigns"
      headerContent={site && <HeaderContent clientKey={site.clientKey} siteId={siteId} />}
      headerIcon={site && <DomainIcon src={site.iconUrl} />}
      headerText={site?.name || ""}
      headerSubText={t("navigationCampaigns")}
    >
      {site && site.widgets && site.widgets.length !== 0 && (
        <WidgetList
          availableTemplateIds={site.availableTemplateIds}
          onDelete={handleDeleteWidget}
          widgets={site.widgets || []}
        />
      )}
      {isWidgetsFetched && site && site.widgets && site.widgets.length === 0 && (
        <EmptyCollection
          title={t("emptyCampaignsTitle")}
          description={t("emptyCampaignsDescription")}
          actionText={t("createCampaign")}
          onAction={(): Promise<void> => navigate(`/sites/${site.hostname || site.id}/widgets/new`)}
        />
      )}
    </Layout>
  )
}

export default SiteWidgetsPage
