import React from "react"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"
import PositionSelect from "../../form-fields/PositionSelect"

const positions: ImagePosition[] = [
  "left top", "center top", "right top",
  "left center", "center center", "right center",
  "left bottom", "center bottom", "right bottom"
]

const ImageCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup visible={!!parameterTypes.imageUrl}>
        <Field name="imageUrl" prefix="parameters" type={parameterTypes.imageUrl} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.imageUrlHint}>
        <Field name="imageUrlHint" prefix="parameters" type={parameterTypes.imageUrlHint} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.backgroundImageUrl}>
        <Field name="backgroundImageUrl" prefix="parameters" type={parameterTypes.backgroundImageUrl} />
      </FormGroup>
      <FormGroup title={t("ImagePosition")} visible={!!parameterTypes.imagePosition}>
        <PositionSelect positions={positions} name="theme.imagePosition" />
      </FormGroup>
      <FormGroup title={t("ImagePosition")} visible={!!parameterTypes.backgroundImagePosition}>
        <PositionSelect positions={positions} name="theme.backgroundImagePosition" />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.imageBlured}>
        <Field name="imageBlured" prefix="parameters" type={parameterTypes.imageBlured} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.imageShaded}>
        <Field name="imageShaded" prefix="parameters" type={parameterTypes.imageShaded} />
      </FormGroup>
    </>
  )
}

export default ImageCustomization
