import React from "react"
import Grid from "@material-ui/core/Grid"
import { FormRenderProps } from "react-final-form"
import { useTranslation } from "react-i18next"
import { Link as RouterLink } from "@reach/router"
import {
  getAdditionalFieldTypes, getCustomizationOptions,
} from "../../utils/widget-builder/fields"
import possibleFields from "../../utils/widget-builder/possibleFields"
import Field from "../../utils/widget-builder/Field"
import StylePopupContent from "./style-tabs"

import Button from "../buttons/Button"
import SwitchboxField from "../form-fields/SwitchboxField"
import FormGroup from "../form-fields/FormGroup"
import LayoutSelect from "../form-fields/LayoutSelect"
import PositionSelect from "../form-fields/PositionSelect"
import StyleTabSelect from "../form-fields/StyleTabSelect"
import TextField from "../form-fields/TextField"
import TextFieldArray from "../form-fields/TextFieldArray"

interface WidgetFormProps {
  renderProps: FormRenderProps<CustomerWidget>;
  templates: WidgetTemplate[];
  widget: CustomerWidget;
}

const appearAnimations = [
  "without-animation", "fade-animation", "slide-from-top-animation", "scale-from-center-animation",
]
const disappearAnimations = [
  "hide-without-animation", "hide-fade-animation", "hide-slide-to-top-animation", "hide-scale-to-center-animation",
]

const WidgetForm: React.FC<WidgetFormProps> = (props: WidgetFormProps) => {
  const { renderProps, templates, widget } = props
  const { t } = useTranslation()

  const { handleSubmit, values } = renderProps
  const { activeStyleTab, layout } = values
  const templateId = Number(values.templateId)
  const currentTemplate = templates.find(({ id }) => id === templateId)
  const parameterTypes = (currentTemplate?.parameterTypes || {}) as any
  const layoutParameterTypes = parameterTypes[layout || "default"] || {}
  const additionalFieldTypes = getAdditionalFieldTypes(layoutParameterTypes)
  const customizationOptions = getCustomizationOptions(layoutParameterTypes)
  const layouts = currentTemplate?.layouts || []

  const usedPathnames = values.pathnames || []
  const unavailablePositions = Object.entries(widget.unavailablePositions).reduce((positions, [position, value]) => {
    const isUsed = value as boolean
    const usedPaths = value as Array<string>
    if (values.allPages || isUsed === true || usedPathnames.some(p => usedPaths.includes(p))) {
      (positions as Array<WidgetPosition>).push(position as WidgetPosition)
    }
    return positions
  }, [])

  const isAnimationDurationFieldVisible = (
    !!layoutParameterTypes.animationDuration && (
      values.theme.animationOnAppear !== "without-animation" || (
        values.theme.animationOnDisappear !== "hide-without-animation" &&
        values.theme.animationOnDisappear !== "without-animation"
      )
    )
  )

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <FormGroup>
          <TextField label={t("widgetName")} name="name" type="text" />
        </FormGroup>
        {layouts.length > 0 && <FormGroup title={t("Layout")}>
          <LayoutSelect layouts={layouts} name="layout" template={currentTemplate} />
        </FormGroup>}
        <FormGroup title={t("Position")}>
          <PositionSelect unavailablePositions={unavailablePositions} name="position" />
        </FormGroup>
        <FormGroup title={t("Style")} visible={!!customizationOptions.length}>
          <StyleTabSelect name="activeStyleTab" tabs={customizationOptions} />
        </FormGroup>
        {activeStyleTab && <FormGroup className="widget-form__styles-customization">
          <StylePopupContent
            activeStyleTab={activeStyleTab as WidgetParameterCategory}
            parameterTypes={layoutParameterTypes}
          />
        </FormGroup>}
        <div className="form-group-wrapper--locked">
          {!widget.isBrandingOptional && <div className="form-group__unlock">
            <RouterLink className="link" to="/settings/billing">
              {t("Upgrade")}
            </RouterLink> {t("toUnlock")}
          </div>}
          <FormGroup visible={!!layoutParameterTypes.pliekBranding}>
            <Field locked={!widget.isBrandingOptional} name="pliekBranding" type={layoutParameterTypes.pliekBranding} />
          </FormGroup>
        </div>
        <FormGroup visible={!!additionalFieldTypes.hasXClose}>
          <Field name="hasXClose" type={additionalFieldTypes.hasXClose} />
        </FormGroup>
        {possibleFields.map(fieldName => (
          <FormGroup key={fieldName} visible={!!additionalFieldTypes[fieldName]}>
            <Field name={fieldName} prefix="parameters" type={additionalFieldTypes[fieldName]} />
          </FormGroup>
        ))}
        <FormGroup>
          <SwitchboxField label={t("ShowOnAllPages")} name="allPages" />
        </FormGroup>
        <FormGroup title={t("OnlyForPages")} visible={!values.allPages} />
        {!values.allPages &&
          <TextFieldArray label={t("EnterPageAddress")} name="pathnames" type="text" />
        }
        <FormGroup title={t("pagesToTrackConversions")} visible={!!layoutParameterTypes.pagesToTrackConversions} />
        {layoutParameterTypes.pagesToTrackConversions &&
          <TextFieldArray label={t("EnterPageAddress")} name="pagesToTrackConversions" type="text" />
        }
        <FormGroup title={t("DisplayOptions")} visible={!!layoutParameterTypes.showOnMobileDevices} />
        <FormGroup visible={!!layoutParameterTypes.showOnMobileDevices}>
          <Field name="showOnMobileDevices" prefix="parameters" type={layoutParameterTypes.showOnMobileDevices} />
        </FormGroup>
        <FormGroup visible={!!layoutParameterTypes.showOnTabletDevices}>
          <Field name="showOnTabletDevices" prefix="parameters" type={layoutParameterTypes.showOnTabletDevices} />
        </FormGroup>
        <FormGroup visible={!!layoutParameterTypes.showOnDesktopDevices}>
          <Field name="showOnDesktopDevices" prefix="parameters" type={layoutParameterTypes.showOnDesktopDevices} />
        </FormGroup>
        <FormGroup title={t("Animation")} visible={!!layoutParameterTypes.animationOnAppear} />
        <FormGroup visible={!!layoutParameterTypes.animationOnAppear}>
          <Field
            name="animationOnAppear"
            options={appearAnimations}
            prefix="theme"
            type={layoutParameterTypes.animationOnAppear}
          />
        </FormGroup>
        <FormGroup visible={!!layoutParameterTypes.animationOnDisappear}>
          <Field
            name="animationOnDisappear"
            options={disappearAnimations}
            prefix="theme"
            type={layoutParameterTypes.animationOnDisappear}
          />
        </FormGroup>
        <FormGroup visible={isAnimationDurationFieldVisible}>
          <Field
            name="animationDuration"
            prefix="theme"
            type={layoutParameterTypes.animationDuration}
            unit="s"
          />
        </FormGroup>
        <FormGroup title={t("Trigger")} />
        <FormGroup>
          <TextField label={t("delayToShow")} name="delayToShow" type="number" />
        </FormGroup>
        <FormGroup>
          <TextField label={t("delayToHide")} name="delayToHide" type="number" />
        </FormGroup>
        <FormGroup>
          <Field name="activeSince" type="datetime" />
        </FormGroup>
        <FormGroup>
          <Field name="activeUntil" type="datetime" />
        </FormGroup>
        <FormGroup title={t("redirectToAfterFormSubmit")} visible={!!layoutParameterTypes.redirectUrl}>
          <TextField label={t("urlAddress")} name="parameters.redirectUrl" type="text" />
        </FormGroup>
        <FormGroup title={t("sendDataTo")} visible={!!layoutParameterTypes.emailToSendData}>
          <TextField label={t("email")} name="parameters.emailToSendData" type="email" />
        </FormGroup>
        <FormGroup>
          <Grid item xs={12}>
            <Button type="submit">
              {t("save")}
            </Button>
            <Button secondary href={`/sites/${widget.siteHostName || widget.siteId}/widgets`}>
              {t("backToWidgets")}
            </Button>
          </Grid>
        </FormGroup>
      </Grid>
    </form>
  )
}

export default WidgetForm
