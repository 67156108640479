import React from "react"

const ArrowRightIcon: React.FC<{}> = () => {
  return (
    <svg width="15" height="8" viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.3536 3.64645C14.5488 3.84171 14.5488 4.15829 14.3536 4.35355L11.1716 7.53553C10.9763 7.7308 10.6597 7.7308 10.4645 7.53553C10.2692 7.34027 10.2692 7.02369 10.4645 6.82843L13.2929 4L10.4645 1.17157C10.2692 0.97631 10.2692 0.659727 10.4645 0.464465C10.6597 0.269203 10.9763 0.269203 11.1716 0.464465L14.3536 3.64645ZM-4.37114e-08 3.5L14 3.5L14 4.5L4.37114e-08 4.5L-4.37114e-08 3.5Z" fill="#A9C0D1"/>
    </svg>
  )
}

export default ArrowRightIcon
