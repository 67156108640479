import React from "react"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const WidgetDetailsCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup title={t("background")} visible={!!parameterTypes.contentBackgroundColor}>
        <Field initialValue="#f8f8f8" name="contentBackgroundColor" prefix="theme" type={parameterTypes.contentBackgroundColor} />
      </FormGroup>
      <FormGroup title={t("background")} visible={!!parameterTypes.semitransparentBackgroundColor}>
        <Field initialValue="#f8f8f8" name="semitransparentBackgroundColor" prefix="theme" type={parameterTypes.semitransparentBackgroundColor} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.borderRadius}>
        <Field name="borderRadius" prefix="theme" type={parameterTypes.borderRadius} unit="px" />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.widgetBoxShadowRadius}>
        <Field name="widgetBoxShadowRadius" prefix="theme" type={parameterTypes.widgetBoxShadowRadius} />
      </FormGroup>
    </>
  )
}

export default WidgetDetailsCustomization
