import React, { useState } from "react"
import Paper from "../boxes/Paper"
import { useTranslation } from "react-i18next"
import { usePixelCodeCopier } from "../../utils/code"
import Button from "../buttons/Button"
import StateLabel from "../labels/StateLabel"
import StateSwitcher from "../switches/StateSwitcher"
import siteApi from "../../api/sites"

import CodeIcon from "../../images/icons/other/CodeIcon"
import DomainIcon from "../../images/icons/other/DomainIcon"
import ManageIcon from "../../images/icons/other/ManageIcon"

interface SiteCardProps {
  onUpdate: () => void;
  site: CustomerSite;
}

const SiteCard: React.FC<SiteCardProps> = (props: SiteCardProps) => {
  const { onUpdate } = props
  const { t } = useTranslation()
  const [site, setSite] = useState(props.site)
  const copyPixelCode = usePixelCodeCopier(site?.clientKey)

  const handleStateChange = (isChecked: boolean): void => {
    const updatedSite = { ...site }
    updatedSite.state = isChecked ? "active" : "disabled"
    setSite(updatedSite)
    siteApi.save(updatedSite).then((data: CustomerSite) => {
      setSite(data)
      onUpdate()
    })
  }

  const { hostname, iconUrl, id, isAllowedByBillingPlan, name, state } = site

  const cardClassName = [
    "card horizontal-card site-card",
    (!isAllowedByBillingPlan || state === "disabled") ? "site-card--inactive" : ""
  ].join(" ")

  return (
    <Paper className={cardClassName}>
      <div className="site-card__group">
        <div className="site-card__switch">
          <StateSwitcher value={state === "active"} onChange={handleStateChange}/>
        </div>
        <div className="site-card__domain">
          <div className="site-card__domain-icon">
            <DomainIcon src={iconUrl} />
          </div>
          {name || hostname}
        </div>
      </div>
      <div className="site-card__state">
        {isAllowedByBillingPlan || state !== "active" ? (
          <StateLabel active={state === "active"} />
        ) : (
          <div className="card__state card__state--forbidden">
            {t("sitesLimitExceeded")}
          </div>
        )}
      </div>
      <Button className="site-card__code button--with-check-effect" icon={CodeIcon} onClick={copyPixelCode}>
        {t("copyPixelCode")}
      </Button>
      <Button secondary rounded className="site-card__manage" href={`/sites/${hostname || id}/widgets`} icon={ManageIcon}>
        {t("manage")}
      </Button>
    </Paper>
  )
}

export default SiteCard
