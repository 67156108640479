/* eslint-disable @typescript-eslint/no-unused-vars */

import React from "react"
import { StatefulIconProps } from "../../../utils/icons"

import { ReactComponent as CallBackIcon } from "../../../../assets/images/icons/widgets/CallBack.svg"
import { ReactComponent as CallToActionIcon } from "../../../../assets/images/icons/widgets/CallToAction.svg"
import { ReactComponent as CountdownIcon } from "../../../../assets/images/icons/widgets/Countdown.svg"
import { ReactComponent as CouponIcon } from "../../../../assets/images/icons/widgets/CouponCode.svg"
import { ReactComponent as EmailBarIcon } from "../../../../assets/images/icons/widgets/EmailBar.svg"
import { ReactComponent as EmailCollectorIcon } from "../../../../assets/images/icons/widgets/EmailCollector.svg"
import { ReactComponent as EmojiIcon } from "../../../../assets/images/icons/widgets/EmojiFeedback.svg"
import { ReactComponent as FeedbackBoxIcon } from "../../../../assets/images/icons/widgets/FeedbackBox.svg"
import { ReactComponent as InformationalIcon } from "../../../../assets/images/icons/widgets/InformationalPopup.svg"
import { ReactComponent as LatestConversionIcon } from "../../../../assets/images/icons/widgets/LatestConversion.svg"
import { ReactComponent as LeadCollectorIcon } from "../../../../assets/images/icons/widgets/LeadCollector.svg"
import { ReactComponent as LeadPopupIcon } from "../../../../assets/images/icons/widgets/LeadPopup.svg"
import { ReactComponent as LiveVisitorCounterIcon } from "../../../../assets/images/icons/widgets/LiveVisitorsCounter.svg"
import { ReactComponent as NewProductIcon } from "../../../../assets/images/icons/widgets/NewProduct.svg"
import { ReactComponent as ReviewIcon } from "../../../../assets/images/icons/widgets/Review.svg"
import { ReactComponent as SpecialOfferIcon } from "../../../../assets/images/icons/widgets/SpecialOffer.svg"
import { ReactComponent as VideoIcon } from "../../../../assets/images/icons/widgets/VideoPopup.svg"
import { ReactComponent as WelcomeIcon } from "../../../../assets/images/icons/widgets/WelcomeNotification.svg"
import { ReactComponent as WhatsAppIcon } from "../../../../assets/images/icons/widgets/WhatsApp.svg"

interface IconStyles {
  backgroundColor: (activeColor: string) => string;
  primaryColor: (activeColor: string) => string;
}

export const useStyles = (props: StatefulIconProps): IconStyles => {
  const { active } = props

  return {
    backgroundColor: (activeColor: string): string => active ? activeColor : "#EAEFF3",
    primaryColor: (activeColor: string): string => active ? activeColor : "#BAD0E0",
  }
}

const iconForTemplateSlug = (templateSlug: WidgetTemplateSlug | null): React.FC<StatefulIconProps> | null => {
  switch(templateSlug) {
    case "coupon":
      return CouponIcon
    case "emoji":
      return EmojiIcon
    case "feedback-box":
      return FeedbackBoxIcon
    case "new-product":
      return NewProductIcon
    case "review":
      return ReviewIcon
    case "special-offer":
      return SpecialOfferIcon
    case "email-bar":
      return EmailBarIcon
    case "email-collector":
      return EmailCollectorIcon
    case "informational":
      return InformationalIcon
    case "lead-collector":
      return LeadCollectorIcon
    case "lead-popup":
      return LeadPopupIcon
    case "welcome":
      return WelcomeIcon
    case "call-back":
      return CallBackIcon
    case "whatsapp":
      return WhatsAppIcon
    case "call-to-action":
      return CallToActionIcon
    case "countdown":
      return CountdownIcon
    case "latest-converstion":
      return LatestConversionIcon
    case "live-visitor-counter":
      return LiveVisitorCounterIcon
    case "video":
      return VideoIcon
    default:
      return null
  }
}

interface IconProps extends StatefulIconProps {
  templateSlug: WidgetTemplateSlug | null;
}

const Icon: React.FC<IconProps> = (props: IconProps) => {
  const { active, templateSlug, ...restProps } = props
  const IconForSlug: React.FC<StatefulIconProps> | null = iconForTemplateSlug(templateSlug)

  return IconForSlug ? <IconForSlug {...restProps} /> : null
}

export default Icon
