import React from "react"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const InputsCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <FormGroup visible={!!parameterTypes.inputs}>
      <Field name="inputs" prefix="parameters" type={parameterTypes.inputs} />
    </FormGroup>
  )
}

export default InputsCustomization
