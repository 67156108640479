/* eslint-disable @typescript-eslint/camelcase */

export default {
  activate: "activate",
  activeSince: "Active since",
  activeUntil: "Active until",
  addDomain: "Add domain",
  addWidget: "Add widget",
  agreement: "agreement",
  AllDomains: "All domains",
  allCampaigns: "All campaigns",
  altText: "Link alt text",
  Animation: "Animation",
  animationDuration: "Animation duration (seconds)",
  animationOnAppear: "Animation on appear",
  animationOnDisappear: "Animation on disappear",
  ApplyCoupon: "Enter your coupon code if you have one",
  appName: "Pliek",
  autoplay: "Autoplay",
  Back: "Back",
  background: "background",
  backgroundImageUrl: "Background image URL",
  backToWidgets: "Back to widgets",
  BillingMethod: "Billing Method",
  borderRadius: "Corner radius",
  buttonText: "Text for button",
  button: "button",
  campaign: "campaign",
  campaigns: "Campaigns",
  cancel: "cancel",
  cancelButtonText: "Text for cancel",
  CancelSubscription: "Cancel subscription",
  CancelSubscriptionConfirmation: "Yes, cancel my subscription",
  change: "change",
  Change: "Change",
  ChangeCard: "Change Card",
  changeMyPassword: "Change my password",
  changeYourPassword: "Change your password",
  ChangePlan: "Change your plan",
  Choose: "Choose",
  checkbox: "Checkbox",
  checkboxEnabled: "Checkbox",
  checkboxLabel: "Label for checkbox",
  codes: "ENTER COUPON CODE",
  color: "color",
  confirm: "confirm",
  confirmation: "Confirmation",
  contactUs: "Contact us",
  conversionsPeriod: "Period",
  conversionsPeriodUnit: "Unit",
  conversionRate: "conversion rate",
  copyPixelCode: "copy pixel code",
  countdownTarget: "Timer end date",
  createCampaign: "Create campaign",
  created: "Created",
  createDashboard: "Create dashboard",
  CreateYourAccount: "Create your account",
  date: "date",
  deleteDomain: "Delete domain",
  deactivate: "deactivate",
  DisplayOptions: "Display options",
  domain: "Domain",
  delayToHide: "Hide after (seconds)",
  delayToShow: "Show after (seconds)",
  dropShadow: "Drop shadow",
  email: "Email",
  emailAndPhone: "email, phone",
  emailInputPlaceholder: "Label for email",
  emptyCampaignsTitle: "No campaigns yet",
  emptyCampaignsDescription: "Create a campaign in order to see your active campaigns here.",
  emptyDomainsDescription: "Add your first domain in order to see all your domains here.",
  emptyDomainsTitle: "No domains yet",
  emptyLeadsTitle: "No leads found",
  emptyLeadsDescription: "In the selected period no leads’ve been found.",
  engagement: "engagement",
  EnterPageAddress: "ENTER PAGE ADDRESS",
  exportToCSV: "Export to CSV",
  firstName: "First name",
  forgotPassword: "Forgot your password?",
  fullWidth: "Full width",
  pliekBranding: "Pliek branding",
  hasXClose: "Close button",
  headerText: "Header text",
  Hostname: "Host name",
  icon: "Icon",
  iconDisabled: "Icon",
  IconTypes_avatar: "avatars",
  IconTypes_icon: "icons",
  IHaveRead: "I have read the",
  imageBlured: "Blured background",
  ImagePosition: "Image position",
  imageUrl: "Image URL",
  imageShaded: "Shade background",
  impressions: "impressions",
  impressions75LimitReached: "You have reached 75% of the maximum amount of impressions for this period. Please upgrade your account to prevent your campaigns from being deactivated.",
  impressionsLimitExceeded: "You have reached the maximum amount of impressions for this period. Please upgrade your account to resume your running campaigns.",
  impressionsLimitExceededShort: "You have reached the maximum amount of impressions for this period.",
  invalidPhoneNumber: "please enter correct phone number",
  Invoices: "Invoices",
  lastName: "Last name",
  Layout: "Layout",
  Layout_bar: "Bar",
  "Layout_bar-icon": "Bar + icon",
  "Layout_bar-photo": "Bar + photo",
  "Layout_bar-small-photo": "Bar + photo",
  Layout_big: "Big",
  Layout_default: "Default",
  "Layout_default-bar": "Default",
  Layout_emoji: "Emoji",
  Layout_horizontal: "Horizontal",
  Layout_icon: "With icon",
  "Layout_icon-style": "Icon style",
  Layout_photo: "With photo",
  "Layout_photo-bg": "Photo b/g",
  "Layout_photo-bg-left": "Align left",
  "Layout_photo-bg-right": "Align right",
  "Layout_photo-left": "Photo left",
  "Layout_photo-style": "Photo style",
  Layout_vertical: "Vertical bar",
  leads: "leads",
  LifetimeDeals: "Lifetime deal",
  linkText: "Link text",
  linkUrl: "Link URL, like https://pliek.io/",
  listText1: "Column 1 text",
  listText2: "Column 2 text",
  listText3: "Column 3 text",
  listIconsDisabled: "Use icons",
  liveVisitorsPeriodMinutes: "Live within (minutes)",
  liveVisitorsText: "Live visitors label",
  logout: "Logout",
  mainText: "Main text",
  manage: "manage",
  name: "Name",
  nameInputPlaceholder: "Label for name",
  navigationAccount: "Account",
  navigationAllDomains: "All domains",
  navigationBackToDomains: "back to domains",
  navigationBilling: "Billing",
  navigationCampaigns: "Campaigns",
  navigationDashboard: "Dashboard",
  navigationDomains: "Domains",
  navigationLeads: "Leads",
  navigationManageDomain: "Manage domain",
  navigationSettings: "Settings",
  navigationStatistics: "Statistics",
  navigationWhatsNew: "What’s new",
  navigationHelpCenter: "Help center",
  newCampaign: "New campaign",
  newDomain: "New domain",
  newPassword: "New password",
  newPasswordConfirmation: "Confirmation",
  Next: "Next",
  NextPlan: "Next plan",
  Now: "NOW",
  notIncludedInYourBillingPlan: "not included in your plan",
  numberInputPlaceholder: "Placeholder for a phone number",
  oldPrice: "Old price",
  oldPriceEnabled: "Old price",
  OnlyForPages: "Only for pages",
  openLinkInNewTab: "Open in new tab",
  "Option__day": "days",
  "Option__fade-animation": "Fade",
  "Option__hide-fade-animation": "Fade",
  "Option__hide-slide-to-top-animation": "Slide",
  "Option__hide-scale-to-center-animation": "Scale",
  "Option__hide-without-animation": "No animation",
  "Option__hour": "hours",
  "Option__minute": "minutes",
  "Option__month": "months",
  "Option__scale-from-center-animation": "Scale",
  "Option__slide-from-top-animation": "Slide",
  "Option__without-animation": "No animation",
  "Option__week": "weeks",
  "Option__year": "years",
  overheadText: "Overhead text",
  pagesToTrackConversions: "Pages to track conversions",
  palette: "palette",
  password: "Password",
  phoneNumber: "Phone number",
  pixelCode: "Pixel code",
  pixelCodeCopied: "Your pixel code has been copied to your clipboard.",
  placeholder: "Placeholder",
  pleaseVisitFromYourPC: "Please visit your dashboard from a desktop computer. You can also open the link we sent you in the welcome email from your PC.",
  position: "Position",
  positionIsAlreadyInUse: "This position is already in use by another widget",
  positionIsAlreadyInUseNotification: "This position is already in use by another widget. This can cause overlapping widgets.",
  Position_bottom: "Bottom Center",
  Position_center_bottom: "Bottom",
  Position_bottom_left: "Bottom Left",
  Position_left_bottom: "Bottom Left",
  Position_bottom_right: "Bottom Right",
  Position_right_bottom: "Bottom Right",
  Position_center: "Center",
  Position_center_center: "Center",
  Position_left: "Center Left",
  Position_left_center: "Center Left",
  Position_right: "Center Right",
  Position_right_center: "Center Right",
  Position_top: "Top Center",
  Position_center_top: "Top",
  Position_top_left: "Top Left",
  Position_left_top: "Top Left",
  Position_top_right: "Top Right",
  Position_right_top: "Top Right",
  price: "Price",
  price_period: "Additional text",
  recent: "recent",
  redirectToAfterFormSubmit: "Redirect to after form submit",
  resetPasswordToken: "Reset password token",
  rotationEnabled: "Loop",
  rotationInterval: "Loop interval (seconds)",
  save: "Save",
  saveChanges: "Save changes",
  selectTemplate: "Choose a template",
  selectWidgets: "Select widgets",
  sendDataTo: "Send data to email",
  sendMeResetPasswordInstructions: "Send me password reset instructions",
  showConversionsForThePast: "Show conversions for the past",
  ShowOnAllPages: "Show on all pages",
  showOnDesktopDevices: "Show on desktop devices",
  showOnMobileDevices: "Show on mobile devices",
  showOnTabletDevices: "Show on tablet devices",
  showVisitorsToday: "Show daily visitors",
  SignIn: "Sign in",
  SignUpStep1: "Choose your plan",
  SignUpStep2: "Create your account",
  SignUpStep3: "Dashboard is ready",
  size: "Size",
  sitesLimitExceeded: "Limit exceeded",
  sitesLimitExceededError: "You exceeded the limit of allowed domains. Please upgrade your account to use more domains.",
  stars: "Number of stars",
  stateActive: "active",
  stateInactive: "inactive",
  status: "Status",
  Step: "Step",
  Style: "Style",
  Style_avatar: "Avatar",
  Style_button: "Button",
  Style_cancel_button: "Button 2",
  Style_checkbox: "Checkbox",
  Style_coupon_codes: "Coupon codes",
  Style_emoji: "Emoji",
  Style_heading: "Heading",
  Style_icon: "Icon",
  Style_image: "Image",
  Style_input: "Input",
  Style_inputs: "Input",
  Style_list_with_icon: "List with icon",
  Style_main_text: "Main text",
  Style_price: "Price",
  Style_subtitle: "Subtitle",
  Style_textarea: "Textarea",
  Style_timer: "Timer",
  Style_video: "Video",
  Style_widget_details: "Widget details",
  subscriptionCanceled: "Your subscription has been canceled",
  subscriptionWillBeCanceled: "Your subscription will be canceled at the end of the current billing period",
  subscriptionWillBeCanceledAt: "Will be canceled on {{date}}",
  subscriptionWillNotBeProlonged: "The plan will not be available",
  template: "Template",
  text: "text",
  textareaPlaceholder: "Placeholder for text area",
  topPages: "Top pages",
  toUnlock: "to unlock",
  Trigger: "Trigger",
  undo: "undo",
  Upgrade: "Upgrade",
  UpgradePlan: "Upgrade your plan",
  Upgrade_title: "Upgrade your account",
  Upgrade_description: "Make the most of your account and upgrade now",
  urlAddress: "URL address",
  videoUrl: "Link to YouTube video",
  visitors: "Visitors",
  visitorsTodayText: "Visitors today label",
  widgetName: "widget name",
  Widgets: "Widgets",
  WillBeApplied: "Will be applied",
  yourAccountHasExpired: "Your payment is overdue. Please check your credit card details to reactivate your campaigns.",
  YourPlan: "Your Plan",
  YourLastPlan: "Your last Plan",
  yourSubscriptionIsCancelled: "Your subscription is canceled",
  zapierToken: "Zapier token"
}
