import React from "react"

export interface BoxProps {
  className?: string;
  children?: React.ReactNode;
  gap?: string;
  maxWidth?: string;
  onClick?: () => void;
}

const Box: React.FC<BoxProps> = ({ className, children, gap, maxWidth, onClick }: BoxProps) => {
  const style: any = {}
  if (gap) style.marginTop = gap
  if (maxWidth) style.maxWidth = maxWidth
  return (
    <div className={className} onClick={onClick ? onClick : undefined} style={style}>
      {children}
    </div>
  )
}

export default Box
