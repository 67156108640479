import React from "react"
import { getPredefinedIcon } from "../../../utils/predefinedIcons"
import RadioGroup from "../../form-fields/RadioGroup"

const icons: string[] = [
  "mail", "phone", "coupon", "location",
  "checked", "person", "heart", "plane",
  "charts", "search", "cart", "percent",
]

interface IconSelectProps {
  name: string;
  type: string;
}

const IconSelect: React.FC<IconSelectProps> = (props: IconSelectProps) => {
  const renderOptionProps = (iconName: string): object => ({
    icon: getPredefinedIcon(iconName)
  })

  return (
    <RadioGroup
      {...props}
      className="widget-form__icons"
      options={icons}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default IconSelect
