import { decamelizeKeys } from "humps"
import { AxiosResponse } from "axios"
import req from "./jsonRequest"
import { handleFormErrors } from "./utils"

interface WidgetResponse {
  widget: CustomerWidget;
}

interface WidgetPreview {
  activeSince: string | null; // ISO Time string
  activeUntil: string | null; // ISO Time string
  backgroundImageUrl: string | null;
  countdownTarget: string; // ISO Time string
  delayToHide: number;
  delayToShow: number;
  html: string;
  htmlId: string;
  layout: WidgetLayout;
  style: string;
  timestamp: number;
  token: string;
}

interface WidgetTemplatesResponse {
  widgetTemplates: WidgetTemplate[];
}

function widgetFromResponse(response: AxiosResponse<WidgetResponse>): CustomerWidget {
  return response.data.widget
}

function previewFromResponse(response: AxiosResponse<WidgetPreview>): WidgetPreview {
  return response.data
}

async function destroy(widgetId: number | string): Promise<void> {
  return req.delete(`/widgets/${widgetId}.json`)
}

async function getOne(widgetId: number | string | undefined): Promise<CustomerWidget | null> {
  if (!widgetId) return null
  return req.get<WidgetResponse>(`/widgets/${widgetId}.json`)
    .then(widgetFromResponse)
    .catch(() => null)
}

async function getNew(siteId: number | string | undefined): Promise<CustomerWidget | null> {
  if (!siteId) return null
  return req.get<WidgetResponse>(`/sites/${siteId}/widgets/new.json`)
    .then(widgetFromResponse)
    .catch(() => null)
}

async function getPreview(widget: CustomerWidget): Promise<WidgetPreview> {
  const data = { widget: decamelizeKeys(widget) }
  const responsePromise = req.post<WidgetPreview>(`/widgets/preview.json`, data)
  return responsePromise.then(previewFromResponse)
}

async function getTemplates(): Promise<WidgetTemplate[]> {
  return req.get<WidgetTemplatesResponse>("/widgets/templates.json")
    .then(response => response.data.widgetTemplates)
    .catch(() => [])
}

async function save(widget: CustomerWidget): Promise<CustomerWidget> {
  const data = { widget: decamelizeKeys(widget) }
  const responsePromise = widget.id
    ? req.patch<WidgetResponse>(`/widgets/${widget.id}.json`, data)
    : req.post<WidgetResponse>("/widgets.json", data)
  return responsePromise.then(widgetFromResponse).catch(handleFormErrors)
}

export default {
  destroy,
  getNew,
  getOne,
  getPreview,
  getTemplates,
  save,
}
