import React from "react"
import { useTranslation } from "react-i18next"

interface StepProps {
  currentStep: number;
  step: number;
}

interface StepBarProps {
  currentStep: number;
}

const Step: React.FC<StepProps> = (props: StepProps) => {
  const { t } = useTranslation()
  const { currentStep, step } = props
  const isSelected = currentStep === step
  const isPrevious = currentStep > step
  const text = t(`SignUpStep${step}`)
  const classNames = [
    "sign-up-steps__item",
    isSelected ? "sign-up-steps__item--current" : "",
    isPrevious ? "sign-up-steps__item--previous" : "",
  ].join(" ")

  return (
    <div className={classNames}>
      <div className="sign-up-steps__item-header">{t("Step")} {step}</div>
      <div className="sign-up-steps__item-text">{text}</div>
    </div>
  )
}

const StepBar: React.FC<StepBarProps> = (props: StepBarProps) => {
  const { currentStep } = props

  return (
    <div className="sign-up-steps">
      <Step step={1} currentStep={currentStep}/>
      <Step step={2} currentStep={currentStep}/>
      <Step step={3} currentStep={currentStep}/>
    </div>
  )
}

export default StepBar
