import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import Layout from "../components/layout/DomainsLayout"
import WidgetBuilder from "../components/widgets/WidgetBuilder"
import widgetsApi from "../api/widgets"

const handleSubmit = async (attrs: CustomerWidget): Promise<any> => {
  return widgetsApi.save(attrs)
    .then(() => navigate(`/sites/${attrs.siteHostName || attrs.siteId}/widgets`))
    .catch(errors => errors)
}

interface WidgetEditProps extends RouteComponentProps {
  widgetId?: string;
}

const WidgetEdit: React.FC<WidgetEditProps> = (props: WidgetEditProps) => {
  const { widgetId } = props
  const [widget, setWidget] = useState<CustomerWidget | null>(null)
  const [templates, setTemplates] = useState<WidgetTemplate[]>([])

  useEffect(() => {
    widgetsApi.getOne(widgetId).then(setWidget)
    widgetsApi.getTemplates().then(setTemplates)
  }, [])

  if (!widget) return null

  return (
    <Layout
      activeSiteId={widget.siteHostName || widget.siteId}
      activeSubSection="campaigns"
    >
      <WidgetBuilder onSubmit={handleSubmit} templates={templates} widget={widget} />
    </Layout>
  )
}

export default WidgetEdit
