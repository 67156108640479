import React from "react"
import { statefulIcon } from "../../../utils/icons"

const ManageIcon: React.FC<{}> = () => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="3.5" y1="0.5" x2="3.5" y2="21.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="11.5" y1="0.5" x2="11.5" y2="21.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="19.5" y1="0.5" x2="19.5" y2="21.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <path d="M6 12.5C6 13.8807 4.88071 15 3.5 15C2.11929 15 1 13.8807 1 12.5C1 11.1193 2.11929 10 3.5 10C4.88071 10 6 11.1193 6 12.5Z" fill="white" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M14 6.5C14 7.88071 12.8807 9 11.5 9C10.1193 9 9 7.88071 9 6.5C9 5.11929 10.1193 4 11.5 4C12.8807 4 14 5.11929 14 6.5Z" fill="white" stroke="#CDDFEC" strokeWidth="2"/>
      <path d="M22 15.5C22 16.8807 20.8807 18 19.5 18C18.1193 18 17 16.8807 17 15.5C17 14.1193 18.1193 13 19.5 13C20.8807 13 22 14.1193 22 15.5Z" fill="white" stroke="#CDDFEC" strokeWidth="2"/>
    </svg>
  )
}

const ManageActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="3.5" y1="0.5" x2="3.5" y2="21.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="11.5" y1="0.5" x2="11.5" y2="21.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="19.5" y1="0.5" x2="19.5" y2="21.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <circle cx="3.5" cy="12.5" r="2.5" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
      <circle cx="11.5" cy="6.5" r="2.5" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
      <circle cx="19.5" cy="15.5" r="2.5" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
    </svg>
  )
}

export default statefulIcon(ManageIcon, ManageActiveIcon)
