/* eslint-disable @typescript-eslint/require-await */

import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Layout from "../components/layout/SettingsLayout"
import Billing from "../components/billing"
import billingApi from "../api/billing"

const BillingPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  const [billingInfo, setBillingInfo] = useState<BillingInfo | null>(null)

  const createPayment = async (): Promise<void> => {
    billingApi.createPayment().then((url) => window.location = url)
  }

  const undoSubscriptionCancel = (): Promise<BillingInfo> => {
    return billingApi.undoSubscriptionCancel().then((billing: BillingInfo) => {
      setBillingInfo(billing)
      return billing
    })
  }

  const cancelSubscription = (): Promise<BillingInfo> => {
    return billingApi.cancelSubscription().then((billing: BillingInfo) => {
      setBillingInfo(billing)
      return billing
    })
  }

  useEffect(() => {
    billingApi.get().then(setBillingInfo)
  }, [])

  useEffect(() => {
    if (!billingInfo?.changePlanOncePaid) return;
    const interval = setInterval((): void => {
      billingApi.get().then(setBillingInfo)
    }, 5000)
    return (): void => clearInterval(interval)
  }, [billingInfo?.changePlanOncePaid])

  return (
    <Layout
      activeSubSection="billing"
      headerText={t("navigationBilling")}
    >
      {billingInfo && (
        <Billing
          onCancelSubscription={cancelSubscription}
          onCreatePayment={createPayment}
          info={billingInfo}
          undoSubscriptionCancel={undoSubscriptionCancel}
        />
      )}
    </Layout>
  )
}

export default BillingPage
