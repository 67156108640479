import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const SubtitleIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="40" height="25" viewBox="0 0 40 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 18.5C0 18.2239 0.223858 18 0.5 18H39.5C39.7761 18 40 18.2239 40 18.5C40 18.7761 39.7761 19 39.5 19H0.5C0.223857 19 0 18.7761 0 18.5Z" fill={primaryColor}/>
      <path d="M0 24.5C0 24.2239 0.223858 24 0.5 24H29.5C29.7761 24 30 24.2239 30 24.5C30 24.7761 29.7761 25 29.5 25H0.5C0.223857 25 0 24.7761 0 24.5Z" fill={primaryColor}/>
      <path d="M0 12.5C0 12.2239 0.223858 12 0.5 12H39.5C39.7761 12 40 12.2239 40 12.5C40 12.7761 39.7761 13 39.5 13H0.5C0.223857 13 0 12.7761 0 12.5Z" fill={primaryColor}/>
      <rect width="28" height="3" rx="1.5" fill={primaryColor}/>
    </svg>
  )
}

export default SubtitleIcon
