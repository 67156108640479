import axios from "axios"
import { decamelizeKeys } from "humps"
import { getCsrfToken } from "../utils"
import { handleFormErrors } from "./utils"

async function requestNewPassword(user: UserForgotPasswordAttrs): Promise<any> {
  const headers = { "X-CSRF-Token": getCsrfToken() }
  return axios.post<any>("/reset-password.json", { user: decamelizeKeys(user) }, { headers })
    .then(response => response.data.message)
    .catch(handleFormErrors)
}

async function setNewPassword(user: UserNewPasswordAttrs): Promise<any> {
  const headers = { "X-CSRF-Token": getCsrfToken() }
  return axios.patch<{}>("/reset-password.json", { user: decamelizeKeys(user) }, { headers })
    .catch(handleFormErrors)
}

async function signIn(user: UserSignInAttrs): Promise<any> {
  const headers = { "X-CSRF-Token": getCsrfToken() }
  return axios.post<{}>("/login.json", { user: decamelizeKeys(user) }, { headers })
    .catch(handleFormErrors)
}

async function signOut(): Promise<boolean> {
  const headers = { "X-CSRF-Token": getCsrfToken() }
  try {
    const response = await fetch("/logout.json", { method: "DELETE", headers })
    return response.ok
  } catch (e) {
    console.log(e)
    return false
  }
}

async function signUp(user: UserSignUpAttrs, checkonly = false, checkPlanOnly = false): Promise<any> {
  const headers = { "X-CSRF-Token": getCsrfToken() }
  return axios.post<{}>("/register.json", { user: decamelizeKeys(user), checkonly, checkPlanOnly }, { headers })
    .then(response => response.data)
    .catch(handleFormErrors)
}

export default {
  requestNewPassword,
  setNewPassword,
  signIn,
  signOut,
  signUp,
}
