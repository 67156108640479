import React from "react"
import { navigate } from "@reach/router"
import { Form, FormRenderProps } from "react-final-form"
import { Link as RouterLink, RouteComponentProps } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../components/buttons/Button"
import ControlGroup from "../components/boxes/ControlGroup"
import Header from "../components/boxes/Header"
import Paper from "../components/boxes/Paper"
import TextField from "../components/form-fields/TextField"
import illustration from "../images/sign-up.svg"
import usersApi from "../api/users"

const initialValues: UserSignInAttrs = {
  email: "",
  password: "",
}

const handleFormSubmit = async (attrs: UserSignInAttrs): Promise<any> => {
  return usersApi.signIn(attrs)
    .then(() => navigate("/sites", { replace: true }))
    .catch((errors: any) => errors)
}

const SignIn: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation()
  return (
    <div className="sign-up-container">
      <img className="sign-up-container__illustration" src={illustration}/>
      <Form
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        render={({ handleSubmit }: FormRenderProps<UserSignInAttrs>): React.ReactNode => {
          return (
            <div className="sign-up-form">
              <form onSubmit={handleSubmit}>
                <div className="sign-up-user-fields">
                  <Header variant="main" text={t("SignIn")}/>
                  <Paper gap="30px">
                    <ControlGroup maxWidth="480px">
                      <TextField type="email" name="email" label="Email" />
                      <TextField type="password" name="password" label="Password" />
                      <RouterLink className="link" to="/forgot-password">
                        {t("forgotPassword")}
                      </RouterLink>
                    </ControlGroup>
                  </Paper>
                  <ControlGroup gap="30px">
                    <Button type="submit">
                      {t("SignIn")}
                    </Button>
                  </ControlGroup>
                </div>
              </form>
            </div>
          )
        }}
      />
    </div>
  )
}

export default SignIn
