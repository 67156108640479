import React from "react"
import cn from "classnames"
import Paper from "../boxes/Paper"
import WidgetIcon from "../../images/icons/widgets/Icon"

export interface TemplateCardProps {
  onToggle: (template: WidgetTemplate) => void;
  readOnly?: boolean;
  selected?: boolean;
  template: WidgetTemplate;
}

const TemplateCard: React.FC<TemplateCardProps> = (props: TemplateCardProps) => {
  const { onToggle, readOnly, template, selected } = props
  const cardClassName = cn("card widget-card template-card", {
    "template-card--readonly": readOnly,
    "template-card--selected": selected
  })

  return (
    <div
      className="template-card-wrapper"
      onClick={(): void => { !readOnly && onToggle && onToggle(template) }}
    >
      <Paper className={cardClassName}>
        <div className="widget-card__image">
          <WidgetIcon active templateSlug={template.slug} />
        </div>
        <div className="widget-card__title">
          {template.name}
        </div>
        <div className="template-card__description">
          {template.description}
        </div>
      </Paper>
    </div>
  )
}

export default TemplateCard
