import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import Button from "../buttons/Button"
import CustomerLayout from "./CustomerLayout"
import NavLink from "./NavLink"

import ArrowLeftIcon from "../../images/icons/other/ArrowLeftIcon"
import CampaignsIcon from "../../images/icons/menu/CampaignsIcon"
import DomainIcon from "../../images/icons/other/DomainIcon"
import HeavyPlusIcon from "../../images/icons/other/HeavyPlusIcon"
import LeadsIcon from "../../images/icons/menu/LeadsIcon"
import ListIcon from "../../images/icons/menu/ListIcon"
import ManageIcon from "../../images/icons/menu/ManageIcon"
import StatisticsIcon from "../../images/icons/menu/StatisticsIcon"

interface LayoutProps {
  activeSiteId?: number | string;
  activeSubSection: string;
  sites?: CustomerSite[];
  headerContent?: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerText?: string;
  headerSubText?: string;
}

const DomainsLayout: React.FC<LayoutProps> = ({
  activeSiteId, activeSubSection, children, sites = [], ...restProps
}) => {
  const SidebarContent: React.FC<any> = () => {
    const { t } = useTranslation()
    const isSiteStaffPage = ["campaigns", "leads", "statistics", "manage"].includes(activeSubSection)
    // const [isSitesShown, setIsSitesShown] = useState(!isSiteStaffPage) // TODO
    const isSitesShown = !isSiteStaffPage

    // const goToDomains = (): void => { // TODO: show domains section without leaving the current page
    //   setIsSitesShown(true)
    // }

    return (
      <Fragment>
        {!isSitesShown && <Button
          rounded
          secondary
          className="app-sidebar__round-button"
          icon={ArrowLeftIcon}
          href={"/sites"/*TODO: onClick={goToDomains*/}
        >
          {t("navigationBackToDomains")}
        </Button>}
        {isSitesShown && <Button
          rounded
          secondary
          className="app-sidebar__round-button app-sidebar__add-site-button"
          icon={HeavyPlusIcon}
          href="/sites/new"
        />}
        <div className="app-sidebar__secondary-title">
          {isSitesShown && <div className="app-sidebar__secondary-title-text">
            {t("navigationDomains")}
          </div>}
        </div>
        <div className="app-sidebar__links">
          {isSitesShown && <NavLink activeSection={activeSubSection} icon={ListIcon} section="list" to="/sites">
            {t("navigationAllDomains")}
          </NavLink>}
          {!isSitesShown && <Fragment>
            <NavLink
              activeSection={activeSubSection}
              icon={CampaignsIcon}
              section="campaigns"
              to={!!activeSiteId && `/sites/${activeSiteId}/widgets` || undefined}
            >
              {t("navigationCampaigns")}
            </NavLink>
            <NavLink
              activeSection={activeSubSection}
              icon={LeadsIcon}
              section="leads"
              to={!!activeSiteId && `/sites/${activeSiteId}/leads` || undefined}
            >
              {t("navigationLeads")}
            </NavLink>
            <NavLink
              activeSection={activeSubSection}
              icon={StatisticsIcon}
              section="statistics"
              to={!!activeSiteId && `/sites/${activeSiteId}/statistics` || undefined}
            >
              {t("navigationStatistics")}
            </NavLink>
            <NavLink
              activeSection={activeSubSection}
              icon={ManageIcon}
              section="manage"
              to={!!activeSiteId && `/sites/${activeSiteId}/edit` || undefined}
            >
              {t("navigationManageDomain")}
            </NavLink>
          </Fragment>}
        </div>
        {isSitesShown && <div className="app-sidebar__recent-sites">
          <div className="app-sidebar__subtitle">{t("recent")}</div>
          <div className="app-sidebar__links">
            {sites.slice(0, 5).map((site: CustomerSite) => {
              const Icon: React.FC<{}> = () => (
                <div className="app-sidebar__site-icon-wrapper">
                  <DomainIcon src={site.iconUrl} />
                </div>
              )
              return (
                <NavLink
                  activeSection={activeSiteId?.toString()}
                  icon={Icon}
                  key={site.id}
                  section={site.id.toString()}
                  to={`/sites/${site.hostname || site.id}/widgets`}
                >
                  {site.name || site.hostname}
                </NavLink>
              )
            })}
          </div>
        </div>}
      </Fragment>
    )
  }

  return (
    <CustomerLayout
      {...restProps}
      activeSection="domains"
      sidebarContent={<SidebarContent />}
    >
      {children}
    </CustomerLayout>
  )
}

export default DomainsLayout
