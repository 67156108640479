import React from "react"
import { FormSpy } from "react-final-form"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const TimerCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { t } = useTranslation()
  const { parameterTypes } = props

  return (
    <>
      <FormGroup visible={!!parameterTypes.countdownTarget}>
        <Field
          name="countdownTarget"
          prefix="parameters"
          type={parameterTypes.countdownTarget}
        />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.rotationEnabled}>
        <Field name="rotationEnabled" prefix="parameters" type={parameterTypes.rotationEnabled} />
      </FormGroup>
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const { values: { parameters: { rotationEnabled } } } = props
          if (!rotationEnabled) return null

          return (
            <FormGroup visible={!!parameterTypes.rotationInterval}>
              <Field name="rotationInterval" prefix="parameters" type={parameterTypes.rotationInterval} />
            </FormGroup>
          )
        }}
      </FormSpy>
      <FormGroup title={t("showConversionsForThePast")} visible={!!parameterTypes.conversionsPeriod}>
        <div className="period-choose">
          <Field name="conversionsPeriod" prefix="parameters" type={parameterTypes.conversionsPeriod} />
          <Field
            name="conversionsPeriodUnit"
            options={["minute", "hour", "day", "week", "month", "year"]}
            prefix="parameters"
            type={parameterTypes.conversionsPeriodUnit}
          />
        </div>
      </FormGroup>
    </>
  )
}

export default TimerCustomization
