import React, { useState } from "react"
import { useDebounce } from "../../utils/debounce"

interface SearchFieldProps {
  onChange: (value?: string | null) => void;
  initialValue?: string;
}

const SearchField: React.FC<SearchFieldProps> = (props: SearchFieldProps) => {
  const { initialValue, onChange } = props
  const [value, setValue] = useState(initialValue)

  useDebounce((): void => {
    if (value == initialValue) return;

    onChange(value)
  }, 1000, [value])

  return (
    <input
      className="filter filter-input"
      defaultValue={initialValue}
      onChange={(e: any): void => setValue(e.target.value)}
      type="text"
      placeholder="Search"
    />
  )
}

export default SearchField
