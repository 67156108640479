import React from "react"

interface StaticFieldProps {
  children?: React.ReactNode;
  label?: string;
}

const StaticField: React.FC<StaticFieldProps> = (props: StaticFieldProps) => {
  const { children, label } = props
  return (
    <div className="form-static-field">
      <div className="form-static-field__label">{label}</div>
      <div className="form-static-field__value">{children}</div>
    </div>
  )
}

export default StaticField
