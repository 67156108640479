/* eslint-disable no-prototype-builtins */

import React, { ReactNode } from "react"
import { isValidPhoneNumber } from "react-phone-number-input"
import { Form, FormRenderProps } from "react-final-form"
import { useTranslation } from "react-i18next"
import arrayMutators from "final-form-arrays"
import Paper from "../boxes/Paper.tsx"
import WidgetForm from "./WidgetForm"
import WidgetPreview from "./WidgetPreview"

interface WidgetBuilderProps {
  onSubmit: (attrs: CustomerWidget) => Promise<void>;
  templates: WidgetTemplate[];
  widget: CustomerWidget;
}

const initialValues = (widget: CustomerWidget, templates: WidgetTemplate[]): CustomerWidget => {
  const templateId: number = widget.templateId || templates[0]?.id || 0
  const result: CustomerWidget = { ... widget, templateId }
  const currentTemplate = templates.find(({ id }) => id === templateId)
  const defaultParameters = (currentTemplate?.defaultParameters || {}) as any
  const defaultTheme = (currentTemplate?.defaultTheme || {}) as any
  result.layout = widget.layout || currentTemplate?.defaultLayout || "default"
  result.name = widget.name || currentTemplate?.name || ""
  if (!widget.pagesToTrackConversions.length) result.pagesToTrackConversions = [""]
  result.pathnames = widget.pathnames || [""]
  result.parameters = { ...defaultParameters, ...(widget.parameters || {}) }
  result.theme = { ...defaultTheme, ...(widget.theme || {}) }
  return result
}

const WidgetBuilder: React.FC<WidgetBuilderProps> = (props: WidgetBuilderProps) => {
  const { onSubmit, templates, widget } = props
  const { t } = useTranslation()

  const validateForm = (widget: CustomerWidget): any => {
    const errors = {} as any
    if (widget.parameters.hasOwnProperty("phoneNumber") && !isValidPhoneNumber(`+${widget.parameters.phoneNumber}`)) {
      errors.parameters = errors.parameters || {}
      errors.parameters.phoneNumber = t("invalidPhoneNumber")
    }
    return errors
  }

  return (
    <Form
      initialValues={initialValues(widget, templates)}
      mutators={{...arrayMutators}}
      onSubmit={onSubmit}
      validate={validateForm}
      render={(renderProps: FormRenderProps<CustomerWidget>): ReactNode => {
        return (
          <div className="widget-builder">
            <div className="widget-builder__preview">
              <WidgetPreview />
            </div>
            <div className="widget-builder__form-wrapper">
              <Paper className="widget-builder__form">
                <WidgetForm
                  renderProps={renderProps}
                  templates={templates}
                  widget={widget}
                />
              </Paper>
            </div>
          </div>
        )
      }}
    />
  )
}

export default WidgetBuilder
