import React from "react"
import { statefulIcon } from "../../../utils/icons"

const LeadsIcon: React.FC<{}> = () => {
  return (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="11.2658" cy="5.32923" r="4.82923" stroke="#CDDFEC"/>
      <path d="M21.2511 23C21.2511 17.7021 16.7177 13.4074 11.1255 13.4074C5.53337 13.4074 1 17.7021 1 23" stroke="#CDDFEC" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

const LeadsActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="10.6315" cy="5" r="4.5" fill="#CDDFEC" stroke="#CDDFEC"/>
      <path d="M10.5 12.5791C6.33996 12.5791 2.80444 15.1123 1.51838 18.6392C0.950792 20.1958 2.34315 21.5791 4 21.5791H17C18.6569 21.5791 20.0492 20.1958 19.4816 18.6392C18.1956 15.1123 14.66 12.5791 10.5 12.5791Z" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

export default statefulIcon(LeadsIcon, LeadsActiveIcon)
