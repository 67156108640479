import React from "react"
import { FormSpy } from "react-final-form"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const PriceCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup visible={!!parameterTypes.price}>
        <Field name="price" prefix="parameters" type={parameterTypes.price} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.pricePeriod}>
        <Field name="pricePeriod" prefix="parameters" type={parameterTypes.pricePeriod} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.priceColor}>
        <Field initialValue="#000000" name="priceColor" prefix="theme" type={parameterTypes.priceColor} />
      </FormGroup>
      <FormGroup title={t("background")} visible={!!parameterTypes.priceBackgroundColor}>
        <Field initialValue="#ffe483" name="priceBackgroundColor" prefix="theme" type={parameterTypes.priceBackgroundColor} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.oldPriceEnabled}>
        <Field name="oldPriceEnabled" prefix="parameters" type={parameterTypes.oldPriceEnabled} />
      </FormGroup>
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const { values: { parameters: { oldPriceEnabled } } } = props

          if (!oldPriceEnabled) return null

          return (
            <>
              <FormGroup visible={!!parameterTypes.oldPrice}>
                <Field name="oldPrice" prefix="parameters" type={parameterTypes.oldPrice} />
              </FormGroup>
              <FormGroup title={t("color")} visible={!!parameterTypes.oldPriceColor}>
                <Field initialValue="#D33971" name="oldPriceColor" prefix="theme" type={parameterTypes.oldPriceColor} />
              </FormGroup>
              <FormGroup title={t("background")} visible={!!parameterTypes.oldPriceBackgroundColor}>
                <Field initialValue="#FFD3DE" name="oldPriceBackgroundColor" prefix="theme" type={parameterTypes.oldPriceBackgroundColor} />
              </FormGroup>
            </>
          )
        }}
      </FormSpy>
    </>
  )
}

export default PriceCustomization
