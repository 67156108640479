import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const IconIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { fillOpacity, backgroundColor, primaryColor, secondaryColor } = useStyles(props)

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke={primaryColor} strokeWidth="2"/>
      <path d="M16.4661 14.5379C16.0384 14.5379 15.6916 14.1879 15.6916 13.7562V13.0962H10.4423C9.33774 13.0962 8.44231 13.9916 8.44231 15.0962L8.44227 25.0962C8.44226 26.2008 9.3377 27.0962 10.4423 27.0962H15.6916V26.4362C15.6916 26.0045 16.0384 25.6545 16.4661 25.6545C16.4661 25.6545 16.5 25.5 16.4661 14.5379Z" fill={backgroundColor}/>
      <path d="M16.4661 14.5379C16.0384 14.5379 15.6916 14.1879 15.6916 13.7562V13.0962H10.4423C9.33774 13.0962 8.44231 13.9916 8.44231 15.0962L8.44227 25.0962C8.44226 26.2008 9.3377 27.0962 10.4423 27.0962H15.6916V26.4362C15.6916 26.0045 16.0384 25.6545 16.4661 25.6545C16.4661 25.6545 16.5 25.5 16.4661 14.5379Z" fill={secondaryColor} fillOpacity={fillOpacity}/>
      <path d="M29.0576 13.5962C29.8861 13.5962 30.5576 14.2678 30.5576 15.0962L30.5576 25.0962C30.5576 25.9246 29.886 26.5962 29.0576 26.5962H17.7405V26.4362C17.7405 25.7327 17.1742 25.1545 16.4661 25.1545C15.7579 25.1545 15.1916 25.7326 15.1916 26.4362V26.5962H10.4423C9.61384 26.5962 8.94227 25.9246 8.94227 25.0962L8.94231 15.0962C8.94231 14.2678 9.61388 13.5962 10.4423 13.5962H15.1916V13.7562C15.1916 14.4597 15.7579 15.0379 16.4661 15.0379C17.1742 15.0379 17.7406 14.4598 17.7406 13.7562V13.5962H29.0576Z" stroke={primaryColor}/>
    </svg>
  )
}

export default IconIcon
