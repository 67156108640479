import React  from "react"
import { Field, FormSpy } from "react-final-form"
import { useTranslation } from "react-i18next"
import ArrowLeftIcon from "../../images/icons/other/ArrowLeftIcon"
import Button from "../buttons/Button"
import ControlGroup from "../boxes/ControlGroup"
import Header from "../boxes/Header"
import TemplateCard from "../templates/TemplateCard"

interface WidgetsProps {
  confirmationText: string;
  onBack(id: number): void;
  templates: WidgetTemplate[];
}

const Widgets: React.FC<WidgetsProps> = (
  { confirmationText, onBack, templates }: WidgetsProps
) => {
  const { t } = useTranslation()

  return (
    <>
      <Header variant="main" text={t("SignUpStep1")} text2={t("selectWidgets")}/>
      <Field name="widgetTemplateIds">
        {(props: any): any => {
          const { meta } = props
          const error = meta.submitError && meta.submitError[0]
          if (!error) return null
          return (
            <div className="sign-up-form__base-error">{error}</div>
          )
        }}
      </Field>
      <ControlGroup gap="30px">
        <FormSpy subscription={{ values: true }}>
          {(props: any): any => {
            const {
              form: { mutators: { push, remove } },
              values: { widgetTemplateIds }
            } = props

            const handleTemplateToggle = (template: WidgetTemplate): void => {
              const { id } = template
              if (widgetTemplateIds.includes(id)) {
                remove("widgetTemplateIds", widgetTemplateIds.indexOf(id))
              } else {
                push("widgetTemplateIds", id)
              }
            }

            return (
              <div className="widgets">
                {templates.map(template => (
                  <TemplateCard
                    key={template.id}
                    onToggle={handleTemplateToggle}
                    selected={widgetTemplateIds.includes(template.id)}
                    template={template}
                  />
                ))}
              </div>
            )
          }}
        </FormSpy>
      </ControlGroup>
      <ControlGroup gap="30px">
        <Button secondary icon={ArrowLeftIcon} onClick={onBack} type="button">
          {t("Back")}
        </Button>
        <Button type="submit">
          {confirmationText}
        </Button>
      </ControlGroup>
    </>
  )
}

export default Widgets
