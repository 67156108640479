import React from "react"
import { RouteComponentProps } from "@reach/router"
import WidgetCard from "./WidgetCard"

interface WidgetListProps extends RouteComponentProps {
  availableTemplateIds: number[];
  onDelete: (id: number) => void;
  widgets: CustomerWidget[];
}

const WidgetList: React.FC<WidgetListProps> = (props: WidgetListProps) => {
  const { availableTemplateIds, onDelete, widgets } = props

  return (
    <div className="widgets">
      {widgets.map(w => (
        <WidgetCard
          isAllowedByBillingPlan={availableTemplateIds.includes(w.templateId)}
          key={w.id}
          onDelete={onDelete}
          widget={w}
        />
      ))}
    </div>
  )
}

export default WidgetList
