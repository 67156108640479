import React from "react"
import cn from "classnames"
import { Field, FieldRenderProps } from "react-final-form"

const renderField = (props: FieldRenderProps<number | string>): React.ReactNode => {
  const { className, input, label, meta, placeholder, readOnly } = props
  const error = meta.submitError && meta.submitError[0]
  const classNames = cn(`form-text-input form-text-input-${input.type}`, className, {
    "form-text-input--error": !!error
  })
  return (
    <label className={classNames}>
      {label && <div className="form-text-input__label"> {label} </div>}
      <div className="form-text-input__input">
        <input
          {...input}
          className="text-input"
          min={input.type === "number" ? 0 : undefined}
          placeholder={placeholder}
          readOnly={readOnly}
        />
        {error && <div className="form-text-input__error-text">{error}</div>}
      </div>
    </label>
  )
}

interface TextFieldProps {
  label?: string;
  format?: (value: any) => any;
  name: string;
  parse?: (value: any) => any;
  placeholder?: string;
  readOnly?: boolean;
  type: "number" | "text" | "email" | "password";
}

const identity = (value: any): any => value

const numberParse = (value: any): string => Number(value).toString()

const TextField: React.FC<TextFieldProps> = (props: TextFieldProps) => {
  return (
    <Field
      {...props}
      parse={props.parse || (props.type === "number" ? numberParse : identity)}
      render={renderField}
    />
  )
}

export default TextField
