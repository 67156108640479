import React from "react"
import { statefulIcon } from "../../../utils/icons"

const BillingIcon: React.FC<{}> = () => {
  return (
    <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1, 0)">
        <rect x="1" y="1" width="22" height="16" rx="2" stroke="#CDDFEC" strokeWidth="2"/>
        <line x1="1" y1="5.5" x2="23" y2="5.5" stroke="#CDDFEC"/>
        <line x1="4.5" y1="10.5" x2="9.5" y2="10.5" stroke="#CDDFEC" strokeLinecap="round"/>
        <line x1="4.5" y1="12.5" x2="13.5" y2="12.5" stroke="#CDDFEC" strokeLinecap="round"/>
      </g>
    </svg>
  )
}

const BillingActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g transform="translate(1, 0)">
        <rect x="1" y="1" width="22" height="16" rx="2" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
        <line x1="0.5" y1="5.5" x2="23.5" y2="5.5" stroke="white" strokeLinecap="round"/>
        <line x1="4.5" y1="10.5" x2="9.5" y2="10.5" stroke="white" strokeLinecap="round"/>
        <line x1="4.5" y1="12.5" x2="13.5" y2="12.5" stroke="white" strokeLinecap="round"/>
      </g>
    </svg>
  )
}

export default statefulIcon(BillingIcon, BillingActiveIcon)
