import req from "./jsonRequest"

interface BillingResponse {
  billing: BillingInfo;
}

interface CreatePaymentResponse {
  redirectUrl: string;
}

async function cancelSubscription(): Promise<BillingInfo> {
  return req.delete<BillingResponse>("/settings/billing.json")
    .then(response => response.data.billing)
}

async function get(): Promise<BillingInfo> {
  return req.get<BillingResponse>("/settings/billing.json")
    .then(response => response.data.billing)
}

async function createPayment(): Promise<any> {
  return req.post<CreatePaymentResponse>("/settings/payments.json")
    .then(response => response.data.redirectUrl)
}

async function undoSubscriptionCancel(): Promise<BillingInfo> {
  return req.patch<BillingResponse>("/settings/billing.json")
  .then(response => response.data.billing)
}

export default {
  cancelSubscription,
  get,
  createPayment,
  undoSubscriptionCancel,
}
