import React, { useState } from "react"
import cn from "classnames"
import { navigate } from "@reach/router"
import { useTranslation } from "react-i18next"
import Button from "../buttons/Button"
import DropdownMenu from "../menu/DropdownMenu"
import MoreIcon from "../../images/icons/other/MoreIcon"
import Paper from "../boxes/Paper"
import StateLabel from "../labels/StateLabel"
import StateSwitcher from "../switches/StateSwitcher"
import WidgetIcon from "../../images/icons/widgets/Icon"
import widgetApi from "../../api/widgets"

export interface WidgetCardProps {
  isAllowedByBillingPlan: boolean;
  onDelete: (id: number) => void;
  widget: CustomerWidget;
}

const WidgetCardMenuContent: React.FC<WidgetCardProps> = (props: WidgetCardProps) => {
  const { onDelete, widget } = props
  const { t } = useTranslation()
  const [isDeletionInitiated, setIsDeletionInitiated] = useState(false)

  if (!isDeletionInitiated) return (
    <>
      <Button secondary href={`/widgets/${widget.id}/edit`}>
        {t("edit")}
      </Button>
      <Button secondary onClick={(): void => setIsDeletionInitiated(true)}>
        {t("delete")}
      </Button>
    </>
  )

  return (
    <>
      <Button secondary onClick={(): void => onDelete(widget.id)}>
        {t("confirm")}
      </Button>
      <Button secondary onClick={(): void => setIsDeletionInitiated(false)}>
        {t("cancel")}
      </Button>
    </>
  )
}

const WidgetCard: React.FC<WidgetCardProps> = (props: WidgetCardProps) => {
  const { isAllowedByBillingPlan } = props
  const { t } = useTranslation()
  const [widget, setWidget] = useState(props.widget)
  const isActive = widget.state === "active"

  const handleStateChange = (isChecked: boolean): void => {
    const updatedWidget = { ...widget }
    updatedWidget.state = isChecked ? "active" : "disabled"
    setWidget(updatedWidget)
    widgetApi.save(updatedWidget).then(setWidget)
  }

  return (
    <Paper
      className={cn("card widget-card", { "card--inactive": !isAllowedByBillingPlan || !isActive })}
      onClick={(): void => { navigate(`/widgets/${widget.id}/edit`) } }
    >
      <div className="widget-card__state">
        {isAllowedByBillingPlan ? (
          <StateLabel active={isActive} />
        ) : (
          <div className="card__state card__state--forbidden">
            {t("notIncludedInYourBillingPlan")}
          </div>
        )}
      </div>
      {isAllowedByBillingPlan && <div className="widget-card__switch" onClick={(e): void => e.stopPropagation()}>
        <StateSwitcher value={widget.state === "active"} onChange={handleStateChange}/>
      </div>}
      <div className="widget-card__image">
        <WidgetIcon active={isAllowedByBillingPlan && isActive} templateSlug={widget.templateSlug} />
      </div>
      <div className="widget-card__title">
        {widget.name}
      </div>
      <div className="widget-card__more" onClick={(e): void => e.stopPropagation()}>
        <DropdownMenu expander={<Button noBackground rounded icon={MoreIcon} />} lockBackground>
          <WidgetCardMenuContent isAllowedByBillingPlan={isAllowedByBillingPlan} widget={widget} onDelete={props.onDelete} />
        </DropdownMenu>
      </div>
    </Paper>
  )
}

export default WidgetCard
