import React from "react"
import RadioGroup from "../../form-fields/RadioGroup"

const icons: string[] = [
  "pile-of-poo", "thumbs-down", "thumbs-up", "sign-of-the-horns", "ok-hand",
  "face-screaming-in-fear", "flushed-face", "fearful-face", "smiling-face-with-sunglasses", "face-savoring-food",
  "pouting-face", "sad-but-relieved-face", "nauseated-face", "drooling-face", "grimacing-face",
  "face-with-rolling-eyes", "speak-no-evil-monkey", "zipper-mouth-face", "winking-face-with-tongue", "face-with-tears-of-joy",
  "pouting-face", "sad-but-relieved-face", "neutral-face", "smiling-face", "smiling-face-with-heart-eyes",
]

interface IconSelectProps {
  name: string;
  type: string;
}

const IconSelect: React.FC<IconSelectProps> = (props: IconSelectProps) => {
  const renderOptionProps = (iconName: string): object => {
    const Icon = (): React.ReactNode => (
      <div className={`predefined-emoji predefined-emoji--${iconName}`} />
    )

    return { icon: Icon }
  }

  return (
    <RadioGroup
      {...props}
      className="widget-form__icons widget-form__emoji-picker"
      options={icons}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default IconSelect
