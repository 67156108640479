/* eslint-disable react/display-name */

import React from "react"
import { FormSpy } from "react-final-form"
import { CustomizationComponentProps } from "."
import EmojiSelect from "../shared/EmojiSelect"
import IconTabSelect from "../shared/IconTabSelect"
import FormGroup from "../../form-fields/FormGroup"

const iconFor = (iconName: string): any => {
  if (!iconName) return null

  return (): React.ReactNode => (
    <div className={`predefined-emoji predefined-emoji--${iconName}`} />
  )
}

const EmojiCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <>
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const {
            activeEmojiTab,
            parameters: { emoji1, emoji2, emoji3, emoji4, emoji5 }
          } = props.values

          const icons = {
            emoji1: iconFor(emoji1),
            emoji2: iconFor(emoji2),
            emoji3: iconFor(emoji3),
            emoji4: iconFor(emoji4),
            emoji5: iconFor(emoji5),
          }

          const iconSelectName = activeEmojiTab || "emoji1"
          return (
            <>
              <FormGroup visible={!!parameterTypes.emoji1}>
                <IconTabSelect
                  className="widget-form__emoji-tabs"
                  icons={icons}
                  initialValue="emoji1"
                  name="activeEmojiTab"
                  type="text"
                />
              </FormGroup>
              <FormGroup visible={!!parameterTypes[iconSelectName]}>
                <EmojiSelect
                  name={`parameters.${iconSelectName}`}
                  type={parameterTypes[iconSelectName]}
                />
              </FormGroup>
            </>
          )
        }}
      </FormSpy>
    </>
  )
}

export default EmojiCustomization
