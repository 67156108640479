import React from "react"
import TemplateCard from "./TemplateCard"

interface TemplatePickerProps {
  onSelect: (template: WidgetTemplate) => void;
  templates: WidgetTemplate[];
}

const TemplatePicker: React.FC<TemplatePickerProps> = (props: TemplatePickerProps) => {
  const { onSelect, templates } = props

  return (
    <div className="widgets">
      {templates.map(template => (
        <TemplateCard key={template.id} onToggle={onSelect} template={template} />
      ))}
    </div>
  )
}

export default TemplatePicker
