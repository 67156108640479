import React, { useEffect, useState } from "react"
import cn from 'classnames'
import { useAlert } from "react-alert"
import { useTranslation } from "react-i18next"
import AppStorage from "../../utils/storage"
import MainSidebarLayout from "./MainSidebarLayout"
import Button from "../buttons/Button"
import Header from "../boxes/Header"
import ViewIcon from "../../images/icons/other/ViewIcon"
import { processExceededLimits } from "../../utils/exceededLimitsHandlers"

interface LayoutProps {
  activeSection: string | null;
  headerContent?: React.ReactNode;
  headerIcon?: React.ReactNode;
  headerText?: string;
  headerSubText?: string;
  sidebarContent?: any;
}

type ViewMode = "COMPACT" | "HALF" | "FULL";

const CustomerLayout: React.FC<LayoutProps> = ({
  children, activeSection, headerContent, headerIcon, headerText, headerSubText,
  sidebarContent,
}) => {
  const { t } = useTranslation()
  const alert = useAlert()
  const [viewMode, setViewMode] = useState<ViewMode>(AppStorage.get("sidebarViewMode", "FULL"))
  const hasHighestBillingPlan = AppStorage.get("accountInfo", { highestPlan: false }).highestPlan

  useEffect(() => {
    processExceededLimits(alert)
  })

  const secondarySidebarClassName = cn("app-sidebar__secondary", {
    "app-sidebar__secondary-compact": viewMode === "COMPACT",
  })

  const applyViewMode = (sidebarViewMode: ViewMode): void => {
    AppStorage.set({ sidebarViewMode })
    setViewMode(sidebarViewMode)
  }

  const toggleViewMode = (): void => {
    switch(viewMode) {
      case "FULL": return applyViewMode("HALF")
      case "HALF": return applyViewMode("COMPACT")
      default: return applyViewMode("FULL")
    }
  }

  const isHeaderShown = headerText || headerSubText || headerContent

  return (
    <div className="app-layout">
      <div className="app-layout-mobile">{t("pleaseVisitFromYourPC")}</div>
      <div className="app-sidebar">
        <MainSidebarLayout viewMode={viewMode} activeSection={activeSection} />
        <div className={secondarySidebarClassName}>
          {sidebarContent}
          <div className="app-sidebar__upgrade">
            <div className="app-sidebar__upgrade-title">{t("Upgrade_title")}</div>
            <div className="app-sidebar__upgrade-description">{t("Upgrade_description")}</div>
            {hasHighestBillingPlan && (
              <Button engaging external className="app-sidebar__upgrade-button" href="https://pliek.io/contact/">
                {t("contactUs")}
              </Button>
            )}
            {!hasHighestBillingPlan && (
              <Button engaging className="app-sidebar__upgrade-button" href="/settings/billing">
                {t("upgrade")}
              </Button>
            )}
          </div>
        </div>
        <Button
          rounded
          secondary
          className="app-sidebar__toggle-view"
          icon={ViewIcon}
          onClick={toggleViewMode}
        />
      </div>
      <div className="app-layout__content">
        <main className="main-content">
          <div>
            {isHeaderShown && <Header icon={headerIcon} variant="main" text={headerText} text2={headerSubText}>
              {headerContent}
            </Header>}
            { children }
          </div>
        </main>
      </div>
    </div>
  )
}

export default CustomerLayout
