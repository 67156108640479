import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const HeadingIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.248 20C1.06133 20 0.912 19.944 0.8 19.832C0.688 19.72 0.632 19.5707 0.632 19.384V1.044C0.632 0.857332 0.688 0.707999 0.8 0.595999C0.912 0.465332 1.06133 0.399999 1.248 0.399999H1.752C1.95733 0.399999 2.116 0.455999 2.228 0.568C2.34 0.68 2.396 0.838666 2.396 1.044V9.22H13.204V1.044C13.204 0.857332 13.26 0.707999 13.372 0.595999C13.484 0.465332 13.6333 0.399999 13.82 0.399999H14.324C14.5293 0.399999 14.688 0.455999 14.8 0.568C14.912 0.68 14.968 0.838666 14.968 1.044V19.384C14.968 19.5707 14.9027 19.72 14.772 19.832C14.66 19.944 14.5107 20 14.324 20H13.82C13.6333 20 13.484 19.944 13.372 19.832C13.26 19.72 13.204 19.5707 13.204 19.384V10.9H2.396V19.384C2.396 19.5707 2.33067 19.72 2.2 19.832C2.088 19.944 1.93867 20 1.752 20H1.248ZM25.3335 20C25.1468 20 24.9975 19.944 24.8855 19.832C24.7735 19.72 24.7175 19.5707 24.7175 19.384V2.696L19.7055 6.56C19.5561 6.65333 19.4255 6.7 19.3135 6.7C19.1268 6.7 18.9681 6.616 18.8375 6.448L18.5295 6.056C18.4361 5.92533 18.3895 5.79467 18.3895 5.664C18.3895 5.47733 18.4735 5.31867 18.6415 5.188L24.6055 0.595999C24.7921 0.465332 25.0348 0.399999 25.3335 0.399999H25.8655C26.0521 0.399999 26.2015 0.455999 26.3135 0.568C26.4255 0.68 26.4815 0.829333 26.4815 1.016V19.384C26.4815 19.5707 26.4255 19.72 26.3135 19.832C26.2015 19.944 26.0521 20 25.8655 20H25.3335Z" fill={primaryColor}/>
    </svg>
  )
}

export default HeadingIcon
