import React from "react"
import { Field, FieldRenderProps } from "react-final-form"

const renderField = (props: FieldRenderProps<string>): React.ReactNode => {
  const { input: { value } } = props
  if (!value) return null
  const hints = (typeof value === "string") ? [value] : value
  if (!hints.length) return null
  return (
    <div className="form-hint">
      {hints.map((hint, index) => (
        <div
          className="form-hint__item"
          dangerouslySetInnerHTML={{ __html: hint }}
          key={index}
        />
      ))}
    </div>
  )
}

interface HintFieldProps {
  name: string;
}

const HintField: React.FC<HintFieldProps> = (props: HintFieldProps) => {
  return (
    <Field {...props} render={renderField} type="text"/>
  )
}

export default HintField
