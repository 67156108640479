import React from 'react'

interface DownloadLinkProps {
  to: string;
}

const DownloadLink: React.FC<DownloadLinkProps> = (props: DownloadLinkProps) => (
  <a download href={props.to} {...props} />
)

export default DownloadLink
