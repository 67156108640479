import React from "react"
import cn from "classnames"

interface FormGroupProps {
  children?: React.ReactNode;
  className?: string;
  title?: string;
  visible?: boolean;
}

const FormGroup: React.FC<FormGroupProps> = (props: FormGroupProps) => {
  const { children, className, title, visible = true } = props

  if (!visible) return null

  return (
    <div className={cn("form-group", className)}>
      {title && <div className="form-group__title">{title}</div>}
      {children && <div className="form-group__content">{children}</div>}
    </div>
  )
}

export default FormGroup
