import fieldsMapping from "./fieldsMapping"

export const getCustomizationOptions = (parameterTypes: WidgetParameterTypes): WidgetParameterCategory[] => {
  const fields = Object.keys(parameterTypes)
  const categories = Object.keys(fieldsMapping)

  return categories.filter(key => {
    const categoryFields = (fieldsMapping as any)[key]
    return fields.some(field => categoryFields.includes(field))
  }) as WidgetParameterCategory[]
}

const additionalFields = (parameterTypes: WidgetParameterTypes): string[] => {
  const fields = Object.keys(parameterTypes)
  const groupedFields = Object.values(fieldsMapping).reduce((memo, v) => [...memo, ...v], [])

  return fields.filter(f => !groupedFields.includes(f))
}

export const getAdditionalFieldTypes = (parameterTypes: WidgetParameterTypes): WidgetParameterTypes => {
  return additionalFields(parameterTypes).reduce((memo, key) => (
    { ...memo, [key]: parameterTypes[key] }
  ), {})
}
