import React  from "react"
import { useTranslation } from "react-i18next"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ArrowLeftIcon from "../../images/icons/other/ArrowLeftIcon"
import Button from "../buttons/Button"
import ControlGroup from "../boxes/ControlGroup"
import Paper from "../boxes/Paper"

interface DashboardReadyProps {
  name: string;
  onBack(id: number): void;
}

const DashboardReady: React.FC<DashboardReadyProps> = ({ name, onBack }: DashboardReadyProps) => {
  const { t } = useTranslation()
  return (
    <Paper className="sign-up-ready">
      <CheckCircleIcon/>
      <p>
        The dashboard for {name} is ready!
      </p>
      <ControlGroup>
        <Button secondary icon={ArrowLeftIcon} onClick={onBack} type="button">
          {t("Back")}
        </Button>
        <Button>Go to dashboard</Button>
      </ControlGroup>
    </Paper>
  )
}

export default DashboardReady
