import React from "react"
import { FormSpy } from "react-final-form"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FontCustomization from "../shared/FontCustomization"
import FormGroup from "../../form-fields/FormGroup"

const CheckboxCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <>
      <FormGroup visible={!!parameterTypes.checkboxEnabled}>
        <Field name="checkboxEnabled" prefix="parameters" type={parameterTypes.checkboxEnabled} />
      </FormGroup>
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const { values: { parameters: { checkboxEnabled } } } = props

          if (!checkboxEnabled) return null

          return (
            <>
              <FormGroup visible={!!parameterTypes.agreementText}>
                <Field name="agreementText" prefix="parameters" type={parameterTypes.agreementText} />
              </FormGroup>
              <FormGroup visible={!!parameterTypes.checkboxFontSize}>
                <FontCustomization namePrefix="checkbox" parameterTypes={parameterTypes} />
              </FormGroup>
              <FormGroup visible={!!parameterTypes.checkboxColor}>
                <Field initialValue="#000000" name="checkboxColor" prefix="theme" type={parameterTypes.checkboxColor} />
              </FormGroup>
              <FormGroup visible={!!parameterTypes.altCheckboxColor}>
                <Field initialValue="#ffffff" name="altCheckboxColor" prefix="theme" type={parameterTypes.altCheckboxColor} />
              </FormGroup>
            </>
          )
        }}
      </FormSpy>
    </>
  )
}

export default CheckboxCustomization
