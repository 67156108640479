import { AxiosResponse } from "axios"
import { camelizeKeys } from "humps"

export async function handleFormErrors(error: any): Promise<any> {
  const response: AxiosResponse | undefined = error.response
  if (response && response.status >= 400 && response.status < 500 && response.data.errors) {
    return Promise.reject(camelizeKeys(response.data.errors))
  }
  console.log(error)
  return Promise.reject(error)
}
