import React from "react"
import ReactPaginate from "react-paginate"
import Select from "./Select"

interface PageChangeProps {
  selected: number;
}

interface PaginationProps {
  onPageChange: (value: number) => void;
  onPerPageChange: (value: number) => void;
  page: number;
  perPage: number;
  totalPages: number;
}

const Pagination: React.FC<PaginationProps> = (props: PaginationProps) => {
  const { onPageChange, onPerPageChange, page, perPage, totalPages } = props

  const handlePageChange = (props: PageChangeProps): void => {
    const { selected } = props
    onPageChange(selected + 1)
  }

  const handlePerPageSelect = (value: number): void => {
    onPerPageChange(value)
  }

  return (
    <div className="pagination-wrapper">
      <ReactPaginate
        activeLinkClassName="button pagination__link--active"
        breakLabel="..."
        breakClassName="pagination__button-wrapper"
        containerClassName="pagination"
        disabledClassName="pagination__link--disabled"
        disableInitialCallback={true}
        forcePage={page - 1}
        initialPage={page - 1}
        marginPagesDisplayed={1}
        nextLabel=">"
        nextLinkClassName="button button--secondary"
        onPageChange={handlePageChange}
        pageCount={totalPages}
        pageLinkClassName="button button--secondary"
        pageRangeDisplayed={3}
        previousLinkClassName="button button--secondary"
        previousLabel="<"
      />
      <Select
        onChange={handlePerPageSelect}
        options={[25, 50, 100, 250].map(n => ({ name: `${n} per page`, value: n }))}
        selectedValue={parseInt((perPage || "").toString()) || 25}
      />
    </div>
  )
}

export default Pagination
