import React, { useEffect, useState } from "react"
import { FormSpy } from "react-final-form"
import cn from "classnames"
import { useTranslation } from "react-i18next"
import ArrowLeftIcon from "../../images/icons/other/ArrowLeftIcon"
import Button from "../buttons/Button"
import ControlGroup from "../boxes/ControlGroup"
import Header from "../boxes/Header"
import Paper from "../boxes/Paper"
import TextField from "../form-fields/TextField"
import couponApi from "../../api/coupons"

interface PlanProps {
  plan: BillingPlan;
  discount?: number | null;
}

interface PlanCardProps extends PlanProps {
  onClick(id: number): void;
}

interface PlanCardsProps {
  existingCoupon?: CouponCode | null;
  canApplyCoupon?: boolean;
  onBack?: (id: number) => void;
  onClick(id: number, couponCode?: string | null): void;
  plans: BillingPlan[];
}

const PlanLabel: React.FC<PlanProps> = ({ plan }: PlanProps) => {
  if (!plan.mostPopular && !plan.perWidget) return null
  const className = cn("sign-up-plan__label", {
    "sign-up-plan__label--popular": plan.mostPopular,
    "sign-up-plan__label--per-widget": plan.perWidget,
  })
  return (
    <div className={className}>
      {plan.mostPopular ? "Most popular" : "Pay per widget"}
    </div>
  )
}

const PlanName: React.FC<PlanProps> = ({ plan }: PlanProps) => {
  return (
    <div className="sign-up-plan__name">{plan.name}</div>
  )
}

const PlanPrice: React.FC<PlanProps> = ({ discount, plan }: PlanProps) => {
  return (
    <>
      <div className="sign-up-plan__price">
        <span className="sign-up-plan__price-currency">{plan.priceCurrency}</span>
        {discount && discount > 0 && <>
          {plan.priceValue - (discount / 100)}
          <div className="sign-up-plan__old-price">{plan.priceCurrency}{plan.priceValue}</div>
        </> || plan.priceValue}
      </div>
      <div className="sign-up-plan__price-description">{plan.priceDescription}</div>
    </>
  )
}

const PlanDescription: React.FC<PlanProps> = ({ plan }: PlanProps) => {
  return (
    <ul className="sign-up-plan__description">
      {plan.descriptionLines.map((line, i) => {
        const className = cn(
          "sign-up-plan__description-line",
          { [`sign-up-plan__description-line--${line.icon}`]: line.icon }
        )
        return <li className={className} key={i}>{line.text}</li>
      })}
    </ul>
  )
}

const PlanCard: React.FC<PlanCardProps> = ({ discount, onClick, plan }: PlanCardProps) => {
  const { t } = useTranslation()
  return (
    <Paper className="sign-up-plan">
      <PlanLabel plan={plan}/>
      <PlanName plan={plan}/>
      <PlanPrice plan={plan} discount={discount}/>
      <PlanDescription plan={plan}/>
      <Button engaging={plan.mostPopular} onClick={(): void => onClick(plan.id)} type="button">
        {t("Choose")} {plan.name}
      </Button>
    </Paper>
  )
}

const PlanCards: React.FC<PlanCardsProps> = ({ existingCoupon, canApplyCoupon, onBack, onClick, plans }: PlanCardsProps) => {
  const { t } = useTranslation()
  const lifetimePlans = plans.filter(p => p.isLifetime)
  const restPlans = plans.filter(p => !p.isLifetime)

  return (
    <>
      {!!canApplyCoupon && <>
        <Header variant="main" text={t("ApplyCoupon")}/>
        <Paper className="sign-up-coupons">
          <ControlGroup maxWidth="480px">
            <TextField type="text" name="couponCode" label="Coupon code" />
          </ControlGroup>
        </Paper>
      </>}
      <FormSpy subscription={{ values: true }}>
        {(props: any): any => {
          const { values: { couponCode } } = props
          const [coupon, setCoupon] = useState<CouponCode | null>(existingCoupon || null)

          useEffect(() => {
            couponApi.getCouponCode(couponCode).then(setCoupon)
          }, [couponCode])

          return (
            <>
              {!!restPlans.length && <>
                <Header variant="main" text={t("SignUpStep1")}/>
                <div className="sign-up-plans">
                  {restPlans.map(plan => (
                    <PlanCard
                      key={plan.id}
                      onClick={(id: number): void => onClick(id, couponCode)}
                      plan={plan}
                      discount={coupon?.billingPlanId === plan.id ? coupon?.discountCents : null}
                    />
                  ))}
                </div>
              </>}
              {!!lifetimePlans.length && <>
                <Header variant="main" text={t("LifetimeDeals")}/>
                <div className="sign-up-plans">
                  {lifetimePlans.map(plan => (
                    <PlanCard
                      key={plan.id}
                      onClick={(id: number): void => onClick(id, couponCode)}
                      plan={plan}
                      discount={coupon?.billingPlanId === plan.id ? coupon?.discountCents : null}
                    />
                  ))}
                </div>
              </>}
            </>
          )
        }}
      </FormSpy>
      {onBack && <ControlGroup gap="30px">
        <Button secondary icon={ArrowLeftIcon} onClick={onBack} type="button">
          {t("Back")}
        </Button>
      </ControlGroup>}
    </>
  )
}

export default PlanCards
