/* eslint-disable @typescript-eslint/camelcase */

import React from "react"
import { useTranslation } from "react-i18next"
import RadioGroup from "./RadioGroup"

import AvatarIcon from "../../images/icons/builder/style-tabs/AvatarIcon"
import ButtonIcon from "../../images/icons/builder/style-tabs/ButtonIcon"
import CheckboxIcon from "../../images/icons/builder/style-tabs/CheckboxIcon"
import CouponIcon from "../../images/icons/builder/style-tabs/CouponIcon"
import EmojiIcon from "../../images/icons/builder/style-tabs/EmojiIcon"
import HeadingIcon from "../../images/icons/builder/style-tabs/HeadingIcon"
import IconIcon from "../../images/icons/builder/style-tabs/IconIcon"
import ImageIcon from "../../images/icons/builder/style-tabs/ImageIcon"
import InputIcon from "../../images/icons/builder/style-tabs/InputIcon"
import ListIcon from "../../images/icons/builder/style-tabs/ListIcon"
import MainTextIcon from "../../images/icons/builder/style-tabs/MainTextIcon"
import PriceIcon from "../../images/icons/builder/style-tabs/PriceIcon"
import SubtitleIcon from "../../images/icons/builder/style-tabs/SubtitleIcon"
import TextareaIcon from "../../images/icons/builder/style-tabs/TextareaIcon"
import TimerIcon from "../../images/icons/builder/style-tabs/TimerIcon"
import VideoIcon from "../../images/icons/builder/style-tabs/VideoIcon"
import WidgetDetailsIcon from "../../images/icons/builder/style-tabs/WidgetDetailsIcon"

const icons: any = {
  avatar: AvatarIcon,
  button: ButtonIcon,
  cancel_button: ButtonIcon,
  checkbox: CheckboxIcon,
  coupon_codes: CouponIcon,
  emoji: EmojiIcon,
  heading: HeadingIcon,
  icon: IconIcon,
  image: ImageIcon,
  input: InputIcon,
  inputs: InputIcon,
  list_with_icon: ListIcon,
  main_text: MainTextIcon,
  price: PriceIcon,
  subtitle: SubtitleIcon,
  textarea: TextareaIcon,
  timer: TimerIcon,
  video: VideoIcon,
  widget_details: WidgetDetailsIcon,
}

interface StyleTabSelectProps {
  name: string;
  tabs: WidgetParameterCategory[];
}

const StyleTabSelect: React.FC<StyleTabSelectProps> = (props: StyleTabSelectProps) => {
  const { t } = useTranslation()
  const { tabs, ...restProps } = props

  const renderOptionProps = (tabName: string): object => ({
    icon: icons[tabName],
    label: t(`Style_${tabName}`),
  })

  return (
    <RadioGroup
      {...restProps}
      className="widget-form__layouts"
      options={tabs}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default StyleTabSelect
