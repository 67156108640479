import React from "react"
import { AlertComponentProps } from "react-alert"

const AlertTemplate: React.FC<AlertComponentProps> = (props: AlertComponentProps) => {
  const { message, close } = props

  return (
    <div className="alert">
      <div className="alert__content">{message}</div>
      <button className="alert__close" onClick={close}>x</button>
    </div>
  )
}

export default AlertTemplate
