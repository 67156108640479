import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const InputIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="52" height="25" viewBox="0 0 52 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.7059 4H49C50.1046 4 51 4.89543 51 6V19C51 20.1046 50.1046 21 49 21H14.7059M8.35294 4H3C1.89543 4 1 4.89543 1 6V19C1 20.1046 1.89543 21 3 21H8.35294" stroke={primaryColor} strokeWidth="2" strokeLinecap="round"/>
      <line y1="-0.5" x2="25" y2="-0.5" transform="matrix(4.37114e-08 1 1 -4.37114e-08 12 0)" stroke={primaryColor}/>
      <line y1="-0.5" x2="3" y2="-0.5" transform="matrix(1 0 0 -1 10 24)" stroke={primaryColor}/>
      <line y1="-0.5" x2="3" y2="-0.5" transform="matrix(1 0 0 -1 10 0)" stroke={primaryColor}/>
    </svg>
  )
}

export default InputIcon
