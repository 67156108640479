import React from "react"
import RadioGroup from "../../form-fields/RadioGroup"

import Avatar1 from "../../../../assets/images/icons/avatars/avatar-1.svg"
import Avatar2 from "../../../../assets/images/icons/avatars/avatar-2.svg"
import Avatar3 from "../../../../assets/images/icons/avatars/avatar-3.svg"
import Avatar4 from "../../../../assets/images/icons/avatars/avatar-4.svg"

const icons: any = {
  "avatar-1": Avatar1,
  "avatar-2": Avatar2,
  "avatar-3": Avatar3,
  "avatar-4": Avatar4,
}

interface AvatarSelectProps {
  name: string;
  type: string;
}

const AvatarSelect: React.FC<AvatarSelectProps> = (props: AvatarSelectProps) => {
  const renderOptionProps = (iconName: string): object => {
    const Avatar = (): React.ReactNode => <img src={icons[iconName]} />

    return { icon: Avatar }
  }

  return (
    <RadioGroup
      {...props}
      className="widget-form__avatars"
      options={Object.keys(icons)}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default AvatarSelect
