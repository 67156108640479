import React from "react"
import cn from "classnames"
import Box, { BoxProps } from "./Box"

const ControlGroup: React.FC<BoxProps> = (props: BoxProps) => {
  return (
    <Box {...props} className={cn("control-group", props.className)}/>
  )
}

export default ControlGroup
