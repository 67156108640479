import React, { Children, cloneElement } from "react"
import cn from "classnames"
import LinkButton from "../buttons/LinkButton"

interface OrderButtonProps {
  activeOrder?: string;
  by: string;
  children: React.ReactNode;
  className?: string;
  onChange?: (value: any) => void;
}

export const OrderButton: React.FC<OrderButtonProps> = (props: OrderButtonProps) => {
  const { activeOrder, by, children, className, onChange } = props

  const handleReorder = (): void => {
    onChange && onChange(activeOrder === by ? `-${by}` : by)
  }

  const buttonClassName = cn({
    "button-link--inactive": activeOrder !== by && activeOrder !== `-${by}`,
    "order-link--reversed": activeOrder === `-${by}`,
  })

  return (
    <div className={className}>
      <LinkButton className={buttonClassName} onClick={handleReorder}>
        {children}
        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1L6 6L11 1" stroke="#BAD0E0" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </LinkButton>
    </div>
  )
}

interface OrderPanelProps {
  activeOrder?: string;
  children: any;
  className?: string;
  onChange: (value: any) => void;
}

const OrderPanel: React.FC<OrderPanelProps> = (props: OrderPanelProps) => {
  const { activeOrder, children, className, onChange } = props

  return (
    <div className={className}>
      {children && Children.map(children, child =>
        cloneElement(child, { activeOrder, onChange })
      )}
    </div>
  )
}

export default OrderPanel
