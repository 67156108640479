import React, { useEffect, useRef, useState } from "react"

interface DropdownMenuProps {
  children?: React.ReactNode;
  expander?: React.ReactNode;
  lockBackground?: boolean;
}

const DropdownMenu: React.FC<DropdownMenuProps> = (props: DropdownMenuProps) => {
  const { children, lockBackground } = props
  const refContainer = useRef<HTMLDivElement | null>(null)
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = (): void => setIsExpanded(!isExpanded)

  const handleClick = (e: any): void => {
    if (refContainer.current && !refContainer.current.contains(e.target)) {
      toggleExpanded()
    }
  }

  useEffect(() => {
    if (isExpanded) {
      document.addEventListener('click', handleClick)

      return (): void => document.removeEventListener('click', handleClick)
    }
  }, [isExpanded])

  return (
    <>
      {isExpanded && lockBackground && <div className="dropdown-menu__bg"/>}
      <div className="dropdown-menu" ref={refContainer}>
        <div className="dropdown-menu__expander" onClick={toggleExpanded}>
          {props.expander}
        </div>
        {isExpanded && <div className="dropdown-menu__content">
          {children}
        </div>}
      </div>
    </>
  )
}

export default DropdownMenu
