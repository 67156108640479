import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const AvatarIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { primaryColor } = useStyles(props)

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="19" stroke={primaryColor} strokeWidth="2"/>
      <path d="M20 23.5C23.0813 23.5 25.5 20.7678 25.5 17.5C25.5 14.2322 23.0813 11.5 20 11.5C16.9187 11.5 14.5 14.2322 14.5 17.5C14.5 20.7678 16.9187 23.5 20 23.5Z" stroke={primaryColor}/>
      <path d="M8 34.9133C9.96124 29.6785 14.5955 26 20 26C25.4045 26 30.0388 29.6785 32 34.9133" stroke={primaryColor}/>
    </svg>
  )
}

export default AvatarIcon
