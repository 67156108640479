import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import SignUp from "../components/sign-up"
import plansApi from "../api/billingPlans"
import templatesApi from "../api/templates"
import usersApi from "../api/users"
import illustration from "../images/sign-up.svg"

const SignUpPage: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const [plans, setPlans] = useState<BillingPlan[]>([])
  const [templates, setTemplates] = useState<WidgetTemplate[]>([])

  useEffect(() => {
    plansApi.getAll().then(setPlans)
    templatesApi.getAll().then(setTemplates)
  }, [])

  return (
    <div className="sign-up-container">
      <img className="sign-up-container__illustration" src={illustration}/>
      <SignUp state={props.location?.state} onSubmit={usersApi.signUp} plans={plans} templates={templates}/>
    </div>
  )
}

export default SignUpPage
