import React from "react"
import { statefulIcon } from "../../../utils/icons"

const AccountIcon: React.FC<{}> = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1C6.38293 1 1 6.38293 1 13C1 19.6171 6.38293 25 13 25C19.6171 25 25 19.6171 25 13C25 6.38293 19.6171 1 13 1Z" stroke="#CDDFEC" strokeWidth="2"/>
      <circle cx="13" cy="11" r="3.5" stroke="#CDDFEC"/>
      <path d="M21 23C21 18.5817 17.4183 15 13 15C8.58173 15 5 18.5817 5 23" stroke="#CDDFEC" strokeLinecap="round"/>
    </svg>
  )
}

const AccountActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 1C6.38293 1 1 6.38293 1 13C1 19.6171 6.38293 25 13 25C19.6171 25 25 19.6171 25 13C25 6.38293 19.6171 1 13 1Z" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
      <circle cx="13" cy="11" r="3.5" stroke="white"/>
      <path d="M21 23C21 18.5817 17.4183 15 13 15C8.58173 15 5 18.5817 5 23" stroke="white" strokeLinecap="round"/>
    </svg>
  )
}

export default statefulIcon(AccountIcon, AccountActiveIcon)
