import React from "react"
import { ColorResult, SketchPicker as Picker } from "react-color"
import { useTranslation } from "react-i18next"
import { Field, FieldRenderProps } from "react-final-form"
import DropdownMenu from "../menu/DropdownMenu"

const colors = [
  "#000000", "#232323", "#606060", "#888888", "#B9B9B9", "#EBEBEB",
  "#5869FA", "#7280FB", "#939DFC", "#A6AFFD", "#BDC4FE", "#DCE0FF",
  "#E63535", "#FB6E45", "#FB924E", "#FBBD64", "#FFDE6A", "#FFE483",
  "#219447", "#47AF4E", "#63C253", "#84D16E", "#ACE199", "#DDF4CE",
  "#D33971", "#DD5F95", "#CE91C3", "#E1A4BE", "#F4B5BD", "#FCC8BB",
  "#834AF9", "#9056F3", "#9B60EE", "#AC77F0", "#BD90F6", "#D3B1FE",
]

const renderField = (props: FieldRenderProps<string>): React.ReactNode => {
  const { input } = props
  const { t } = useTranslation()

  const onChangeComplete = (color: ColorResult): void => {
    let value = color.hex
    const alpha = color.rgb?.a || 100
    if (alpha < 100) value += Math.round(alpha * 255).toString(16)
    input.onChange(value)
  }

  return (
    <div className="color-picker">
      <div className="color-picker__preview" style={{ backgroundColor: input.value }} />
      <input {...input} className="text-input color-picker__input"/>
      <DropdownMenu expander={<span className="color-picker__expander">{t("palette")}</span>}>
        <Picker
          color={input.value}
          onChangeComplete={onChangeComplete}
          presetColors={colors}
          width="auto"
        />
      </DropdownMenu>
    </div>
  )
}

export interface ColorFieldProps {
  initialValue?: string;
  name: string;
}

const ColorField: React.FC<ColorFieldProps> = (props: ColorFieldProps) => {
  return (
    <Field
      {...props}
      render={renderField}
      type="text"
    />
  )
}

export default ColorField
