import React from "react"
import { StatefulIconProps, useStyles } from "../../../../utils/icons"

const WidgetDetailsIcon: React.FC<StatefulIconProps> = (props: StatefulIconProps) => {
  const { fillOpacity, primaryColor, secondaryColor } = useStyles(props)

  return (
    <svg width="31" height="39" viewBox="0 0 31 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5585 22.0384L12.0752 31.5357C11.1714 33.1011 9.16984 33.6374 7.6045 32.7337C6.03917 31.8299 5.50284 29.8283 6.40659 28.263L10.0004 22.0384L17.5585 22.0384Z" fill={secondaryColor} fillOpacity={fillOpacity}/>
      <path d="M6.40631 31.5358L7.27234 32.0358L7.56072 31.5363L7.27278 31.0365L6.40631 31.5358ZM5.77828 32.6235L4.91226 32.1235L5.77828 32.6235ZM8.61257 34.2599L7.74655 33.7599L8.61257 34.2599ZM9.24052 33.1723L9.23973 32.1723L8.66291 32.1727L8.3745 32.6723L9.24052 33.1723ZM20.7729 5.3797C21.4005 4.29266 22.7905 3.92021 23.8775 4.54781L24.8775 2.81576C22.8339 1.63587 20.2207 2.33607 19.0408 4.3797L20.7729 5.3797ZM7.27274 28.7626L20.7729 5.3797L19.0408 4.3797L5.54069 27.7626L7.27274 28.7626ZM7.27278 31.0365C6.8795 30.3539 6.85277 29.49 7.27274 28.7626L5.54069 27.7626C4.74934 29.1333 4.80481 30.7593 5.53984 32.035L7.27278 31.0365ZM6.64431 33.1235L7.27234 32.0358L5.54029 31.0358L4.91226 32.1235L6.64431 33.1235ZM6.87724 33.9928C6.57286 33.8171 6.46857 33.4279 6.64431 33.1235L4.91226 32.1235C4.18424 33.3845 4.61628 34.9969 5.87724 35.7249L6.87724 33.9928ZM7.74655 33.7599C7.57082 34.0643 7.18161 34.1686 6.87724 33.9928L5.87724 35.7249C7.1382 36.4529 8.75058 36.0209 9.4786 34.7599L7.74655 33.7599ZM8.3745 32.6723L7.74655 33.7599L9.4786 34.7599L10.1065 33.6723L8.3745 32.6723ZM11.2093 31.0354C10.7893 31.7628 10.0276 32.1717 9.23973 32.1723L9.24131 34.1723C10.7138 34.1711 12.1499 33.4062 12.9413 32.0354L11.2093 31.0354ZM24.7094 7.65246L11.2093 31.0354L12.9413 32.0354L26.4414 8.65246L24.7094 7.65246ZM23.8775 4.54781C24.9645 5.17542 25.337 6.56542 24.7094 7.65246L26.4414 8.65246C27.6213 6.60883 26.9211 3.99565 24.8775 2.81576L23.8775 4.54781Z" fill={primaryColor}/>
      <path d="M8.79873 29.4376C8.97532 29.1317 9.36641 29.0269 9.67226 29.2035C9.97811 29.3801 10.0829 29.7712 9.90632 30.077L7.78104 33.7581C7.60446 34.064 7.21337 34.1688 6.90752 33.9922C6.60167 33.8156 6.49688 33.4245 6.67346 33.1187L8.79873 29.4376Z" fill={secondaryColor} fillOpacity={fillOpacity}/>
      <path d="M24.6969 12.9465C25.6318 13.4862 25.9521 14.6816 25.4124 15.6164C24.8726 16.5513 23.6772 16.8716 22.7424 16.3319L20.6167 15.1046L18.4909 13.8773L16.3652 12.65L14.2395 11.4227C13.3046 10.883 12.9843 9.68759 13.5241 8.75273C14.0638 7.81788 15.2592 7.49757 16.1941 8.03731L24.6969 12.9465Z" fill="white" stroke={primaryColor}/>
      <line x1="10.0004" y1="22.5" x2="17.0004" y2="22.5" stroke={primaryColor}/>
    </svg>
  )
}

export default WidgetDetailsIcon
