import { decamelizeKeys } from "humps"
import { AxiosResponse } from "axios"
import req from "./jsonRequest"
import { handleFormErrors } from "./utils"

interface SiteIndexResponse {
  sites: CustomerSite[];
}

interface SiteResponse {
  site: CustomerSite;
}

interface SiteMessagesQueryProps {
  page?: number;
  search?: string;
  since?: string;
  until?: string;
}

interface SiteStatisticsQueryProps {
  since?: string;
  until?: string;
}

function siteFromResponse(response: AxiosResponse<SiteResponse>): CustomerSite {
  return response.data.site
}

async function destroy(siteId: number | string): Promise<void> {
  return req.delete(`/sites/${siteId}.json`)
}

async function getAll(): Promise<CustomerSite[]> {
  return req.get<SiteIndexResponse>("/sites.json")
    .then(response => response.data.sites)
    .catch(() => [])
}

async function getNew(): Promise<CustomerSite | null> {
  return req.get<SiteResponse>("/sites/new.json")
    .then(siteFromResponse)
    .catch(() => null)
}

async function getOneWithMessages(siteId: string | undefined, params: SiteMessagesQueryProps): Promise<CustomerSite | null> {
  if (!siteId) return null
  return req.get<SiteResponse>(`/sites/${siteId}/leads.json`, { params })
    .then(siteFromResponse)
    .catch(() => null)
}

async function getOneWithStatistics(siteId: string | undefined, params: SiteStatisticsQueryProps): Promise<CustomerSite | null> {
  if (!siteId) return null
  return req.get<SiteResponse>(`/sites/${siteId}/statistics.json`, { params })
    .then(siteFromResponse)
    .catch(() => null)
}

async function getOneWithWidgets(siteId: string | undefined): Promise<CustomerSite | null> {
  if (!siteId) return null
  return req.get<SiteResponse>(`/sites/${siteId}.json`)
    .then(siteFromResponse)
    .catch(() => null)
}

async function save(site: CustomerSite): Promise<CustomerSite> {
  const data = { site: decamelizeKeys(site) }
  const responsePromise = site.id
    ? req.patch<SiteResponse>(`/sites/${site.id}.json`, data)
    : req.post<SiteResponse>("/sites.json", data)
  return responsePromise.then(siteFromResponse).catch(handleFormErrors)
}

export default {
  destroy,
  getAll,
  getNew,
  getOneWithMessages,
  getOneWithStatistics,
  getOneWithWidgets,
  save,
}
