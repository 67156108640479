import React from "react"
import { useTranslation } from "react-i18next"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FontCustomization from "../shared/FontCustomization"
import FormGroup from "../../form-fields/FormGroup"

const CancelButtonCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props
  const { t } = useTranslation()

  return (
    <>
      <FormGroup visible={!!parameterTypes.cancelButtonText}>
        <Field name="cancelButtonText" prefix="parameters" type={parameterTypes.cancelButtonText} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.cancelButtonFontSize}>
        <FontCustomization namePrefix="cancelButton" parameterTypes={parameterTypes} />
      </FormGroup>
      <FormGroup title={t("text")} visible={!!parameterTypes.cancelButtonTextColor}>
        <Field initialValue="#93b1c7" name="cancelButtonTextColor" prefix="theme" type={parameterTypes.cancelButtonTextColor} />
      </FormGroup>
      <FormGroup title={t("button")} visible={!!parameterTypes.cancelButtonBackgroundColor}>
        <Field initialValue="#5869fa" name="cancelButtonBackgroundColor" prefix="theme" type={parameterTypes.cancelButtonBackgroundColor} />
      </FormGroup>
    </>
  )
}

export default CancelButtonCustomization
