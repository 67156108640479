import React from "react"
import { statefulIcon } from "../../../utils/icons"

const ListIcon: React.FC<{}> = () => {
  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="5.5" y1="7.5" x2="15.5" y2="7.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="5.5" y1="12.5" x2="15.5" y2="12.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <line x1="5.5" y1="17.5" x2="15.5" y2="17.5" stroke="#CDDFEC" strokeLinecap="round"/>
      <rect x="1" y="1" width="19" height="23" rx="2" stroke="#CDDFEC" strokeWidth="2"/>
    </svg>
  )
}

const ListActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="19" height="23" rx="2" fill="#CDDFEC" stroke="#CDDFEC" strokeWidth="2"/>
      <line x1="5.5" y1="7.5" x2="15.5" y2="7.5" stroke="white" strokeLinecap="round"/>
      <line x1="5.5" y1="12.5" x2="15.5" y2="12.5" stroke="white" strokeLinecap="round"/>
      <line x1="5.5" y1="17.5" x2="15.5" y2="17.5" stroke="white" strokeLinecap="round"/>
    </svg>
  )
}

export default statefulIcon(ListIcon, ListActiveIcon)
