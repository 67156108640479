/* eslint-disable @typescript-eslint/camelcase */

const mapping = {
  avatar: [
    // TODO: consider showing these options inside icon styles
    // "avatarBackgroundColor",
    "avatarColor",
    "avatarType",
    "avatarValue",
  ],
  button: [
    "buttonTextColor",
    "buttonBackgroundColor",
    "buttonFontSize",
    "buttonFontStyle",
    "buttonFontWeight",
    "buttonText",
    "buttonTextDecoration",
    "linkText",
    "linkUrl",
    "openLinkInNewTab",
  ],
  cancel_button: [
    "cancelButtonBackgroundColor",
    "cancelButtonTextColor",
    "cancelButtonFontSize",
    "cancelButtonFontStyle",
    "cancelButtonFontWeight",
    "cancelButtonText",
    "cancelButtonTextDecoration",
  ],
  checkbox: [
    "altCheckboxColor",
    "agreementText",
    "checkboxColor",
    "checkboxEnabled",
    "checkboxFontSize",
    "checkboxFontStyle",
    "checkboxFontWeight",
    "checkboxLabel",
    "checkboxTextDecoration",
  ],
  coupon_codes: [
    "codes",
    "couponBackgroundColor",
    "couponTextColor",
  ],
  emoji: [
    "emoji1",
    "emoji2",
    "emoji3",
    "emoji4",
    "emoji5",
  ],
  heading: [
    "altHeaderTextColor",
    "headerTextColor",
    "headerFontSize",
    "headerFontStyle",
    "headerFontWeight",
    "headerText",
    "headerTextDecoration",
    "overheadFontSize",
    "overheadFontStyle",
    "overheadFontWeight",
    "overheadText",
    "overheadTextColor",
    "overheadTextDecoration",
  ],
  icon: [
    "avatarBackgroundColor",
    "iconBackgroundColor",
    "iconColor",
    "iconDisabled",
    "iconType",
    "iconValue",
  ],
  image: [
    "backgroundImagePosition",
    "backgroundImageUrl",
    "imageBackgroundColor",
    "imageBlured",
    "imageColor",
    "imagePosition",
    "imageShaded",
    "imageUrl",
    "imageUrlHint",
  ],
  input: [
    "emailInputPlaceholder",
    "inputPlaceholder",
    "nameInputPlaceholder",
    "numberInputPlaceholder",
  ],
  inputs: [
    "inputs",
  ],
  list_with_icon: [
    "listIcon1",
    "listIcon2",
    "listIcon3",
    "listIcon4",
    "listText1",
    "listText2",
    "listText3",
    "listText4",
    "listIcons",
    "listIconsDisabled",
    "listTexts",
    "listWithIconFontSize",
    "listWithIconFontStyle",
    "listWithIconFontWeight",
    "listWithIconIcon1BackgroundColor",
    "listWithIconIcon1Color",
    "listWithIconIcon2BackgroundColor",
    "listWithIconIcon2Color",
    "listWithIconIcon3BackgroundColor",
    "listWithIconIcon3Color",
    "listWithIconTextColor",
    "listWithIconTextDecoration",
  ],
  main_text: [
    "altTextColor",
    "liveVisitorsPeriodMinutes",
    "liveVisitorsText",
    "mainText",
    "fontWeight",
    "fontSize",
    "fontStyle",
    "showVisitorsToday",
    "textDecoration",
    "textColor",
    "visitorsTodayText",
  ],
  price: [
    "oldPrice",
    "oldPriceBackgroundColor",
    "oldPriceColor",
    "oldPriceEnabled",
    "price",
    "priceBackgroundColor",
    "priceColor",
    "pricePeriod",
  ],
  subtitle: [

  ],
  textarea: [
    "textareaPlaceholder",
  ],
  timer: [
    "conversionsPeriod",
    "conversionsPeriodUnit",
    "countdownTarget",
    "rotationEnabled",
    "rotationInterval",
  ],
  video: [
    "autoplay",
    "videoUrl",
  ],
  widget_details: [
    "borderRadius",
    "contentBackgroundColor",
    "contentBoxShadowRadius",
    "semitransparentBackgroundColor",
  ],
}

export default mapping
