import React from "react"
import { statefulIcon } from "../../../utils/icons"

const CampaignsIcon: React.FC<{}> = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 2C1 1.44772 1.44772 1 2 1H19C19.5523 1 20 1.44771 20 2V19C20 19.5523 19.5523 20 19 20H2C1.44771 20 1 19.5523 1 19V2Z" stroke="#CDDFEC" strokeWidth="2"/>
      <line x1="19" y1="11.5" x2="1" y2="11.5" stroke="#CDDFEC"/>
      <path d="M12.5 11.5L12.5 2" stroke="#CDDFEC"/>
    </svg>
  )
}

const CampaignsActiveIcon: React.FC<{}> = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.5C0 0.671571 0.671573 0 1.5 0H12.5C13.3284 0 14 0.671573 14 1.5V8.72124C14 9.54967 13.3284 10.2212 12.5 10.2212H1.5C0.671573 10.2212 0 9.54967 0 8.72124V1.5Z" fill="#CDDFEC"/>
      <path d="M15 1.5C15 0.671571 15.6716 0 16.5 0H19.5C20.3284 0 21 0.671573 21 1.5V8.72124C21 9.54967 20.3284 10.2212 19.5 10.2212H16.5C15.6716 10.2212 15 9.54967 15 8.72124V1.5Z" fill="#CDDFEC"/>
      <path d="M0 12.7434C0 11.915 0.671573 11.2434 1.5 11.2434H19.5C20.3284 11.2434 21 11.915 21 12.7434V19.5C21 20.3284 20.3284 21 19.5 21H1.5C0.671573 21 0 20.3284 0 19.5V12.7434Z" fill="#CDDFEC"/>
    </svg>
  )
}

export default statefulIcon(CampaignsIcon, CampaignsActiveIcon)
