import { decamelizeKeys } from "humps"
import { handleFormErrors } from "./utils"
import req from "./jsonRequest"

interface BillingPlanIndexResponse {
  billingPlans: BillingPlan[];
}

async function getAll(): Promise<BillingPlan[]> {
  return req.get<BillingPlanIndexResponse>("/billing_plans.json")
    .then(response => response.data.billingPlans)
    .catch(() => [])
}

async function switchPlan(planDetails: SwitchBillingPlanAttrs): Promise<any> {
  const data = { plan: decamelizeKeys(planDetails) }
  return req.patch<{ redirectUrl: string | null }>("/settings/plan.json", data)
    .then(response => response.data.redirectUrl)
    .catch(handleFormErrors)
}

export default {
  getAll,
  switchPlan,
}
