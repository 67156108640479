import React from "react"
import { useAlert } from "react-alert"
import { useTranslation } from "react-i18next"
import RadioGroup from "./RadioGroup"
import { ReactComponent as LockIcon } from "../../../assets/images/icons/other/lock.svg"

const widgetPositions: WidgetPosition[] = [
  "top_left", "top", "top_right",
  "left", "center", "right",
  "bottom_left", "bottom", "bottom_right"
]

interface PositionSelectProps {
  positions?: ImagePosition[] | WidgetPosition[];
  unavailablePositions?: ImagePosition[] | WidgetPosition[];
  name: string;
}

const PositionSelect: React.FC<PositionSelectProps> = (props: PositionSelectProps) => {
  const { unavailablePositions = [], positions = widgetPositions, ...restProps } = props
  const { t } = useTranslation()
  const alert = useAlert()
  const availablePositions = (positions as string[]).filter(p => !(unavailablePositions as string[]).includes(p))

  const renderOptionProps = (position: WidgetPosition | ImagePosition): object => {
    const options = {} as any
    if (!availablePositions.includes(position)) {
      options.title = t(`positionIsAlreadyInUse`)
      options.className = "widget-position--locked"
      options.children = (<LockIcon />)
      options.onClick = (): void => { alert.show(t(`positionIsAlreadyInUseNotification`)) }
    } else {
      options.label = t(`Position_${position.split(" ").join("_")}`)
    }
    return options
  }

  return (
    <RadioGroup
      {...restProps}
      className="widget-form__positions"
      options={positions}
      renderOptionProps={renderOptionProps}
    />
  )
}
export default PositionSelect
