import React from "react"

const RemoveIcon: React.FC<{}> = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="5.07881" y1="5.14307" x2="12.857" y2="12.9212" stroke="#A0BDD1" strokeLinecap="round"/>
      <line x1="12.8569" y1="5.07881" x2="5.07877" y2="12.857" stroke="#A0BDD1" strokeLinecap="round"/>
    </svg>
  )
}

export default RemoveIcon
