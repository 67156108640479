import React from "react"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const TextareaCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <FormGroup visible={!!parameterTypes.textareaPlaceholder}>
      <Field name="textareaPlaceholder" prefix="parameters" type={parameterTypes.textareaPlaceholder} />
    </FormGroup>
  )
}

export default TextareaCustomization
