function set(data: object): void {
  Object.entries(data).forEach(([key, value]) => {
    window.localStorage.setItem(key, JSON.stringify(value))
  })
}

function clear(): void {
  window.localStorage.clear()
}

function get(key: string, fallbackValue: any = null): any {
  const retrievedObject = window.localStorage.getItem(key)

  return (retrievedObject != null) ? JSON.parse(retrievedObject) : fallbackValue
}

export default {
  clear,
  get,
  set,
}
