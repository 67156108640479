import "react-dates/lib/css/_datepicker.css"
import React, { useState } from "react"
import { DateRangePicker } from "react-dates"
import moment from "moment"

interface DateRangePickerProps {
  endDate?: string;
  onChange: (value: object) => void;
  startDate?: string;
}

const Picker: React.FC<DateRangePickerProps> = (props: DateRangePickerProps) => {
  const { onChange } = props
  const [focusedInput, setFocusedInput] = useState(null)
  const startDate = props.startDate && moment(props.startDate) || null
  const endDate = props.endDate && moment(props.endDate) || null

  const setDates = (dates: any): void => {
    onChange({
      from: dates.startDate?.format("YYYY-MM-DD"),
      to: dates.endDate?.format("YYYY-MM-DD")
    })
  }

  return (
    <DateRangePicker
      customArrowIcon={<span>-</span>}
      endDate={endDate}
      endDateId="period[to]"
      focusedInput={focusedInput}
      isOutsideRange={(): boolean => false}
      onDatesChange={setDates}
      onFocusChange={(data: any): void => setFocusedInput(data)}
      startDate={startDate}
      startDateId="period[from]"
    />
  )
}

export default Picker
