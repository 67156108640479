import React from "react"
import { CustomizationComponentProps } from "."
import Field from "../../../utils/widget-builder/Field"
import FormGroup from "../../form-fields/FormGroup"

const InputCustomization: React.FC<CustomizationComponentProps> = (props: CustomizationComponentProps) => {
  const { parameterTypes } = props

  return (
    <>
      <FormGroup visible={!!parameterTypes.inputPlaceholder}>
        <Field name="inputPlaceholder" prefix="parameters" type={parameterTypes.inputPlaceholder} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.emailInputPlaceholder}>
        <Field name="emailInputPlaceholder" prefix="parameters" type={parameterTypes.emailInputPlaceholder} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.nameInputPlaceholder}>
        <Field name="nameInputPlaceholder" prefix="parameters" type={parameterTypes.nameInputPlaceholder} />
      </FormGroup>
      <FormGroup visible={!!parameterTypes.numberInputPlaceholder}>
        <Field name="numberInputPlaceholder" prefix="parameters" type={parameterTypes.numberInputPlaceholder} />
      </FormGroup>
    </>
  )
}

export default InputCustomization
