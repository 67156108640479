import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { ParsedQuery, parse, stringify } from "query-string"
import { useTranslation } from "react-i18next"
import DateRangePicker from "../components/filters/DateRangePicker"
import DomainIcon from "../images/icons/other/DomainIcon"
import Layout from "../components/layout/DomainsLayout"
import Select from "../components/filters/Select"
import sitesApi from "../api/sites"
import Statistics from "../components/statistics"

interface SiteStatisticsPageProps extends RouteComponentProps {
  siteId?: string;
}

interface PeriodChangeProps {
  from?: string | null;
  to?: string | null;
}

const SiteStatisticsPage: React.FC<SiteStatisticsPageProps> = (props: SiteStatisticsPageProps) => {
  const { location, siteId } = props
  const [nullableQuery, setQuery] = useState<ParsedQuery<string> | null>(null)
  const { t } = useTranslation()
  const [site, setSite] = useState<CustomerSite | null>(null)

  useEffect(() => {
    if (nullableQuery == null) return;

    sitesApi.getOneWithStatistics(siteId, nullableQuery || {}).then(setSite)
  }, [nullableQuery])

  useEffect(() => {
    setQuery(parse(location?.search || "") || {})
  }, [location?.search])

  if (nullableQuery == null) return null;

  const query: any = nullableQuery || {}
  const { campaignId, since, until } = query

  const applyFilters = (newQuery: any): void => {
    const stringifiedQuery = stringify(newQuery, { skipNull: true })
    navigate(`/sites/${siteId}/statistics${stringifiedQuery && ['?', stringifiedQuery].join('')}`)
  }

  const handlePeriodChange = (props: PeriodChangeProps): void => {
    const { from, to } = props
    applyFilters({ ...query, since: from, until: to })
  }

  const handleCampaignSelect = (id?: string | number | null): void => {
    const campaignId = parseInt(id?.toString() || "") === -1 ? null : id
    applyFilters({ ...query, campaignId })
  }

  const HeaderContent: React.FC<{}> = () => {
    return (
      <div className="filters-group">
        <DateRangePicker
          endDate={until?.toString() || undefined}
          onChange={handlePeriodChange}
          startDate={since?.toString() || undefined}
        />
        <Select
          onChange={handleCampaignSelect}
          options={[
            { id: -1, name: t("allCampaigns") },
            ...(site?.widgets || [])
          ].map(w => ({ name: w.name, value: w.id }))}
          selectedValue={parseInt((campaignId || "").toString()) || null}
        />
      </div>
    )
  }

  return (
    <Layout
      activeSiteId={site && (site.hostname || site.id) || undefined}
      activeSubSection="statistics"
      headerContent={site && <HeaderContent />}
      headerIcon={site && <DomainIcon src={site.iconUrl} />}
      headerText={site?.name || ""}
      headerSubText={t("navigationStatistics")}
    >
      {site?.statistics && <Statistics {...site.statistics} />}
    </Layout>
  )
}

export default SiteStatisticsPage
